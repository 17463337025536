<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core"
import gsap from "gsap"
import { nextTick, onMounted, ref } from "vue"
import VarsityContentProcessor from "./VarsityContentProcessor.vue"

const props = withDefaults(
    defineProps<{
        name: string
        content?: string
        collapsedY?: string
        collapsedMarginBottom?: string
    }>(),
    {
        collapsedY: "0",
        content: "",
        collapsedMarginBottom: "-29px", //"-2.1rem"
    },
)

const expanded = useLocalStorage(`vb-hint-box:${props.name}`, true)

const alertElement = ref<HTMLDivElement>()
const contentElement = ref<HTMLDivElement>()
const containerElement = ref<HTMLDivElement>()
const timesButton = ref<HTMLDivElement>()
const exclamationButton = ref<HTMLDivElement>()

const timeline = gsap.timeline({
    paused: true,
    onReverseComplete: () => {
        alertElement.value.style.height = "auto"
        alertElement.value.style.width = "100%"
        contentElement.value.style.width = "auto"
        containerElement.value.style.height = "auto"
    },
})
// .timeScale(0.5)

onMounted(() => {
    // animation from expanded (start) → collapsed (end)

    timeline
        // in order to play two tweens at the same time, they must be positioned on the same label / position
        .add("start")
        .to(
            contentElement.value,
            {
                opacity: 0,
                translateY: "-28px", //"-2rem",
                duration: 0.5,
                ease: "power2.inOut",
            },
            "start",
        )
        .to(
            alertElement.value,
            {
                height: () => `${contentElement.value.clientHeight}px`,
                duration: 0.5,
            },
            "start",
        )
        .fromTo(
            alertElement.value,
            {
                width: () => containerElement.value.clientWidth + "px",
                duration: 0.5,
                ease: "power2.inOut",
            },
            {
                width: "28px", //"2rem",
                duration: 0.5,
                ease: "power2.inOut",
            },
        )
        .add("move_down")
        .to(
            alertElement.value,
            {
                height: "28px", //"2rem",
                duration: 0.5,
                ease: "power2.inOut",
            },
            "move_down",
        )
        .to(
            containerElement.value,
            {
                translateY: props.collapsedY,
                duration: 0.5,
                ease: "power2.inOut",
            },
            "move_down",
        )
        .to(
            containerElement.value,
            {
                marginBottom: props.collapsedMarginBottom,
                duration: 0.5,
                ease: "power2.inOut",
            },
            "move_down",
        )
        .fromTo(
            exclamationButton.value,
            {
                scale: 0,
                duration: 0.5,
                ease: "power2.inOut",
            },
            {
                scale: 1,
                duration: 0.5,
                ease: "power2.inOut",
            },
            "move_down",
        )
        .fromTo(
            timesButton.value,
            {
                scale: 1,
                duration: 0.5,
                ease: "power2.inOut",
            },
            {
                scale: 0,
                duration: 0.5,
                ease: "power2.inOut",
            },
            "move_down",
        )
        .to(alertElement.value, {
            borderRadius: "50%",
            duration: 0.5,
            ease: "power2.inOut",
        })
        .add("end")
        .seek(expanded.value ? "start" : "end")
})

async function close() {
    expanded.value = false
    contentElement.value.style.width = `${containerElement.value.getBoundingClientRect().width}px`
    await nextTick()
    await timeline.play()
}

async function open() {
    contentElement.value.style.width = `${containerElement.value.getBoundingClientRect().width}px`
    expanded.value = true
    await nextTick()
    await timeline.reverse()
}

function toggle() {
    if (expanded.value) {
        close()
    } else {
        open()
    }
}

// const timelinePosition = ref(0)
// function seekTimeline() {
//     timeline.seek(timelinePosition.value)
//     timeline.pause()
// }
</script>

<template>
    <!-- <input type="range" min="0" max="2" step="0.01" @input="seekTimeline" v-model="timelinePosition" /> -->

    <div class="d-flex justify-content-end pe-none" ref="containerElement">
        <div class="alert alert-danger border-0 overflow-hidden position-relative p-0 m-0 pe-all" ref="alertElement">
            <div class="hintbox-button" @click="toggle">
                <i class="fa fa-times" ref="timesButton"></i>
                <div ref="exclamationButton">
                    <slot name="collapsedIcon"><i class="fa fa-exclamation"></i></slot>
                </div>
            </div>

            <div ref="contentElement" class="p-3">
                <slot>
                    <VarsityContentProcessor :allow-all-tags="true" :content="content"></VarsityContentProcessor>
                </slot>
            </div>
        </div>
    </div>
    <!-- <div
        class="hint-box-wrapper"
        :class="expanded ? 'hint-box-expanded' : 'hint-box-collapsed'"
        :style="{ '--hint-box-collapsed-y': collapsedY }"
    >
        <div class="hint-box alert alert-danger" @click="expanded = !expanded">
            <div class="hint-box-content">
                <VarsityContentProcessor :allow-all-tags="true" :content="content"></VarsityContentProcessor>
            </div>
            <div class="c-pointer hint-box-button" @click.prevent="close">
                <i class="fa fa-times"></i>
                <i class="fa fa-exclamation c-pointer"></i>
            </div>
        </div>
    </div> -->
</template>

<style scoped>
.pe-none {
    pointer-events: none;
}

.pe-all {
    pointer-events: all;
}

.hintbox-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    display: grid;
    place-items: center;
    cursor: pointer;
    z-index: 1;
    /* transform: translateX(1px); */
}

.hintbox-button > * {
    grid-row: 1;
    grid-column: 1;
}
</style>
