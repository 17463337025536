<script setup lang="ts">
type StepProps = {
    issue: Issue
    task: ChampionshipRegistrationStepTask
    championship: Championship
    stepData: any
}
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import { useLegacyHttpClient } from '@/composables/compat/useLegacyHttpClient'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import { ref } from 'vue'
const props = defineProps<StepProps>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const inlineConfirm = createInlineConfirm({
    async action() {
        showError.value = false
        try {
            await http.post(`/championship/registration/${props.issue.id}/unlock-registration`, {})
        } catch {
            showError.value = true
        }
    },
    afterAction() {
        if (!showError.value) {
            emit("updated")
        }
    },
})
const http = useLegacyHttpClient()
const showError = ref(false)
</script>

<template>
    <div>
        <div v-if="showError" class="alert alert-danger">
            {{ $t("@championship:registration.undo_final_registration.error") }}
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <div>
                {{ $t("@tasks:tasks.championship_registration.cancel_text") }}
            </div>
            <div>
                <VarsityInlineConfirmButton class="btn btn-warning" :controller="inlineConfirm">
                    {{ $t("@championship:registration.cancel_registration") }}
                </VarsityInlineConfirmButton>
            </div>
        </div>

        <VarsityInlineConfirm :controller="inlineConfirm">
            <template #confirmation>
                {{ $t("@championship:registration.undo_final_registration.confirm") }}
            </template>
            <template #success>
                {{ $t("@championship:registration.undo_final_registration.success") }}
            </template>
        </VarsityInlineConfirm>
    </div>
</template>
