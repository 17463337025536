<script lang="ts" setup>
import type { Entity, ModuleConfiguration, PageType } from "@/vf"
import { injectMandatory, useModuleAction } from "@/vf"
import { inject } from "vue"

const item = injectMandatory<Entity>("item", "VfBackButton requires an item to be injected")
const moduleAction = useModuleAction()
const currentPage: PageType = inject("current-page", "show")

const config = injectMandatory<ModuleConfiguration>(
    "module-config",
    "VfBackButton must be used inside an element that provides module-config like VfEdit or VfShow",
)

const mode = config.show.page(item).mode
</script>

<template>
    <button type="button" @click="moduleAction.close(item, currentPage)" class="btn btn-secondary btn-back">
        <i class="fa fa-fw fa-arrow-left" v-if="mode === 'router'"></i>
        <span v-t="'crud:button.back'"></span>
    </button>
</template>
