<script setup lang="ts">
import { useEventListener, whenever } from "@vueuse/core"
import SignaturePad from "signature_pad"
import { onMounted, onUnmounted, ref, watch } from "vue"

const props = withDefaults(
    defineProps<{
        eraser?: boolean
        modelValue?: string
        width?: string
        height?: string
    }>(),
    {
        eraser: true,
        modelValue: null,
        width: "400px",
        height: "200px",
    },
)

const emit = defineEmits<{
    (e: "update:modelValue", value: string): void
    (e: "empty", isEmpty: boolean): void
}>()

const canvas = ref<HTMLCanvasElement>()
const mode = ref<"draw" | "erase">("draw")
const data = ref<string>(props.modelValue)
const forward = []
const isEmpty = ref(true)
let originalUnloaded = false
let signaturePad: SignaturePad
let loadedData: string
let originalData: string

useEventListener("resize", resizeCanvas)

watch(isEmpty, value => {
    emit("empty", value)
})

whenever(
    () => props.modelValue,
    () => {
        loadData(props.modelValue)
    },
)

onMounted(() => {
    signaturePad = new SignaturePad(canvas.value, {
        minWidth: 0.2,
        maxWidth: 1,
    })
    signaturePad.addEventListener("endStroke", updateData)

    if (data.value) {
        loadData(data.value, true)
    }

    drawMode()
    resizeCanvas()

    isEmpty.value = signaturePad.isEmpty()
})

onUnmounted(() => {
    signaturePad.off()
})

function resizeCanvas() {
    // correct pixel ratio causes incorrect drawing positions, so lines do not appear where they should
    const ratio = 1 // Math.max(window.devicePixelRatio || 1, 1)
    canvas.value.width = canvas.value.offsetWidth * ratio
    canvas.value.height = canvas.value.offsetHeight * ratio
    canvas.value.getContext("2d").scale(ratio, ratio)

    // browser clears canvas on resize, so we need to redraw
    signaturePad.fromData(signaturePad.toData())
}

function undo() {
    let data = signaturePad.toData()
    if (data) {
        let popped = data.pop() // remove the last dot or line
        if (popped) {
            forward.push(popped)
            signaturePad.fromDataURL(originalData)
            signaturePad.fromData(data)
            originalUnloaded = false
        } else {
            signaturePad.clear()
            originalUnloaded = true
        }
        updateData()
    }
    isEmpty.value = signaturePad.isEmpty()
}

function redo() {
    if (originalUnloaded) {
        originalUnloaded = false
        signaturePad.fromDataURL(originalData)
        return
    }

    let data = signaturePad.toData()

    if (!data) {
        data = []
    }

    if (forward.length == 0) {
        return
    }

    data.push(forward.pop())
    signaturePad.fromDataURL(originalData)
    signaturePad.fromData(data)
    updateData()
}

function clear() {
    signaturePad.clear()
    updateData()
}

function drawMode() {
    let ctx = canvas.value.getContext("2d")
    ctx.globalCompositeOperation = "source-over" // default value
    mode.value = "draw"
}

function eraseMode() {
    let ctx = canvas.value.getContext("2d")
    ctx.globalCompositeOperation = "destination-out"
    mode.value = "erase"
}

function updateData() {
    loadedData = signaturePad.toDataURL("image/png")
    data.value = loadedData

    emit("update:modelValue", data.value)
    isEmpty.value = signaturePad.isEmpty()
}

function loadData(newData: string, force = false) {
    if (loadedData !== newData || force) {
        signaturePad.fromDataURL(newData)
        loadedData = newData
        originalData = newData
        data.value = newData
    }
}
</script>

<template>
    <div class="signature-pad">
        <canvas ref="canvas" :style="{ width, height }"></canvas>
        <div>
            <button @click="undo" class="btn btn-danger" type="button"><i class="fa fa-undo"></i></button>
            <button @click="redo" class="btn btn-danger" type="button"><i class="fa fa-redo"></i></button>
            <button @click="clear" class="btn btn-danger" type="button"><i class="fa fa-file"></i></button>
            <button
                @click="eraseMode"
                type="button"
                v-show="eraser"
                class="btn btn-primary"
                :class="{ 'btn-primary-dark': mode === 'erase' }"
            >
                <i class="fa fa-eraser"></i>
            </button>
            <button
                @click="drawMode"
                type="button"
                v-show="eraser"
                class="btn btn-primary"
                :class="{ 'btn-primary-dark': mode === 'draw' }"
            >
                <i class="fa fa-pencil-alt"></i>
            </button>
        </div>
    </div>
</template>

<style scoped>
canvas {
    border: 1px dashed #ddd;
}
</style>
