<script setup lang="ts">
import { onClickOutside, onKeyStroke, onKeyUp } from "@vueuse/core"
import { ref } from "vue"
import VarsityNavSearch from "./VarsityNavSearch.vue"

const visible = ref(false)

/*─────────────────────────────────────┐
│      double shift / ctrl + space     │
│               to open                │
└─────────────────────────────────────*/
const lastShiftDown = ref(null)

onKeyUp("Shift", event => {
    if (window.location.hostname !== "localhost") return
    if (event.target?.["nodeName"] === "INPUT") return

    if (lastShiftDown.value) {
        const time = Date.now() - lastShiftDown.value
        if (time < 500) {
            visible.value = true
        }
    }

    lastShiftDown.value = Date.now()
})

onKeyStroke(["Escape", " ", "Shift"], event => {
    switch (event.key) {
        case "Escape":
            visible.value = false
            break
        case " ":
            if (event.ctrlKey) {
                visible.value = true
            }
            break
        case "Shift":
            // do nothing, handled in keydown event
            break
        default:
            lastShiftDown.value = false
            break
    }
})

/*─────────────────────────────────────┐
│      close dialog on click           │
└─────────────────────────────────────*/
const dialog = ref(null)
onClickOutside(dialog, () => (visible.value = false))
</script>

<template>
    <div v-if="visible">
        <div class="nav-search-backdrop" @click="visible = false"></div>
        <div class="nav-search-container">
            <div class="nav-search-dialog" ref="dialog">
                <VarsityNavSearch @close="visible = false"></VarsityNavSearch>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.nav-search-backdrop,
.nav-search-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.nav-search-backdrop {
    background-color: #000;
    opacity: 0.4;
    z-index: 5000;
}

.nav-search-container {
    display: grid;
    place-content: center;
    z-index: 5001;
}

.nav-search-dialog {
    background-color: #fff;
    padding: 3rem;
    width: 50vw;
    height: 50vh;
    border-radius: 0.5rem;
    box-shadow: 0 0 2rem rgba(#000, 0.8);
    display: flex;
    flex-direction: column;
}
</style>
