<script setup lang="ts">
const props = defineProps<{
    imageUrl: string
}>()
</script>

<template>
    <div class="sidebar-card">
        <div class="sidebar-card--image">
            <div class="sidebar-card--image--inner" :style="{ backgroundImage: `url(${props.imageUrl})` }"></div>
        </div>
        <div class="sidebar-card--content">
            <div class="sidebar-card--title"><slot name="title"></slot></div>
            <div class="sidebar-card--date" v-if="$slots.subtitle">
                <slot name="subtitle"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
.sidebar-card {
    background-color: #ecf1f4;
    border: 1px solid #ecf1f4;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
}

.sidebar-card--image {
    width: 30%;
    flex-shrink: 0;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    aspect-ratio: 1.25;
}

.sidebar-card--image--inner {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 300ms ease-in-out;
}

.sidebar-card:hover .sidebar-card--image--inner {
    transform: scale(1.1);
}

.sidebar-card--content {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sidebar-card--title {
    font-weight: bold;
    color: var(--primary);
}

.sidebar-card--date {
    font-weight: bold;
    font-family: "Roboto Slab", serif;
    color: var(--body-color);
}
</style>
