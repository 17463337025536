<script setup lang="ts">
import type { CmsPagePart } from "@/model/c-m-s/cms-page-part"
import { resolveConfiguration } from "@/pages/Cms/CmsPage/configuration"
import FrontendFragment from "@/pages/Cms/Frontend/FrontendFragment.vue"

const props = defineProps<{
    tree?: CmsPagePart[]
    page?: any
}>()

function resolveConfigurationForColumn(column) {
    for (let child of column) {
        resolveConfigurationForTree(child)
    }
}

function resolveConfigurationForTree(tree) {
    tree.config = resolveConfiguration(tree.type, tree.config)
    try {
        for (let idx in tree.columns ?? []) {
            resolveConfigurationForColumn(tree.columns[idx])
        }
    } catch (e) {
        console.warn("error", e, tree?.columns, typeof tree?.columns)
    }
}

resolveConfigurationForColumn(props.tree)
</script>

<template>
    <FrontendFragment v-bind="{ elements: props.tree, page: props.page }"></FrontendFragment>
</template>
