<script setup lang="ts">
import { useInlineConfirmGroup, type InlineConfirm } from "@/composables/createInlineConfirm"
import { classesToArray, sleep } from "@/vf"
import { computed, ref, type Ref } from "vue"

const props = withDefaults(
    defineProps<{
        controller: InlineConfirm
        class?: string
        disabled?: boolean
    }>(),
    {
        class: "btn btn-primary btn-shape-skewed",
        disabled: false,
    },
)
const controller = props.controller
const button: Ref<HTMLButtonElement> = ref(null)
const transitionDuration = 500
const expanded = ref(false)

const group = useInlineConfirmGroup(controller)

async function toggle() {
    if (controller.isOpen.value) {
        await controller.close()
        // button.value.classList.remove("btn-expand-dialog")
        expanded.value = false
    } else {
        await group.closeOther()
        // button.value.classList.add("btn-expand-dialog")
        expanded.value = true
        await sleep(transitionDuration)
        controller.open()
    }
}

controller.onClose(() => {
    // button.value.classList.remove("btn-expand-dialog")
    expanded.value = false
    return sleep(transitionDuration)
})

controller.onOpen(() => {
    expanded.value = true
    // return sleep(transitionDuration)
})

const computedClasses = computed(() => {
    const classes = classesToArray(props.class)

    if (expanded.value) {
        classes.push("btn-expand-dialog")
    }

    return classes.join(" ")
})
</script>

<template>
    <button type="button" :class="computedClasses" :disabled="props.disabled" @click="toggle">
        <slot></slot>
    </button>
</template>

<style lang="scss" scoped>
:root .btn.btn-shape-skewed.btn-expand-dialog {
    //transform:      translateY(.55rem);
    padding-bottom: 1rem;
    margin-bottom: -2px;
    padding-left: calc(2.5rem);
    padding-right: calc(2.5rem);

    &::before,
    &::after {
        width: calc(33px + 0.5rem);
    }

    // &::before {
    //     //left: calc(-30px - 0.5rem);
    // }

    // &::after {
    //     //right: calc(-30px - 0.5rem);
    // }
}
</style>
