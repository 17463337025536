import type { App } from "vue"
import { registerAnimateShow } from "./animate-show"
import { registerFocus } from "./focus"
import { registerAureliaCompat } from "./formatters"
import { registerGlobalComponents } from "./global-components"
import { registerScroll } from "./scroll"
import { registerSpinnerDirective } from "./spinner"

export function registerVfPlugins(app: App) {
    registerAnimateShow(app)
    registerAureliaCompat(app)
    registerGlobalComponents(app)
    registerSpinnerDirective(app)
    registerFocus(app)
    registerScroll(app)
}
