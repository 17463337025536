<script setup lang="ts">
import { createHashTab } from "./useHashTab"

const props = withDefaults(defineProps<{ name?: string; default: string }>(), { name: "default" })
const { activeTab, selectTab } = createHashTab(props.name, props.default)
</script>

<template>
    <slot v-bind="{ activeTab, selectTab }" />
</template>
