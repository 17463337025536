import { sleep, useAppConfig, useDialogs, useHttpClient } from "@/vf"
import { useEventListener } from "@vueuse/core"
import type { AxiosError } from "axios"

const queue = []

const localStorageKeyForQueue = "@Scoring.Service.PostService.queue"
const localStorageKeyForUsername = "@Scoring.Service.PostService.username"
const localStorageKeyForAccount = "@Scoring.Service.PostService.account"

let executionTaskRunning = false

let ensureEmptyPromise = null
let ensureEmptyPromiseResolve = null

export interface PostService {
    post(endpoint, data)

    ensureEmpty(): Promise<void>

    isEmpty(): boolean

    queue
}

export function usePostService(): PostService {
    const { appConfig } = useAppConfig()
    const http = useHttpClient()
    const dialogs = useDialogs()

    const ls = localStorage.getItem(localStorageKeyForQueue)

    if (ls) {
        queue.splice(0, queue.length)
        queue.push(...JSON.parse(ls))
        _queueChanged()
    }

    function _onBeforeUnload(event: BeforeUnloadEvent) {
        if (queue.length > 0) {
            return (event.returnValue = (window as any).i18n.global.t("@scoring:service.unload_message"))
        }

        return null
    }

    // beforeunload is also called when switching accounts.
    useEventListener(window, "beforeunload", _onBeforeUnload)

    function post(endpoint, data) {
        queue.push({ endpoint, data })
        _queueChanged()
    }

    function ensureEmpty() {
        if (!executionTaskRunning) {
            return new Promise(resolve => resolve(null))
        }

        if (!ensureEmptyPromise) {
            ensureEmptyPromise = new Promise(resolve => {
                ensureEmptyPromiseResolve = resolve
            })
        }

        return ensureEmptyPromise
    }

    function _queueIsEmptyCallback() {
        if (!ensureEmptyPromise) {
            return
        }

        ensureEmptyPromiseResolve()
        ensureEmptyPromiseResolve = null
        ensureEmptyPromise = null
    }

    function _queueChanged() {
        if (queue.length == 0) {
            localStorage.removeItem(localStorageKeyForQueue)
        } else {
            localStorage.setItem(localStorageKeyForQueue, JSON.stringify(queue))
        }

        // ignore promise from executeQueue and by that start a new background task
        // noinspection JSIgnoredPromiseFromCall
        _executeQueue()
    }

    function _saveLoginInLocalStorage() {
        localStorage.setItem(localStorageKeyForUsername, appConfig.value.login.username)
        let accountId = appConfig.value.account.id
        if (appConfig.value.switchFrom) {
            accountId = appConfig.value.switchFrom.id + ":" + accountId
        }
        localStorage.setItem(localStorageKeyForAccount, accountId)
    }

    function _clearLoginInLocalStorage() {
        localStorage.removeItem(localStorageKeyForUsername)
        localStorage.removeItem(localStorageKeyForAccount)
    }

    async function _executeQueue() {
        if (executionTaskRunning) return
        executionTaskRunning = true
        _saveLoginInLocalStorage()
        try {
            while (queue.length > 0) {
                await _executeNext()
            }
        } catch (e) {
            console.error(e)
        }
        _clearLoginInLocalStorage()
        executionTaskRunning = false
        _queueIsEmptyCallback()
    }

    async function _executeNext() {
        const peek = queue[0]
        let sleepMultiplier = 1 // wait up to 5 seconds between retries
        const removePeeked = () => {
            queue.shift()
            _queueChanged()
        }
        for (;;) {
            try {
                // @ts-ignore
                await http.postBg(peek.endpoint, peek.data)

                // remove the peeked value after we executed it successfully
                removePeeked()
                break
            } catch (exc) {
                const e = exc as AxiosError<{ error: string }>
                if (e.response.status == 400) {
                    dialogs.open({
                        type: "error",
                        message: e.response.data.error ?? "Unknown error",
                        translate: true,
                        buttons: [dialogs.Buttons.BUTTON_OK],
                    })
                    // do not retry this
                    removePeeked()
                    break
                } else {
                    console.warn("request failed, retrying in " + 100 * sleepMultiplier + " ms", e)
                    await sleep(100 * sleepMultiplier)
                    if (sleepMultiplier < 50) {
                        sleepMultiplier += 10
                    }
                }
            }
        }
    }

    return {
        post,
        ensureEmpty,
        queue,
        isEmpty: () => queue.length == 0,
    }
}
