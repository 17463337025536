import { type Issue } from "@/model/tasks/issue"
import { $datetime, normalizeModuleConfiguration, type ModuleConfiguration } from "@/vf"
import { h, ref, type Ref } from "vue"
import { useI18n } from "vue-i18n"
import IssueShow from "../Issue/show.vue"
import IssueNew from "./new.vue"

export function createModuleConfiguration(apiBase: string, filterOnlyMessages?: Ref<boolean>): ModuleConfiguration {
    const i18n = useI18n()
    return normalizeModuleConfiguration({
        routePrefix: () => "@Tasks.Customer",
        readableName: {
            plural: "@tasks:issue.issues",
            singular: "@tasks:issue.issue",
        },
        new: IssueNew,
        show: item => ({
            component: IssueShow,
            props: {
                id: item.id,
                viewMode: "customer",
                filterOnlyMessages: filterOnlyMessages?.value ?? false,
            },
        }),
        list: {
            fields: [
                {
                    name: "newIcon",
                    title: "",
                    type: "vnode",
                    getter: item =>
                        item["unreadMessagesCount"] > 0
                            ? h("i", { class: "fa-solid fa-envelope-open-text" })
                            : h("i", { style: "width: 1rem" }),
                },
                {
                    name: "name",
                    sortField: "a.name",
                    title: "@tasks:issue.name",
                    type: "string",
                    getter: (issue: Issue) => i18n.t(issue.name, issue.nameProps) ?? issue.name,
                },
                {
                    name: "createdAt",
                    sortField: "a.createdAt",
                    title: "@tasks:issue.createdAt",
                    type: "datetime",
                },
                {
                    name: "lastTaskAt",
                    sortField: "a.lastTaskAt",
                    title: "@tasks:issue.lastTaskAt",
                    type: "datetime",
                },
                {
                    name: "taskCount",
                    sortField: "a.messageCount",
                    title: "@tasks:issue.messageCount",
                    type: "vnode",
                    thClass: () => "text-center",
                    tdClass: () => "text-center",
                    getter: (issue: Issue) =>
                        h(
                            "div",
                            {
                                class: "text-center",
                                title: i18n.t("@tasks:issue.messageCountHover", {
                                    count: issue.messageCount,
                                    unread: issue["unreadMessagesCount"] ?? 0,
                                }),
                            },
                            [
                                issue.messageCount +
                                    (issue["unreadMessagesCount"] ?? 0 > 0
                                        ? " (" + issue["unreadMessagesCount"] + ")"
                                        : ""),
                            ],
                        ),
                },
                {
                    name: "taskCount",
                    sortField: "a.taskCount",
                    title: "@tasks:issue.tasksCount",
                    type: "vnode",
                    thClass: () => "text-center",
                    tdClass: () => "text-center",
                    getter: (issue: Issue) =>
                        h(
                            "div",
                            {
                                class: "text-center",
                                title: i18n.t("@tasks:issue.tasksCountHover", {
                                    count: issue.taskWithoutMessagesCount,
                                    open: issue.openTaskWithoutMessagesCount ?? 0,
                                }),
                            },
                            [
                                issue.taskWithoutMessagesCount +
                                    (issue.openTaskWithoutMessagesCount ?? 0 > 0
                                        ? " (" + issue.openTaskWithoutMessagesCount + ")"
                                        : ""),
                            ],
                        ),
                },
                {
                    name: "state",
                    sortField: "a.state",
                    title: "@tasks:issue.state",
                    type: "vnode",
                    tdClass: () => "text-right",
                    thClass: () => "text-right",
                    getter: (issue: Issue) => {
                        if (issue.state === "done") {
                            return h("span", { title: $datetime(issue.closedAt) }, [
                                i18n.t(`@tasks:issue.issue_states.done`),
                            ])
                        }

                        return i18n.t(`@tasks:issue.issue_states.${issue.state}`)
                    },
                },
            ],
            orderField: "createdAt",
            orderDirection: "desc",
            onClick: "show",
            requestParams: ref({
                viewMode: "customer",
                includeDone: "1",
                includeOpen: "1",
            }),
            trClass: item => {
                return item["unreadMessagesCount"] > 0 ? "font-weight-bold" : ""
            },
        },
        afterSaveAction: "navigate-list",
        apiBase: () => apiBase,
        mode: "in-table",
    })
}
