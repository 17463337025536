<script lang="ts" setup>
import { injectMandatory, type ModuleConfiguration } from "@/vf"
import VbNewButton from "@/components/crud/VbNewButton.vue"

const config = injectMandatory<ModuleConfiguration>(
    "module-config",
    "ScListToolbar must be used inside an element that provides module-config like VfEdit or VfShow.",
)
</script>

<template>
    <VbNewButton v-if="config.list.newButton()"></VbNewButton>
</template>
