import type { Invitation } from "@/model/app/invitation"
import type { Organization } from "@/model/app/organization"
import { useHttpClient } from "@/vf"
import { computed, ref, watch, type Ref } from "vue"

export interface SelectionTableOrganization {
    id: string
    name: string
    organizationLevel: "main" | "sub"
    parentName: string
    /** Parent Organization ID */
    parent: string
    approved: boolean
    disabled: boolean
    status: "disabled" | "active" | "review"
    accessible: boolean
    hasManagerAccess: boolean
    hasChildren: boolean
    debitorProfiles: {
        name: string
        accountNumber: string
        addressLine: string
        addressLine2: string
        street: string
        streetNumber: string
        zip: string
        city: string
    }[]
    children?: Organization[]
}

export interface ExtendedOrganization extends Organization {
    id: string // non optional
    organizationLevel: string

    accessible: boolean
    hasManagerAccess: boolean
    hasManageOrganizationRole: boolean
    hasSubOrganizations: boolean

    requireToAcceptCondition: boolean
    latestConditionId: string
}

export async function useOrganizationList() {
    const http = useHttpClient()

    // organizations from the api, without children
    const organizations = ref<SelectionTableOrganization[]>([])

    // all pending invitations to this organization
    const invitations = ref<Invitation[]>([])

    // organizations grouped by parents
    const tree = ref<SelectionTableOrganization[]>([])

    async function reload() {
        const response = (
            await http.get<{ items: SelectionTableOrganization[]; invitations: Invitation[] }>(
                "/organization/selection",
            )
        ).data

        organizations.value = response.items
        invitations.value = response.invitations

        tree.value = response.items
    }

    await reload()

    return {
        organizations,
        invitations,
        tree,

        reload,
    }
}

export function useOrganization(organizationId: Ref<string>) {
    const organization = ref<ExtendedOrganization>(null)

    const http = useHttpClient()

    async function reload() {
        const response = await http.get<ExtendedOrganization>(`/organization/${organizationId.value}/extended`)

        organization.value = response.data
    }

    watch(organizationId, reload)

    // if set to true, the create sub organization button will be shown
    const createSubOrganizationAvailable = computed(
        () => organization.value?.organizationLevel === "main" && organization.value?.hasManagerAccess,
    )

    return {
        organization,
        reload,

        createSubOrganizationAvailable,
    }
}
