import { defineStore } from "pinia"
import { ref, type Ref } from "vue"

export interface InvitationHint {
    token: string
    email: string
}

export const useNavbarState = defineStore("navbar", () => {
    const showMobileSidebar = ref(false)
    const queryParams = new URLSearchParams(window.location.search)
    const showCard: Ref<string> = ref(null)

    const invitationHint: Ref<InvitationHint | null> = ref(null)

    if (queryParams.has("resetPasswordToken")) {
        showCard.value = "reset-password"
    }

    if (queryParams.has("activate")) {
        showCard.value = "activate"
    }

    function openCard(card: string) {
        showCard.value = card === showCard.value ? null : card
    }

    function toggleMobileSidebar(show: boolean | undefined = undefined) {
        showMobileSidebar.value = show ?? !showMobileSidebar.value
    }

    return {
        openCard,
        showCard,
        invitationHint,
        showMobileSidebar,
        toggleMobileSidebar,
    }
})
