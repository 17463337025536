<script lang="ts" setup>
import { useModuleAction } from "@/vf"

const moduleAction = useModuleAction()
</script>

<template>
    <button @click.prevent="moduleAction.newEntity()" class="btn btn-primary btn-shape-rounded btn-shape-rounded-white">
        <i class="fa fa-fw fa-plus"></i>
        <span>{{ $t("crud:button.new") }}</span>
    </button>
</template>
