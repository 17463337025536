<script setup lang="ts">
import { computed } from "vue"
import { createBackgroundImageColorBlend } from "./color-blend"
import { getHref, getTarget } from "./link-service"

const props = defineProps<{
    element: any
}>()

const style = computed(() => {
    return createBackgroundImageColorBlend(props.element.config, props.element.fileName)
})
</script>

<template>
    <a class="picture-links-item" :href="getHref(props.element)" :target="getTarget(props.element)">
        <div class="picture-link-image-wrap">
            <div class="picture-link-image" :style="style"></div>
        </div>
        <div class="picture-links-title">{{ element.title }}</div>
        <div class="picture-links-link" v-if="element.buttonText">
            {{ element.buttonText || element.title }}
            <i class="fa fa-fw fa-chevron-circle-right"></i>
        </div>
        <div v-if="!element.buttonText">&nbsp;</div>
    </a>
</template>

<style scoped lang="scss">
@import "@/styles/variables";

.picture-links-item {
    font-family: "Roboto Slab";
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: $cms-border-radius;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 3rem;
    align-content: end;
    position: relative;

    &:hover {
        text-decoration: none;
    }

    &:hover .picture-link-image {
        transform: scale(1.05);
    }

    .picture-links-title {
        color: #fff;
        font-size: 2rem;
        margin-bottom: 1rem;
        z-index: 2;
    }

    .picture-links-link {
        color: #5fa5da;
        text-transform: uppercase;
        z-index: 2;
    }

    .picture-link-image-wrap {
        overflow: hidden;
        position: absolute;
        inset: 0;
        display: flex;
        z-index: 1;
        border-radius: $cms-border-radius;
    }

    .picture-link-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: 300ms ease-in-out transform;
        transform: scale(1);
        width: 100%;
        height: 100%;
    }
}
</style>
