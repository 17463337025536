<script setup lang="ts">
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import { useCookieConsent } from "@/composables/useCookieConsent"
import { DialogController, sleep } from "@/vf"
import { ref } from "vue"

const props = defineProps<{
    controller: DialogController<any>
}>()

const { acceptSingle, declineAll: _declineAll, whitelist, allTypes } = useCookieConsent()

const model = ref(whitelist ?? [])

function close() {
    props.controller.ok(true)
}

function saveSettings() {
    _declineAll()
    for (const item of model.value) {
        acceptSingle(item)
    }
    close()
}

async function acceptAll() {
    for (const category of allTypes) {
        for (const item of category.children) {
            if (!model.value.includes(item)) {
                acceptSingle(item)
                model.value = [...model.value, item]
            }
        }
    }

    await sleep(250)
    close()
}

async function declineAll() {
    model.value = []
    await sleep(250)
    _declineAll()
    close()
}

function includesAll(category: { name: string; children: string[] }) {
    for (const item of category.children) {
        if (!model.value.includes(item)) {
            return false
        }
    }
    return true
}

function changeAll(category: { name: string; children: string[] }, value: boolean) {
    for (const item of category.children) {
        if (value) {
            if (!model.value.includes(item)) {
                model.value = [...model.value, item]
            }
        } else {
            model.value = model.value.filter(i => i !== item)
        }
    }
}
</script>

<template>
    <h2>{{ $t("@app:cookie_consent.title") }}</h2>
    {{ $t("@app:cookie_consent.text") }}
    <div class="single-consent">
        <CheckboxToggle :disabled="true" :model-value="true">
            {{ $t("@app:cookie_consent.essential.label") }}
        </CheckboxToggle>
        {{ $t("@app:cookie_consent.essential.info") }}
    </div>
    <div class="single-consent" v-for="category of allTypes">
        <template v-if="category.children.length === 1">
            <CheckboxToggle :value="category.children[0]" v-model="model">
                {{ $t("@app:cookie_consent." + category.children[0] + ".label") }}
            </CheckboxToggle>
            {{ $t("@app:cookie_consent." + category.children[0] + ".info") }}
        </template>
        <template v-else>
            <CheckboxToggle :model-value="includesAll(category)" @update:model-value="changeAll(category, $event)">
                {{ $t("@app:cookie_consent." + category.name + ".label") }}
            </CheckboxToggle>
            {{ $t("@app:cookie_consent." + category.name + ".info") }}

            <div class="single-consent" v-for="type of category.children">
                <CheckboxToggle :value="type" v-model="model">
                    {{ $t("@app:cookie_consent." + type + ".label") }}
                </CheckboxToggle>
                {{ $t("@app:cookie_consent." + type + ".info") }}
            </div>
        </template>
    </div>
    <div class="text-right mt-5">
        <button class="btn btn-primary" @click="declineAll()">Alle ablehnen</button>
        <button class="btn btn-primary" @click="saveSettings()">Auswahl speichern</button>
        <button class="btn btn-primary" @click="acceptAll()">Alle akzeptieren</button>
    </div>
    <div class="text-right mt-5 text-small">
        <RouterLink to="/general/data_protection">{{ $t("@app:cookie_consent.footer.privacy") }}</RouterLink>
        <RouterLink to="/general/imprint" class="ml-3">{{ $t("@app:cookie_consent.footer.imprint") }}</RouterLink>
    </div>
</template>

<style scoped>
.single-consent {
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid #ccc;
}
</style>