<script setup lang="ts">
import { useApp } from "@/vf"
import { calculateStyle } from "../../CmsPage/configuration"
import FrontendFragment from "../FrontendFragment.vue"

const props = defineProps<{
    element: any
}>()

const { apiLink } = useApp()
</script>

<template>
    <section class="cms-picture-text" :class="{ 'flex-row-reverse': element.config.pictureTextLayout === '1' }">
        <div class="cms-picture-text-picture">
            <img
                alt=""
                class="img-fluid"
                :src="apiLink('/media/' + element.fileName)"
                :style="calculateStyle(element)"
            />
        </div>

        <div class="cms-picture-text-text">
            <FrontendFragment v-bind="{ elements: element.columns[0] }"></FrontendFragment>
        </div>
    </section>
</template>
