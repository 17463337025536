import { type Router } from "vue-router"

export function clearRoutes(router: Router) {
    for (const route of router.getRoutes()) {
        if (["_404"].includes(route.name.toString())) {
            continue
        }

        router.removeRoute(route.name)
    }
}

export function reconfigureRoutes(router: Router, newRoutes: any[]) {
    clearRoutes(router)
    for (const route of newRoutes) {
        if (route.moduleId) {
            const filePath = route.moduleId.replace(/\./g, "/").substring(1)
            const pathParts = filePath.split("/")
            const dirCount = pathParts.length - 1
            // this strange construct is to allow vite to resolve the component in build/hmr
            if (dirCount == 0) {
                route.component = () => import(`../pages/${filePath}.vue`)
            } else if (dirCount == 1) {
                route.component = () => import(`../pages/${pathParts[0]}/${pathParts[1]}.vue`)
            } else if (dirCount == 2) {
                route.component = () => import(`../pages/${pathParts[0]}/${pathParts[1]}/${pathParts[2]}.vue`)
            } else if (dirCount == 3) {
                route.component = () =>
                    import(`../pages/${pathParts[0]}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}.vue`)
            } else {
                throw Error("Too many path parts. You need to add more import statements to support this path.")
            }
        }
        route.props = true
        router.addRoute(route)
    }
}
