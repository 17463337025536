<script setup lang="ts">
import VbEditToolbar from "@/components/crud/VbEditToolbar.vue"
import { VfEdit, VfFormRest, type Entity, type EntityEvent, type FormModel, type ModuleConfiguration } from "@/vf"
import type { Ref } from "vue"
import { VbBackButton } from "."

withDefaults(
    defineProps<{
        config: ModuleConfiguration
        item?: Entity
        autoRest?: boolean
        cardBodyClass?: string
        toolbar?: boolean
        header?: boolean
        cardClass?: string
        multiStep?: boolean
    }>(),
    {
        toolbar: true,
        header: true,
        cardBodyClass: "card-body",
        cardClass: "card",
        autoRest: true,
        multiStep: false,
    },
)

const emit = defineEmits<{
    (e: "saved", entity: Entity): void
    (e: "model", model: Ref<FormModel>): void
    (e: "save", event: EntityEvent): void | Promise<void>
}>()
</script>

<template>
    <VfEdit
        v-bind="{ config, item, multiStep }"
        @saved="entity => emit('saved', entity)"
        @model="model => emit('model', model)"
        @save="event => emit('save', event)"
    >
        <template #default="{ item, formModel, schema, additionalData, mode }">
            <div :class="cardClass">
                <div class="card-header" v-if="header && mode === 'router'">
                    <slot name="header">
                        <div class="page-header">
                            <slot name="backbutton">
                                <VbBackButton></VbBackButton>
                            </slot>
                            <h1 v-t="config.readableName.plural"></h1>
                            <h2></h2>
                        </div>
                    </slot>
                </div>
                <div class="card-toolbar" v-if="toolbar && mode === 'router'">
                    <VbEditToolbar :item="item" :config="config" />
                </div>
                <div :class="cardBodyClass">
                    <slot name="default" v-bind="{ item, formModel, schema, additionalData }"></slot>

                    <VfFormRest v-if="autoRest"></VfFormRest>

                    <slot name="toolbar" v-bind="{ formModel }">
                        <VbEditToolbar v-bind="{ item, config, formModel }" v-if="toolbar && mode !== 'router'" />
                    </slot>
                </div>
            </div>
        </template>
    </VfEdit>
</template>
