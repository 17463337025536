<script setup lang="ts">
import { type CmsPagePart } from "@/model/c-m-s/cms-page-part"
import { calculateStyle } from "@/pages/Cms/CmsPage/configuration"
import ConditionRenderInline from "@/pages/Conditions/Render/ConditionRenderInline.vue"
import { useApp } from "@/vf"
import { toRefs } from "vue"

const props = defineProps<{
    element:
        | CmsPagePart
        | {
              config: {
                  selectedDocument: string
                  displayType: "pdf" | "inline"
                  showPdfDownloadLink: false
                  textFactor: number
              }
          }
}>()
const { element } = toRefs(props)
const { apiLink } = useApp()
if (element.value.config.displayType != "pdf") {
    // height is only valid for pdfs
    element.value.config.height = undefined
}
</script>

<template>
    <div v-if="element.config.showPdfDownloadLink" class="mb-3">
        <a :href="apiLink('legal/view/' + element.config.selectedDocument)" target="_blank">
            {{ $t("@cms:condition.download_pdf") }}
        </a>
    </div>
    <iframe
        :src="apiLink('legal/view/' + element.config.selectedDocument + '?disposition=inline')"
        class="w-100 border-0"
        :style="calculateStyle(element)"
        v-if="element.config.displayType == 'pdf'"
    />
    <div :style="calculateStyle(element)" v-else>
        <ConditionRenderInline
            :conditionId="element.config.selectedDocument"
            :text-factor="element.config.textFactor"
            :single-language="true"
        />
    </div>
</template>
