<script setup lang="ts">
import { useHashTab } from "./useHashTab"

defineProps<{ tab: string; onlyHide?: boolean }>()
const { activeTab } = useHashTab()!
</script>

<template>
    <template v-if="!onlyHide && activeTab === tab">
        <slot></slot>
    </template>
    <template v-if="onlyHide">
        <div v-show="activeTab === tab">
            <slot></slot>
        </div>
    </template>
</template>