<script lang="ts" setup>
import VarsityInlineConfirm from "@/components/VarsityInlineConfirm.vue"
import VarsityInlineConfirmButton from "@/components/VarsityInlineConfirmButton.vue"
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import { useVarsityAppConfig } from "@/composables"
import { createInlineConfirmCollection } from "@/composables/createInlineConfirm"
import type { LoginSearchFilter } from "@/model/app/login-search-filter"
import type { UserGroup } from "@/model/app/user-group"
import { type IssueGroup } from "@/model/tasks/issue-group"
import { type ProductGroup } from "@/model/tasks/product-group"
import { type Project } from "@/model/tasks/project"
import { VfDropdown, useHttpClient, type ListResponse, type ModuleConfiguration } from "@/vf"
import _, { clone } from "lodash"
import { computed, reactive, ref, watch } from "vue"

const { appConfig } = useVarsityAppConfig()

const props = defineProps<{
    config: ModuleConfiguration
    admin?: boolean
    loginSearchFilters: [LoginSearchFilter]
    loginSearchFilterActiveFilterId: string
}>()
const emit = defineEmits(["updateLoginSearchFilters", "showProjectFilters", "setLoginSearchFilterActiveFilterId"])
defineExpose({
    openLoginSearchFilter: openLoginSearchFilter,
})

const isAdmin = appConfig.value.canManageLoginSearchFilter === true
const http = useHttpClient()
const filters = (
    await http.get<{
        projects: Project[]
        projectTypes: string[]
        issueGroups: IssueGroup[]
        productGroups: ProductGroup[]
    }>("tasks/issue/filters" + (props.admin ? "/admin" : ""))
).data
const userGroups = isAdmin ? (await http.get<ListResponse>("/user-group")).data.list : []
const loginSearchFilterName = ref(null)
const loginSearchFilterEditNameFilterId = ref(null)
const loginSearchFilterEditNames = ref([])

const filteredProjects = computed(() => {
    return filters.projects.filter(it => {
        return selection.productGroups.includes(it.productGroup.id)
    })
})

const selection = reactive({
    issueGroups: [] as string[],
    projectTypes: [] as string[],
    productGroups: [] as string[],
    projects: [] as string[],
})

watch(filteredProjects, () => {
    if (selection.projects.length === 0) {
        return
    }

    selection.projects = selection.projects.filter(
        it => filteredProjects.value.map(p => p.id).includes(it) || it == "-",
    )
})

watch(
    selection,
    () => {
        props.config.list.requestParams.value = {
            ...(props.config.list.requestParams.value ?? {}),
            ...selection,
        }
        let loginSearchFilterActiveFilterId: string = ""
        props.loginSearchFilters.forEach(filter => {
            if (_.isEqual(filter.data, selection)) {
                loginSearchFilterActiveFilterId = filter.id
            }
        })
        emit("setLoginSearchFilterActiveFilterId", loginSearchFilterActiveFilterId)
    },
    { immediate: true },
)

function setSelection(
    method: "all" | "none" | "list",
    list?: {
        issueGroups: string[]
        projectTypes: string[]
        productGroups: string[]
        projects: string[]
    },
) {
    selection.issueGroups = []
    selection.productGroups = []
    selection.projectTypes = []
    selection.projects = []
    if (method === "all") {
        for (const group of filters.issueGroups) {
            selection.issueGroups.push(group.id)
        }
        selection.productGroups.push("-")
        for (const group of filters.productGroups) {
            selection.productGroups.push(group.id)
        }
        for (const t of filters.projectTypes) {
            selection.projectTypes.push(t)
        }
        selection.projects.push("-")
        for (const project of filteredProjects.value) {
            selection.projects.push(project.id)
        }
    } else if (method === "list") {
        selection.issueGroups = list.issueGroups
        selection.projectTypes = list.projectTypes
        selection.productGroups = list.productGroups
        selection.projects = list.projects
    }
}

function saveLoginSearchFilter() {
    http.post("/login/search-filter/", {
        listId: props.config.list.id(),
        name: loginSearchFilterName.value.value,
        data: selection,
    }).then(() => loadLoginSearchFilters())
}

function updateLoginSearchFilter(filterId: string, index: number) {
    http.put(`/login/search-filter/${filterId}`, {
        name: loginSearchFilterEditNames.value[index],
    }).then(() => loadLoginSearchFilters(), (loginSearchFilterEditNameFilterId.value = null))
}

const deleteLoginSearchFilterConfirm = createInlineConfirmCollection(filterId => ({
    async action() {
        if (filterId) {
            await deleteLoginSearchFilter(filterId)
        }
    },
}))

async function deleteLoginSearchFilter(filterId) {
    await http.delete(`/login/search-filter/${filterId}`)
    await loadLoginSearchFilters()
}

function openLoginSearchFilter(filterId: string) {
    emit("showProjectFilters", false)

    if (filterId === "") {
        setSelection("all")
    } else {
        let filter = props.loginSearchFilters.find(searchFilter => searchFilter.id === filterId).data
        setSelection("list", filter)
    }
}

async function loadLoginSearchFilters() {
    let filters = (
        await http.get<[LoginSearchFilter]>(
            "/login/search-filter/?listId=" + encodeURIComponent(props.config.list.id()),
        )
    ).data
    loginSearchFilterEditNames.value = []
    filters.forEach(filter => {
        loginSearchFilterEditNames.value.push(filter.name)
    })
    emit("updateLoginSearchFilters", filters)
}

async function exposeLoginSearchFilter(
    filter: LoginSearchFilter,
    exposed: boolean,
    exposedToUserGroup?: UserGroup,
    listId?: string,
    shallowCopy?: LoginSearchFilter,
) {
    if (listId == null) {
        http.put("/login/search-filter/" + filter.id, {
            exposed: exposed ? 1 : 0,
            exposedToUserGroup: exposedToUserGroup?.id || null,
        }).then(() => loadLoginSearchFilters())
    } else {
        if (shallowCopy == null) {
            http.post("/login/search-filter/", {
                listId: listId,
                shallowCopyOrigin: filter.id,
                exposed: exposed ? 1 : 0,
                exposedToUserGroup: exposedToUserGroup?.id || null,
            }).then(() => loadLoginSearchFilters())
        } else {
            http.put("/login/search-filter/" + shallowCopy.id, {
                exposed: exposed ? 1 : 0,
                exposedToUserGroup: exposedToUserGroup?.id || null,
            }).then(() => loadLoginSearchFilters())
        }
    }
}

function findShallowCopy(filter: LoginSearchFilter) {
    return (
        filter.shallowCopies.find(copy => copy.listId == "/my-varsity/my-profile/my-communication-tasks/issue") || null
    )
}

loadLoginSearchFilters()
setSelection("all")
</script>

<template>
    <div class="search-filter form-light">
        <div class="d-flex communication-settings bg-white">
            <div class="communication-setting">
                <div class="communication-setting-header">
                    <b>{{ $t("@tasks:issueGroup") }}</b>
                </div>
                <div class="communication-setting-body">
                    <template v-for="group of filters.issueGroups">
                        <div :style="'padding-left:' + 20 * group.indent + 'px'">
                            <CheckboxToggle v-model="selection.issueGroups" :value="group.id">
                                {{ group["name"] }}
                            </CheckboxToggle>
                        </div>
                    </template>
                </div>
            </div>
            <div class="communication-setting">
                <div class="communication-setting-header">
                    <b>{{ $t("@tasks:projectType") }}</b>
                </div>
                <div class="communication-setting-body">
                    <!--                <CheckboxToggle v-for="t of filters.projectTypes" v-model="selection.projectTypes" :value="t">-->
                    <!--                    {{ $t("@tasks:issue.project_types." + t) }}-->
                    <!--                </CheckboxToggle>-->
                    <CheckboxToggle v-model="selection.productGroups" value="-">
                        {{ $t("@tasks:issue.without_product_group") }}
                    </CheckboxToggle>
                    <template v-for="group of filters.productGroups">
                        <div :style="'padding-left:' + 20 * group.indent + 'px'">
                            <CheckboxToggle v-model="selection.productGroups" :value="group.id">
                                {{ group["name"] }}
                            </CheckboxToggle>
                        </div>
                    </template>
                </div>
            </div>
            <div class="communication-setting">
                <div class="communication-setting-header">
                    <b>{{ $t("@tasks:projects") }}</b>
                </div>
                <div class="communication-setting-body">
                    <CheckboxToggle v-model="selection.projects" value="-">
                        {{ $t("@tasks:issue.without_project") }}
                    </CheckboxToggle>
                    <CheckboxToggle
                        v-for="project of filteredProjects"
                        v-model="selection.projects"
                        :value="project.id"
                        :key="project.id"
                    >
                        {{ project.name }}
                    </CheckboxToggle>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <button @click="setSelection('all')" class="btn btn-primary">
                {{ $t("@tasks:issue.project_filter.select_all") }}
            </button>
        </div>
        <div class="search-filter mt-4 row">
            <div class="col-4">
                <h2>{{ $t("@tasks:issue.project_filter.save_filter") }}</h2>
                <p>{{ $t("@tasks:issue.project_filter.save_filter.info") }}</p>
                <div class="vb-input-with-button">
                    <input type="text" class="form-control" ref="loginSearchFilterName" />
                    <button type="button" class="" @click="saveLoginSearchFilter">
                        <i class="fa fa-fw fa-save"></i>
                    </button>
                </div>
            </div>
            <div :class="{ 'col-3': !isAdmin, 'col-4': isAdmin }">
                <h2>{{ $t("@tasks:issue.project_filter.my_filters") }}</h2>
                <ul class="list-unstyled mt-2">
                    <template v-for="(filter, index) in props.loginSearchFilters" :key="filter.id">
                        <li v-if="filter.login.id === appConfig.login.id">
                            <div class="d-flex align-items-center">
                                <div class="mr-auto" v-if="loginSearchFilterEditNameFilterId !== filter.id">
                                    {{ filter.name }}
                                </div>
                                <div
                                    class="mr-auto w-100 vb-input-with-button"
                                    style="margin-bottom: 7px; padding-right: 2px"
                                    v-if="loginSearchFilterEditNameFilterId === filter.id"
                                >
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="loginSearchFilterEditNames[index]"
                                    />
                                    <button
                                        type="button"
                                        class="btn-sm"
                                        @click="updateLoginSearchFilter(filter.id, index)"
                                    >
                                        <i class="fa fa-fw fa-save"></i>
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-shape-skewed btn-sm"
                                    style="height: 33.5px"
                                    @click="loginSearchFilterEditNameFilterId = filter.id"
                                    v-if="loginSearchFilterEditNameFilterId !== filter.id"
                                >
                                    <i class="fa fa-fw fa-edit"></i>
                                </button>
                                <VarsityInlineConfirmButton
                                    class="btn btn-danger btn-shape-skewed btn-sm"
                                    style="height: 33.5px"
                                    :controller="deleteLoginSearchFilterConfirm(filter.id)"
                                >
                                    <i class="fas fa-fw fa-trash-alt"></i>
                                </VarsityInlineConfirmButton>
                                <div v-if="isAdmin">
                                    <VfDropdown>
                                        <template #default="{ toggleDropdown }">
                                            <button
                                                class="btn btn-primary btn-shape-skewed btn-sm"
                                                :class="{
                                                    'btn-success': filter.exposed || findShallowCopy(filter)?.exposed,
                                                }"
                                                type="button"
                                                @click="toggleDropdown"
                                            >
                                                <i class="fa fa-fw fa-share-square"></i>
                                            </button>
                                        </template>

                                        <template #menu="{ closeDropdown }">
                                            <div class="dropdown-menu">
                                                <div class="row">
                                                    <div class="col">
                                                        <h6 class="dropdown-header mt-0">
                                                            {{ $t("@app:login.loginSearchFilter.forThisPage") }}
                                                        </h6>
                                                        <div class="dropdown-divider"></div>
                                                        <a
                                                            class="dropdown-item"
                                                            :class="{ active: !filter.exposed }"
                                                            @click="
                                                                closeDropdown(exposeLoginSearchFilter(filter, false))
                                                            "
                                                        >
                                                            {{ $t("@app:login.loginSearchFilter.notExposed") }}
                                                        </a>
                                                        <a
                                                            class="dropdown-item"
                                                            :class="{
                                                                active: filter.exposed && !filter.exposedToUserGroup,
                                                            }"
                                                            @click="
                                                                closeDropdown(
                                                                    exposeLoginSearchFilter(filter, true, null),
                                                                )
                                                            "
                                                        >
                                                            {{ $t("@app:login.loginSearchFilter.forEverybody") }}
                                                        </a>
                                                        <h6 class="dropdown-header">
                                                            {{ $t("@app:login.loginSearchFilter.forUserGroup") }}
                                                        </h6>
                                                        <a
                                                            class="dropdown-item"
                                                            :class="{
                                                                active:
                                                                    filter.exposed &&
                                                                    filter.exposedToUserGroup?.id === userGroup.id,
                                                            }"
                                                            v-for="userGroup in userGroups"
                                                            @click="
                                                                closeDropdown(
                                                                    exposeLoginSearchFilter(filter, true, userGroup),
                                                                )
                                                            "
                                                        >
                                                            {{ userGroup.name }}
                                                        </a>
                                                    </div>
                                                    <div class="col">
                                                        <h6 class="dropdown-header mt-0">my-profile</h6>
                                                        <div class="dropdown-divider"></div>
                                                        <a
                                                            class="dropdown-item"
                                                            :class="{ active: !findShallowCopy(filter) }"
                                                            @click="
                                                                closeDropdown(
                                                                    deleteLoginSearchFilter(
                                                                        findShallowCopy(filter)?.id,
                                                                    ),
                                                                )
                                                            "
                                                        >
                                                            {{ $t("@app:login.loginSearchFilter.notExposed") }}
                                                        </a>
                                                        <a
                                                            class="dropdown-item"
                                                            :class="{
                                                                active:
                                                                    findShallowCopy(filter) &&
                                                                    !findShallowCopy(filter)?.exposedToUserGroup,
                                                            }"
                                                            @click="
                                                                closeDropdown(
                                                                    exposeLoginSearchFilter(
                                                                        filter,
                                                                        true,
                                                                        null,
                                                                        '/my-varsity/my-profile/my-communication-tasks/issue',
                                                                        findShallowCopy(filter),
                                                                    ),
                                                                )
                                                            "
                                                        >
                                                            {{ $t("@app:login.loginSearchFilter.forEverybody") }}
                                                        </a>
                                                        <h6 class="dropdown-header">
                                                            {{ $t("@app:login.loginSearchFilter.forUserGroup") }}
                                                        </h6>
                                                        <a
                                                            class="dropdown-item"
                                                            :class="{
                                                                active:
                                                                    findShallowCopy(filter)?.exposed &&
                                                                    findShallowCopy(filter)?.exposedToUserGroup?.id ===
                                                                        userGroup.id,
                                                            }"
                                                            v-for="userGroup in userGroups"
                                                            @click="
                                                                closeDropdown(
                                                                    exposeLoginSearchFilter(
                                                                        filter,
                                                                        true,
                                                                        userGroup,
                                                                        '/my-varsity/my-profile/my-communication-tasks/issue',
                                                                        findShallowCopy(filter),
                                                                    ),
                                                                )
                                                            "
                                                        >
                                                            {{ userGroup.name }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </VfDropdown>
                                </div>
                            </div>
                            <div>
                                <VarsityInlineConfirm :controller="deleteLoginSearchFilterConfirm(filter.id)">
                                    <template #confirmation>
                                        {{ $t("@tasks:issue.project_filter.delete_confirmation") }}
                                    </template>
                                    <template #success>{{ $t("@tasks:issue.project_filter.delete_success") }}</template>
                                </VarsityInlineConfirm>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>
