<script setup lang="ts">
import type { NewsPost } from "@/model/c-m-s/news-post"
import { $date, useApp } from "@/vf"
import { toRefs } from "vue"
import SidebarButtonWithImage from "../Elements/generic/SidebarButtonWithImage.vue"

const props = defineProps<{
    post: NewsPost
}>()
const { apiLink } = useApp()

const { post } = toRefs(props)
</script>

<template>
    <RouterLink :to="{ name: '@Cms.Frontend.news_post', params: { slug: post.slug } }">
        <SidebarButtonWithImage :image-url="apiLink(`/media/${post.imageName}`)">
            <template #title>{{ post.title }}</template>
            <template #subtitle>{{ $date(post.createdAt) }}</template>
        </SidebarButtonWithImage>
    </RouterLink>
</template>
