import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"

export async function useServerSideLocales() {
    const http = useLegacyHttpClient()
    const messages = (await http.get("locales.json")).content

    return {
        messages,
    }
}
