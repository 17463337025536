import {defineStore} from "pinia"
import {ref} from "vue"
import {useHttpClient} from "@/vf"

export interface GlobalState {
    todos?: { route: string; count: number }[]
}

export const useGlobalState = defineStore("global-state", () => {
    const globalState = ref<GlobalState>({})
    const http = useHttpClient()
    let readyResolve
    const globalStateReady = new Promise(resolve => {
        readyResolve = resolve
    })
    let timer = null

    async function refreshGlobalState() {
        globalState.value = (await http.getBg<GlobalState>("/global-state")).data
        if (readyResolve) {
            readyResolve()
            readyResolve = null
        }

        clearTimeout(timer) // clear a running timer if refreshGlobalState was called manually
        timer = setTimeout(refreshGlobalState, 15_000)
    }

    refreshGlobalState().then()

    return {
        globalState,
        globalStateReady,
        refreshGlobalState,
    }
})
