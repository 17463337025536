import { useLocale } from "@/composables/useLocale"
import { reconfigureRoutes } from "@/router/reconfigureRoutes"
import { defineVfConfig } from "./vf/config/VfConfig"

export default defineVfConfig({
    reconfigureRoutes,
    http: {
        requestHandlers: [
            ({ client }) => {
                client.interceptors.request.use(function (config) {
                    config.headers.set("Accept-Language", useLocale().locale.value)
                    return config
                })
            },
        ],
    },
    async getLocalStorage(): Promise<Storage> {
        const { useCookieConsent } = await import("@/composables/useCookieConsent")
        const { inMemoryLocalStorage } = await import("@/vf/composables/useInMemoryStorage")
        if (useCookieConsent().isAccepted("functional")) {
            return window.localStorage
        } else {
            return inMemoryLocalStorage().storage
        }
    },
    async getSessionStorage(): Promise<Storage> {
        const { useCookieConsent } = await import("@/composables/useCookieConsent")
        const { inMemorySessionStorage } = await import("@/vf/composables/useInMemoryStorage")
        if (useCookieConsent().isAccepted("functional")) {
            return window.sessionStorage
        } else {
            return inMemorySessionStorage().storage
        }
    },
})
