<script setup lang="ts">
import type { NewsPost } from "@/model/c-m-s/news-post"
import { $date, useApp } from "@/vf"
import { toRefs } from "vue"

const props = defineProps<{
    post: NewsPost
}>()
const { apiLink } = useApp()

const { post } = toRefs(props)
</script>

<template>
    <RouterLink class="news-card" :to="{ name: '@Cms.Frontend.news_post', params: { slug: post.slug } }">
        <div class="news-card--image">
            <!-- <div class="news-card--image" :style="{ backgroundImage: `url(${apiLink(`/media/${post.imageName}`)})` }"></div> -->
            <img :src="apiLink(`/media/${post.imageName}`)" alt="" />
        </div>
        <h3 class="news-card--title">{{ post.title }}</h3>
        <div class="news-card--introduction">
            <p>{{ post.introductionText }}</p>
        </div>

        <div class="news-card--date">
            <p>{{ $date(post.createdAt) }}</p>
        </div>
    </RouterLink>
</template>

<style scoped>
.news-card {
    background-color: #ecf1f4;
    border: 1px solid #ecf1f4;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.news-card:hover {
    text-decoration: none;
}

.news-card--image img {
    width: 100%;
    height: 200px;
    /* aspect-ratio: 1.25; */
    object-fit: cover;
    /* background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    transition: transform 300ms ease-in-out;
    transform: scale(1);
}

.news-card .news-card--image {
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.news-card:hover .news-card--image img {
    transform: scale(1.1);
}

.news-card--title {
    padding: 1rem 1rem;
    color: var(--body-color);
    font-weight: bold;
    transition: color 300ms ease-in-out;
}

.news-card:hover .news-card--title {
    color: #0b6fba;
}

.news-card--introduction {
    padding: 0 1rem;
    flex-grow: 1;
    color: var(--body-color);
}

.news-card--date {
    font-family: "Roboto Slab", serif;
    font-weight: bold;
    padding: 0 1rem;
    color: #0b6fba;
}
</style>
