<script setup lang="ts">
import { useMenuBuilder, type VfMenuItem } from "@/composables/useMenuBuilder"
import { useNavbarDropdownState } from "@/stores/useNavbarDropdownState"
import { default as VfDropdown, type CalculateStyleValues } from "@/vf/components/VfDropdown.vue"
import VarsityContentProcessor from "../VarsityContentProcessor.vue"

const props = defineProps<{
    menu: VfMenuItem
}>()

const state = useNavbarDropdownState()
const { getFirstChildRoutingData, countTodos } = useMenuBuilder()

function calculateDropdownStyle(values: CalculateStyleValues) {
    values.style.left = "auto"
}
</script>
<template>
    <template v-for="item of props.menu.children">
        <li
            class="nav-item"
            v-if="item.label"
            :class="{ 'active': item.hasActiveChildren, 'has-active-children': item.hasActiveChildren }"
        >
            <VfDropdown
                menu-class="navbar-submenu navbar-submenu-dark"
                container-class="d-flex flex-column align-items-center h-100"
                button-class="flex-grow-1 d-flex align-items-center"
                @calculate-style="values => calculateDropdownStyle(values)"
                @closed="state.submenuOnClose(1, item)"
            >
                <template #default="{ openDropdown, closeDropdown, isOpen }">
                    <RouterLink
                        class="nav-link position-relative"
                        :to="getFirstChildRoutingData(item)"
                        @mouseover="state.hover(1, item, openDropdown, closeDropdown)"
                        @mouseleave="state.leave(1, item)"
                    >
                        <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                        <span
                            v-if="countTodos(item).value > 0"
                            class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-light ml-2"
                        >
                            {{ countTodos(item) }}
                        </span>
                        <i
                            v-if="item.children?.length > 1"
                            class="fa fa-fw fa-chevron-down small ml-1 dropdown-icon"
                            :class="{ 'is-open': isOpen }"
                        ></i>
                    </RouterLink>
                </template>
                <template #menu="{ closeDropdown }">
                    <template v-for="child of item.children">
                        <RouterLink
                            :to="getFirstChildRoutingData(child)"
                            class="navbar-submenu-item"
                            :class="{ active: child.hasActiveChildren }"
                            @click="closeDropdown()"
                            @mouseover="state.hover(1, item)"
                            @mouseleave="state.leave(1, item)"
                            v-if="child.label"
                        >
                            <VarsityContentProcessor :content="$t(child.label)"></VarsityContentProcessor>
                        </RouterLink>
                    </template>
                </template>
            </VfDropdown>
        </li>
    </template>
</template>
