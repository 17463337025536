import { normalizeModuleConfiguration, useApp, type ModuleConfiguration, type ModuleConfigurationOptions } from "@/vf"

export function createModuleConfiguration(
    organizationId: string,
    subjectType: string,
    subjectId: string,
): ModuleConfiguration {
    const app = useApp()
    const $config: ModuleConfigurationOptions = {}
    $config.apiBase = () => "/order/confirmation"
    $config.routePrefix = () => "@Order.OrderConfirmation"
    $config.readableName = {
        plural: "Order Confirmation",
        singular: "Order Confirmation",
        entity: entity => entity.number,
    }
    $config.list = {
        onRequest({ params }) {
            return { url: `/order/confirmation/${organizationId}/${subjectType}/${subjectId}`, params }
        },
        onClick(item) {
            window.open(
                app.apiLink(
                    `/order/confirmation/${organizationId}/${subjectType}/${subjectId}/${item.id}/order-confirmation`,
                ),
            )
        },
        fields: [
            {
                name: "createdAt",
                sortField: "a.createdAt",
                title: "@order:order_confirmation.date",
                type: "datetime",
            },
            {
                name: "orderConfirmationNumber",
                sortField: "a.orderConfirmationNumber",
                title: "@order:order_confirmation.orderConfirmationNumber",
                type: "string",
            },
            {
                name: "sumInCents",
                sortField: "a.sumInCents",
                title: "@order:order_confirmation.sum",
                type: "currency",
            },
        ],
    }

    return normalizeModuleConfiguration($config)
}
