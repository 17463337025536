<script setup lang="ts">
// from https://github.com/arabsight/aurelia-quill-plugin
// noinspection JSUnusedGlobalSymbols
import MarkdownIt from "markdown-it"
import Quill, { type QuillOptionsStatic } from "quill"
import QuillMarkdown from "quilljs-markdown"
import TurndownService from "turndown"
import { onMounted, onUnmounted, ref, watch } from "vue"

const props = defineProps<{
    modelValue?: string
    options?: Partial<QuillOptionsStatic>
    markdown?: boolean
    enableShowSource?: boolean
    context?: any
    // enableImagePicker?: boolean
}>()

const emit = defineEmits<{
    (e: "update:modelValue", value: string): void
}>()

// const enableImagePicker = computed(
//     () => props.enableImagePicker || (props.context?.attrs?.["enable-image-picker"] ?? false),
// )

/** The bound value of the input which is delegated to the outer v-model */
const value = ref<string>(props.modelValue ?? props.context?.value ?? "")

/* Editor HTML element */
const quillEditor = ref<HTMLDivElement>(null)
const quillToolbar = ref<HTMLDivElement>(null)
const turndown = new TurndownService()
let editor: Quill | null = null
let textChanged = false

// tell quill to add the "img-fluid" class on all images that are inserted
var Image = Quill.import("formats/image")
Image.className = "img-fluid"
Quill.register(Image, true)

/*─────────────────────────────────────┐
│   show source                        │
└─────────────────────────────────────*/
const showSource = ref(false)

function toggleShowSource() {
    showSource.value = !showSource.value

    if (!showSource.value) {
        valueChanged(value.value, undefined)
    }
}

/*─────────────────────────────────────┐
│   change handlers                    │
└─────────────────────────────────────*/
function valueChanged(newValue: string, oldValue: string) {
    if (
        newValue !== oldValue &&
        editor.root.innerHTML !== newValue &&
        turndown.turndown(editor.root.innerHTML) !== newValue &&
        textChanged === false &&
        !showSource.value
    ) {
        if (newValue || newValue === "") {
            let html: string
            if (props.markdown) {
                const md = new MarkdownIt()
                html = value.value.replace(/(\r?\n *\r?\n *\r?\n *)/, "\n\n{PAR}\n\n") // insert dummy text so that the markdown renderer inserts a <p>
                html = md.render(html)
                html = html.replace(
                    /{align (justify|left|right|center)}(.*?){\/align}/gs,
                    '<p class="ql-align-$1">$2</p>',
                )
                html = html.replace('<p><p class="al-align-', '<p class="al-align-')
                html = html.replace("</p></p>", "</p>")
                html = html.replace("{PAR}", "") // remove the dummy text
            } else {
                html = value.value
            }

            editor.root.innerHTML = html.replace(/>\s</g, "><")
        }
    }

    emit("update:modelValue", newValue)
    textChanged = false
}

function onTextChanged() {
    textChanged = true
    let out = editor.root.innerHTML
    if (props.markdown) {
        out = out.replace(/>\s</g, "><")
        out = out.replace(/<p class="ql-align-(justify|left|right|center)">(.*?)<\/p>/gs, "{align $1}$2{/align}")
        //@ts-ignore
        out = turndown.turndown(out)
    }
    value.value = out
    if (props.context) {
        props.context.node.input(out)
    }
}

// value has been changed in the editor
watch(value, valueChanged)

// value has been changed from outside
watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue ?? ""
    },
)

/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
onMounted(() => {
    let editorConfig = {
        modules: {
            toolbar: quillToolbar.value,
        },
        theme: "snow",
    }

    // initialize a new instance of the Quill editor
    // with the supplied options
    editor = new Quill(quillEditor.value as unknown as HTMLDivElement, editorConfig)
    // Quill.register(YouTubePlaceholderBlot)

    // listen for changes and update the value
    editor.on("text-change", onTextChanged)

    if (props.markdown) {
        new QuillMarkdown(editor)
    }

    valueChanged(value.value, undefined)
})

/*─────────────────────────────────────┐
│   image upload                       │
└─────────────────────────────────────*/
// const { apiLink } = useApp()
// const [currentMenu, openMenu] = useToggleableValue<"imageSelect" | "youtube">()

// function insertImageFromMediaLibrary(imagePath: string) {
//     // add image to quill editor
//     const range = editor.getSelection()
//     editor.insertEmbed(range?.index ?? editor.getLength(), "image", apiLink(`/media/${imagePath}`))
//
//     // add image to the value
//     value.value = editor.root.innerHTML
//     emit("update:modelValue", value.value)
//
//     openMenu(null)
// }

/*─────────────────────────────────────┐
│   youtube embed                      │
└─────────────────────────────────────*/
// const youtubeUrl = ref("https://www.youtube.com/watch?v=aXOChLn5ZdQ")
//
// // stolen from https://stackoverflow.com/a/21607897
// function getYoutubeIdFromUrl(url: string) {
//     const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
//     const match = url.match(regExp)
//
//     return match && match[2].length === 11 ? match[2] : null
// }
//
// function insertYoutubeEmbed() {
//     const youtubeVideoId = getYoutubeIdFromUrl(youtubeUrl.value)
//
//     if (!youtubeVideoId) {
//         console.log("No video id found in url", youtubeUrl.value)
//         return
//     }
//
//     const range = editor.getSelection()
//     const placeholderUrl = apiLink(`/youtube-preview-image/faq/${encodeURIComponent(youtubeVideoId)}`)
//     editor.insertEmbed(
//         range?.index ?? editor.getLength(),
//         "youtubePlaceholder",
//         JSON.stringify({ youtubeVideoId, placeholderUrl }),
//     )
//     editor.insertText((range?.index ?? editor.getLength()) + 1, "\n")
//
//     console.log("contents", editor.getContents())
//
//     // add image to the value
//     value.value = editor.root.innerHTML
//     emit("update:modelValue", value.value)
//
//     youtubeUrl.value = ""
//     openMenu(null)
// }

/*─────────────────────────────────────┐
│   tear down                          │
└─────────────────────────────────────*/
onUnmounted(() => {
    editor.off("text-change", onTextChanged)

    // let toolbar = quillEditor.value.parentNode.querySelector(".ql-toolbar")
    //
    // if (toolbar) {
    //     toolbar.remove()
    // }

    // editor.options.modules.toolbar = null
    // delete editor.options.modules.toolbar

    // editor.theme.modules.toolbar = null
    // delete editor.theme.modules.toolbar

    editor = null
})
</script>

<template>
    <div>
        <div ref="quillToolbar">
            <div class="ql-toolbar">
                <span class="ql-formats">
                    <select class="ql-header ql-picker" v-if="!props.markdown">
                        <option selected></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <select class="ql-align">
                        <option></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                    <button type="button" class="ql-link" v-if="!props.markdown"></button>
                    <button type="button" class="ql-clean"></button>
                </span>
                <div class="ql-formats">
                    <button type="button" class="ql-bold"></button>
                    <button type="button" class="ql-italic"></button>
                    <button type="button" class="ql-underline"></button>
                    <button type="button" class="ql-strike"></button>
                </div>
                <span class="ql-formats" v-if="!props.markdown">
                    <select class="ql-color" title="Textfarbe"></select>
                    <select class="ql-background" title="Hintergrundfarbe"></select>
                </span>
                <div class="ql-formats">
                    <button type="button" class="ql-list" value="ordered"></button>
                    <button type="button" class="ql-list" value="bullet"></button>
                    <!-- <button -->
                    <!--     type="button" -->
                    <!--     class="ql-image" -->
                    <!--     v-if="!props.markdown && enableImagePicker" -->
                    <!--     @click.stop.prevent.capture="openMenu('imageSelect')" -->
                    <!-- ></button> -->
                    <!-- <button -->
                    <!--     type="button" -->
                    <!--     class="ql-video" -->
                    <!--     v-if="!props.markdown && enableImagePicker" -->
                    <!--     @click.stop.prevent.capture="openMenu('youtube')" -->
                    <!-- ></button> -->
                </div>
            </div>

            <!-- <div v-animate-show="currentMenu === 'imageSelect'" class="p-3" v-if="enableImagePicker"> -->
            <!--     <MediaLibraryImagePicker @selected="insertImageFromMediaLibrary"></MediaLibraryImagePicker> -->
            <!---->
            <!--     <span class="btn btn-primary mt-4" @click="openMenu('imageSelect')"> -->
            <!--         {{ $t("dialog.button.cancel") }} -->
            <!--     </span> -->
            <!-- </div> -->
            <!---->
            <!-- <div v-animate-show="currentMenu === 'youtube'" class="p-3" v-if="enableImagePicker"> -->
            <!--     <input -->
            <!--         type="text" -->
            <!--         v-model="youtubeUrl" -->
            <!--         class="form-control" -->
            <!--         placeholder="https://www.youtube.com/watch?v=..." -->
            <!--     /> -->
            <!---->
            <!--     <div class="d-flex justify-space-between"> -->
            <!--         <span class="btn btn-primary mt-4" @click="openMenu('imageSelect')"> -->
            <!--             {{ $t("dialog.button.cancel") }} -->
            <!--         </span> -->
            <!--         <span class="btn btn-primary mt-4" @click="insertYoutubeEmbed()">{{ $t("dialog.button.ok") }}</span> -->
            <!--     </div> -->
            <!-- </div> -->
        </div>

        <div ref="quillEditor" v-show="!showSource"></div>
        <textarea v-model="value" v-show="showSource" rows="10" class="form-control"></textarea>
        <button type="button" v-if="props.enableShowSource" @click="toggleShowSource" class="btn btn-secondary mt-2">
            Quelltext umschalten
        </button>
    </div>
</template>

<style lang="scss">
@import "./upstream/snow.scss";

:root {
    display: flex;
    flex-direction: column;

    .ql-container {
        flex-grow: 1;
    }
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

.ql-editor {
    min-height: 200px;
    cursor: text;
}

.ql-editor ol li:before {
    content: "";
}

.ql-color-picker svg {
    height: 100%;
}

.ql-color.ql-picker.ql-color-picker {
    width: 40px;
    display: block !important;
}

.form-white {
    .ql-editor,
    .ql-toolbar {
        background-color: $light;
    }

    .ql-toolbar.ql-snow {
        border-bottom: 1px solid #fff;
    }
}

.quill-light {
    &,
    .ql-editor,
    .ql-toolbar {
        background-color: $light;
    }

    .ql-toolbar.ql-snow {
        border-bottom: 1px solid #fff;
    }
}

.ql-editor .ql-align-justify {
    white-space: pre-line;
}
</style>
