<script setup lang="ts">
import { type Task } from "@/model/tasks/task"
import { $fileTypeIcon, useApp, type Entity } from "@/vf"

const props = defineProps<{
    task: Task
}>()

const { apiLink } = useApp()

function downloadAttachment(attachment: Entity) {
    window.open(apiLink(`/tasks/task/${props.task.id}/attachment/${attachment.id}/download`))
}
</script>

<template>
    <div class="card-body pt-0" v-if="props.task.attachments?.length > 0">
        <div class="border-top border-primary pt-3">
            <div
                class="d-flex align-items-center c-pointer"
                v-for="attachment in props.task.attachments"
                @click="downloadAttachment(attachment)"
            >
                <div><i :class="'fa fa-fw ' + $fileTypeIcon(attachment.mimeType)"></i></div>
                <div class="py-2 px-2">
                    {{ attachment.originalFilename }}
                </div>
            </div>
        </div>
    </div>
</template>
