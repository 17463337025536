<script setup lang="ts">
import VarsityContentProcessor from "@/components/VarsityContentProcessor.vue"
import VarsityFeedbackLoading from "@/components/feedback/VarsityFeedbackLoading.vue"
import DismissableBackdrop from "@/components/layout/DismissableBackdrop.vue"
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { useAppState } from "@/stores"
import { AnimateIf, useAppConfig } from "@/vf"
import { whenever } from "@vueuse/shared"
import { storeToRefs } from "pinia"
import { computed, ref, type Ref } from "vue"
import { useRouter } from "vue-router"
import VarsityFaqSidebarMenu from "./VarsityFaqSidebarMenu.vue"
import { useFaqSidebarStore } from "./useFaqSidebarStore"

const appState = useAppState()
const { appConfig } = useAppConfig()
const http = useLegacyHttpClient()
const router = useRouter()
const currentRouteName = computed(() => router.currentRoute.value.name.toString())

const faqSidebarStore = useFaqSidebarStore()

const { refresh } = faqSidebarStore
const { loading, root, selectedPage } = storeToRefs(faqSidebarStore)

/*─────────────────────────────────────┐
│   update loaded faq                  │
└─────────────────────────────────────*/
whenever(
    () => appState.faqMode,
    () => {
        // when opening sidebar, try to open the faq for the page that is currently open in the router
        refresh(currentRouteName.value)
    },
)

/*─────────────────────────────────────┐
│   assign (admin function)            │
└─────────────────────────────────────*/
const showAssignPage = ref(false)
const loadingAssign = ref(null)
const assignableElements: Ref<{ id: string; type: string; name: string }[]> = ref(null)
const assignedFaqPageId = ref(null)

function toggleAssign() {
    showAssignPage.value = !showAssignPage.value

    if (!assignableElements.value) {
        loadingAssign.value = http.get("/faq-admin/pages-select").then(r => (assignableElements.value = r.content))
    }
}

function saveAssignment() {
    http.post(`/faq-admin/assign-route/${currentRouteName.value}`, {
        page: assignedFaqPageId.value,
    })
    showAssignPage.value = false
    refresh(currentRouteName.value)
}
</script>

<template>
    <div class="faq-sidebar" :class="{ active: appState.faqMode }">
        <div class="bg-primary text-white px-4 py-2">
            <div class="text-right">
                <i
                    class="fa fa-link fa-2x c-pointer ml-3"
                    v-if="appConfig.canManageFaq"
                    :title="$t('faq:assign_page')"
                    @click="toggleAssign()"
                ></i>
                <i class="fa fa-times fa-2x c-pointer ml-3" @click="appState.faqMode = false"></i>
            </div>

            <div class="d-flex my-5 align-items-center">
                <!-- <div>
                    <i
                        class="fa fa-fw fa-arrow-left c-pointer"
                        v-if="selectedItem && selectedItem.id !== 'root'"
                        @click="goUp()"
                    ></i>
                </div> -->
                <h1 class="m-0 text-center flex-grow-1">{{ selectedPage?.name ?? "Frequently Asked Questions" }}</h1>
            </div>
        </div>

        <div class="faq-sidebar-content p-4">
            <AnimateIf :if="showAssignPage">
                <form
                    class="faq-sidebar-assign-page card-toolbar mb-3"
                    v-spinner="loadingAssign"
                    @submit.prevent="saveAssignment()"
                >
                    <label for="faq_assign_page" t="@faq:assign_page_select"></label>
                    <select class="form-control" id="faq_assign_page" v-model="assignedFaqPageId">
                        <option :value="null"></option>
                        <option
                            v-for="element of assignableElements"
                            :disabled="element.type !== 'page'"
                            :value="element.id"
                        >
                            {{ element.name }}
                        </option>
                    </select>

                    <button type="submit" class="btn btn-primary mt-3" v-t="'crud:button.save'"></button>
                    <button
                        type="button"
                        @click="showAssignPage = false"
                        class="btn btn-primary mt-3"
                        v-t="'crud:button.abort'"
                    ></button>
                </form>
            </AnimateIf>

            <template v-if="loading">
                <VarsityFeedbackLoading></VarsityFeedbackLoading>
            </template>

            <template v-else>
                <div class="row">
                    <div class="col-md-3">
                        <div class="accordion-enumeration-menu">
                            <VarsityFaqSidebarMenu :tree="item" v-for="item of root.children"></VarsityFaqSidebarMenu>
                        </div>
                    </div>
                    <div class="col-md-9" v-if="selectedPage">
                        <div class="accordion-enumeration-card" v-for="item of selectedPage.items">
                            <div class="accordion-enumeration-card-top" @click="item.expanded = !item.expanded">
                                <h3>
                                    <VarsityContentProcessor :content="item.question"></VarsityContentProcessor>
                                </h3>
                                <div class="accordion-enumeration-card-toggle" :class="{ active: item.expanded }">
                                    <i class="fa fa-fw fa-plus"></i>
                                </div>
                            </div>

                            <div v-animate-show="item.expanded === true">
                                <div class="accordion-enumeration-card-content">
                                    <div v-html="item.answer" v-youtube-placeholder></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <!-- <div class="faq-sidebar-content accordion-enumeration">
                    <template v-if="state === 'loaded' && selectedItem.type === 'page'"></template>

                    <div
                        class="alert alert-info text-center py-5"
                        v-if="state === 'loaded' && selectedItem.type === 'page' && !selectedItem.items.length"
                    >
                        {{ $t("@faq:no_faq_for_this_page") }}
                    </div>
                </div> -->
        </div>
    </div>

    <DismissableBackdrop :active="appState.faqMode" @close="appState.faqMode = false" />
</template>

<style lang="scss" scoped>
.faq-sidebar {
    position: fixed;
    width: 60vw;
    min-width: 400px;
    max-width: 100vw;
    background-color: #fff;
    top: 0;
    bottom: 0;
    right: -2rem;
    z-index: 12;
    display: flex;
    flex-direction: column;

    transform: translateX(100vw);
    transition: 500ms ease-in-out transform;

    &.active {
        transform: translateX(-2rem);
    }
}

.faq-sidebar-content {
    overflow-y: auto;
    flex-grow: 1;
}
</style>
