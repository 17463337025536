<script setup lang="ts">
import { type ModuleConfiguration } from "@/vf"
import { ref } from "vue"

const props = defineProps<{
    config: ModuleConfiguration
}>()

const emit = defineEmits(["toggle"])

const active = ref(false)

function toggle() {
    active.value = !active.value
    emit("toggle", active.value)

    props.config.list.requestParams.value = {
        ...(props.config.list.requestParams.value ?? {}),
        onlyMessages: active.value ? "1" : "0",
    }
}
</script>

<template>
    <button
        type="button"
        class="btn btn-shape-rounded btn-shape-rounded-white"
        @click="toggle()"
        :class="active ? 'btn-primary-dark' : 'btn-primary'"
    >
        <i class="fa fa-fw fa-comments"></i>
        <span>
            {{ $t("@tasks:issue.filter_only_messages_button") }}
        </span>
    </button>
</template>
