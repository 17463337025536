import type { Account } from "@/model/app/account"
import type { Login } from "@/model/app/login"
import type { LoginAccess } from "@/model/app/login-access"
import type { StaffMember } from "@/model/staff/staff-member"
import { useAppConfig, type AppConfigMenuNode } from "@/vf"

export interface VarsityAppConfig {
    accesses: LoginAccess[]
    access: any
    account: Account[]
    staffAccount?: StaffMember
    accountHierarchy: any
    languages: { iso: string; name: string }[]
    login: Login
    menu: AppConfigMenuNode[]
    requiredConditions: any
    routes: any
    canManageFaq?: boolean
    switchFrom?: any
    isHeadJudge?: boolean
    panels?: { id: string; number: number }[]
    isSuperAdmin: true | undefined
    canManageLoginSearchFilter: true | undefined
}

export function useVarsityAppConfig() {
    return useAppConfig<VarsityAppConfig>()
}
