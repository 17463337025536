<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        icon: string
        active?: boolean
        white?: boolean
    }>(),
    {
        white: false,
        active: false,
    },
)

const emit = defineEmits<{
    (e: "click", event: MouseEvent)
}>()
</script>

<template>
    <button
        class="btn btn-shape-rounded"
        :class="{ 'btn-shape-rounded-white': white, 'btn-primary': !active, 'btn-primary-dark': active }"
        type="button"
        @click="emit('click', $event)"
    >
        <i :class="props.icon"></i>
        <span><slot /></span>
    </button>
</template>
