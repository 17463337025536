<script setup lang="ts">
import { useApp } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import Flickity from "flickity"
import type { Ref } from "vue"
import { onBeforeUnmount, onMounted, ref } from "vue"
import { calculateStyle } from "../../CmsPage/configuration"
import CmsButtonLink from "./CmsButtonLink.vue"

// props
const props = defineProps<{
    element: any
}>()

const type: "fourpictures" | "carousel" =
    props.element.config.pictureEnumType == "fourpictures" ? "fourpictures" : "carousel"

// functions

function attached() {
    if (type == "carousel") {
        setTimeout(() => {
            if (!tabsElement.value) {
                return
            }

            flickity = new Flickity(tabsElement.value[0], {
                pageDots: false,
                cellAlign: "left",
                wrapAround: true,
            })
        }, 1000)
    }
    if (props.element.config.pictureEnumFirstTabOpen) {
        selectTab(0, props.element?.columns[0][0])
    }
}

let i = 0

function selectTab(lineIndex: number, tab: { config: { disableOpenTab: any } }) {
    if (tab.config.disableOpenTab) return
    lineChanged.value = activeLineIndex.value != lineIndex
    activeLineIndex.value = lineIndex
    activeTab.value = tab
    tabs.value.unshift({ tab, key: i++ })
}

function closeTab() {
    activeTab.value = null
    activeLineIndex.value = null
}

// composables
const { apiLink } = useApp()

// refs
let flickity: Flickity = null
const tabsElement: Ref<HTMLElement> = ref(null)
const activeTab: Ref<any> = ref(null)
const tabs = ref<{ tab: any; key: number }[]>([])
const lineChanged: Ref<any> = ref(false)
const activeLineIndex: Ref<any> = ref(null)
const itemsPerLine = type == "fourpictures" ? 4 : props.element?.columns[0].length
const lineCount = Math.ceil(props.element?.columns[0].length / itemsPerLine)
const lines = []
for (let i = 0; i < lineCount; i++) {
    lines.push([])
}
for (let i = 0; i < props.element?.columns[0].length; i++) {
    lines[Math.floor(i / itemsPerLine)].push(props.element?.columns[0][i])
}
// setup
onMounted(() => attached())
onBeforeUnmount(() => flickity?.destroy())
</script>

<template>
    <div class="picture-enumeration" v-for="(line, lineIndex) of lines">
        <!-- desktop -->
        <div
            :class="{
                'picture-enumeration-tabs': true,
                'main-carousel': type == 'carousel',
                'fourpictures': type == 'fourpictures',
            }"
            class="d-none d-md-flex"
            :style="'justify-content:' + element.config.align"
            ref="tabsElement"
        >
            <div class="carousel-cell" v-for="tab in line">
                <div
                    class="picture-enumeration-tab carousel-cell"
                    @click="selectTab(lineIndex, tab)"
                    :class="{ active: activeTab === tab }"
                    :style="
                        type == 'fourpictures'
                            ? { 'background-image': 'url(' + apiLink('/media/' + tab.fileName) + ')' }
                            : {}
                    "
                >
                    <img
                        v-if="type == 'carousel'"
                        alt=""
                        :src="apiLink('/media/' + tab.fileName)"
                        :style="calculateStyle(tab)"
                    />
                    <div class="picture-enumeration-title">{{ tab.title }}</div>
                    <div v-if="type == 'fourpictures'" class="text-primary mb-4">{{ tab.buttonText }}</div>
                </div>
            </div>
        </div>

        <!-- mobile -->
        <div
            :class="{
                'picture-enumeration-tabs': true,
                'main-carousel': type == 'carousel',
                'fourpictures': type == 'fourpictures',
            }"
            class="d-md-none"
            :style="'justify-content:' + element.config.align"
        >
            <div class="carousel-cell" v-for="tab in line">
                <div
                    class="picture-enumeration-tab carousel-cell"
                    @click="selectTab(lineIndex, tab)"
                    :class="{ active: activeTab === tab }"
                    :style="
                        type == 'fourpictures'
                            ? { 'background-image': 'url(' + apiLink('/media/' + tab.fileName) + ')' }
                            : {}
                    "
                >
                    <img
                        v-if="type == 'carousel'"
                        alt=""
                        :src="apiLink('/media/' + tab.fileName)"
                        :style="calculateStyle(tab)"
                    />
                    <div class="picture-enumeration-title">{{ tab.title }}</div>
                    <div v-if="type == 'fourpictures'" class="text-primary mb-4">{{ tab.buttonText }}</div>
                </div>
            </div>
        </div>
        <AnimateIf :if="activeLineIndex == lineIndex">
            <div class="picture-enumeration-content">
                <div class="picture-enumeration-content-close" @click="closeTab()">
                    <i class="fa fa-fw fa-times"></i>
                </div>
                <template v-for="(tab, $index) in tabs" :key="tab.key">
                    <AnimateIf :if="$index == 0" :animate-on-init="!lineChanged" @unmounted="tabs.splice($index)">
                        <div class="picture-enumeration-content-inner" v-scroll>
                            <h3 :class="{ 'mb-0': type == 'fourpictures' }">{{ tab.tab.title }}</h3>
                            <div v-if="type == 'fourpictures'" class="text-primary mb-4">{{ tab.tab.buttonText }}</div>

                            <div v-html="tab.tab.text"></div>

                            <div class="mt-4" v-if="tab.tab.buttonText && type == 'carousel'">
                                <CmsButtonLink class="btn btn-primary" :element="tab"></CmsButtonLink>
                            </div>
                        </div>
                    </AnimateIf>
                </template>
            </div>
        </AnimateIf>
    </div>
</template>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.fourpictures {
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        margin-bottom: 1.5rem;
    }

    .picture-enumeration-tab {
        text-align: left;
        background-size: cover;
        background-position: center;
        transition: all 0.3s;
        position: relative;

        &:hover:before {
            opacity: 1;
            visibility: visible;
            background-color: rgba(11, 111, 185, 0.7);
        }

        &:before {
            margin-right: 1rem;
            content: "";
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
        }

        > div:first-child {
            padding-top: 75%;
            color: white;
            font-size: 1.7rem;
        }
    }

    > div {
        width: 100%;
        aspect-ratio: 1;
        margin-block: 0.5rem;

        @include media-breakpoint-up(md) {
            width: 25%;
            margin-block: 0;
        }

        > div {
            height: 100%;
        }
    }
}
</style>
