<script setup lang="ts">
import { calculateStyle } from "@/pages/Cms/CmsPage/configuration"
import CmsPictureLink from "./CmsPictureLink.vue"

const props = defineProps<{
    element: any
}>()

let layoutClass: string
switch (parseInt(props.element.config.pictureLinksLayout)) {
    case 1:
        layoutClass = "cms-picture-links--rect"
        break
    case 2:
        layoutClass = "cms-picture-links--single"
        break
    default:
        layoutClass = "cms-picture-links--square"
}
</script>

<template>
    <div class="cms-picture-links" :class="layoutClass" :style="calculateStyle(props.element)">
        <div class="cms-picture-link" v-for="card in props.element?.columns[0]">
            <CmsPictureLink v-bind="{ element: card }" />
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "@/styles/variables";

.cms-picture-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}

.cms-picture-links--square {
    :deep(.picture-links-item) {
        aspect-ratio: 1;
    }

    @include media-breakpoint-up(md) {
        .cms-picture-link {
            flex: 1 1 50%;
            padding: 1rem;
            max-width: 50%;
        }
    }

    @include media-breakpoint-up(lg) {
        .cms-picture-link {
            flex: 1 1 25%;
            padding: 1rem;
            max-width: 25%;
        }
    }
}

.cms-picture-links--rect {
    :deep(.picture-links-item) {
        aspect-ratio: 2;
    }

    @include media-breakpoint-up(md) {
        .cms-picture-link {
            flex: 1 1 50%;
            padding: 1rem;
            max-width: 50%;
        }
    }

    @include media-breakpoint-up(lg) {
        .cms-picture-link {
            flex: 1 1 50%;
            padding: 1rem;
            max-width: 50%;
        }
    }
}

.cms-picture-link {
    flex: 1 1 100%;
    padding: 0;
    max-width: 100%;
}
</style>
