<script setup lang="ts">
import type { DebitorProfile } from "@/model/app/debitor-profile"
import { useHttpClient } from "@/vf"
import { computed, ref } from "vue"
import type { ChampionshipRegistrationSelectTeamData } from "./championship-registration"

const props = defineProps<{
    data: ChampionshipRegistrationSelectTeamData
}>()
const emit = defineEmits<{
    (e: "updated"): void
}>()

const http = useHttpClient()
const isValid = computed(() => !!selectedDebitorProfile.value)

const selectedDebitorProfile = ref<DebitorProfile>(props.data.debitorProfile)

async function submit() {
    if (!isValid.value) {
        return
    }

    await http.post(`/championship/registration/${props.data.registrationId}/change-debitor-profile`, {
        debitorProfileId: selectedDebitorProfile.value.id,
    })
    emit("updated")
}
</script>
<template>
    <form @submit.prevent="submit()">
        <select v-model="selectedDebitorProfile" class="custom-select">
            <option :value="item" v-for="item of data.availableDebitorProfiles" :key="item.id">
                {{ item.name }}
            </option>
        </select>

        <button class="btn btn-primary my-4" :disabled="!isValid" type="submit">
            {{ $t("crud:button.save") }}
        </button>
    </form>
</template>
