<script setup lang="ts">
import { useHttpClient } from "@/vf"
import { ref } from "vue"
import ChampionshipRegistrationContactPersonForm from "./ChampionshipRegistrationContactPersonForm.vue"
import type {
    ChampionshipRegistrationContactPerson,
    ChampionshipRegistrationSelectTeamData,
} from "./championship-registration"

const props = defineProps<{
    data: ChampionshipRegistrationSelectTeamData
}>()
const emit = defineEmits<{
    (e: "updated"): void
}>()

const http = useHttpClient()
const isValid = ref(false)

const contactPerson = ref<ChampionshipRegistrationContactPerson>({
    contactPersonName: props.data.contactPerson.contactPersonName,
    contactPersonPhone: props.data.contactPerson.contactPersonPhone,
    contactPersonMail: props.data.contactPerson.contactPersonMail,
})

async function submit() {
    if (!isValid.value) {
        return
    }

    await http.post(`/championship/registration/${props.data.registrationId}/change-contact-person`, {
        ...contactPerson.value,
    })
    emit("updated")
}
</script>
<template>
    <form class="px-4" @submit.prevent="submit()">
        <ChampionshipRegistrationContactPersonForm :data="data" v-model="contactPerson" @validate="isValid = $event" />

        <button class="btn btn-primary my-4" :disabled="!isValid" type="submit">
            {{ $t("crud:button.save") }}
        </button>
    </form>
</template>
