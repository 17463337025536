<script setup lang="ts">
import { useCookieConsent } from "@/composables/useCookieConsent"
import { useNavbarState } from "@/stores"
import { useAppConfig } from "@/vf"
import { storeToRefs } from "pinia"
import { useRouter } from "vue-router"

const navbarState = useNavbarState()
const { showMobileSidebar } = storeToRefs(navbarState)
const { toggleMobileSidebar } = navbarState

const isProduction = import.meta.env.VITE_ENVIRONMENT === "live"
const { appConfig } = useAppConfig()
const router = useRouter()
const { openOverlay: openCookieConsentOverlay } = useCookieConsent()
function loginOrMyProfile() {
    if (!appConfig.value.login) {
        navbarState.openCard("login")
        return
    }

    if (router.hasRoute("@App.MyVarsity.myProfile")) {
        router.push({ name: "@App.MyVarsity.myProfile" })
        return
    }
}
</script>

<template>
    <div class="vb-mobile-header-container">
        <div class="vbmh-first-row">
            <div class="vbmh-search">
                <RouterLink :to="{ name: '@App.Search.results' }">
                    <i class="fa fa-fw fa-search"></i>
                    Suche
                </RouterLink>
            </div>
            <div class="vbmh-login" @click="loginOrMyProfile" v-if="!isProduction">
                <a href="#">myVarsity Account</a>
            </div>
            <div class="vbmh-login vbmh-settings" @click="openCookieConsentOverlay">
                <a href="#"><i class="fa fa-cog"></i></a>
            </div>
        </div>
        <div class="vbmh-second-row">
            <RouterLink class="vbmh-logo" to="/">
                <img src="/assets/varsity-v-color.png" />
            </RouterLink>
            <button class="vbmh-menu-button" @click="toggleMobileSidebar()">
                <i class="fa fa-fw" :class="showMobileSidebar ? 'fa-times-circle' : 'fa-bars'"></i>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "@/styles/variables";

.vb-mobile-header-container {
    .vbmh-first-row {
        display: flex;
        height: 3rem;

        .vbmh-search,
        .vbmh-login {
            flex: 1;
            background-color: #0b6fb9;
            color: #fff;
            display: flex;
            align-items: stretch;
            justify-content: stretch;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                color: #fff;
                font-size: 0.75rem;
                font-weight: bold;
            }
        }

        .vbmh-login {
            position: relative;
        }

        .vbmh-login:before {
            content: "";
            position: absolute;
            width: 42px;
            height: 100%;
            top: 0;
            background: linear-gradient(to right, #084e82 0%, #0b6fb9 100%);
            transform: skew(-30deg);
        }

        .vbmh-settings {
            flex: 0 0;
            min-width: 7rem;
        }
    }

    .vbmh-second-row {
        display: grid;
        grid-template-columns: 1fr 5rem;
        grid-auto-rows: 3rem;

        .vbmh-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-row: 1;
            grid-column: 1 / to 2;

            img {
                height: 1.5rem;
            }
        }

        .vbmh-menu-button {
            appearance: none;
            background-color: #dbe5ed;
            border: none;
            grid-row: 1;
            grid-column: 2;

            display: flex;
            align-items: center;
            justify-content: center;

            padding-left: 2rem;
            clip-path: polygon(2rem 0, 100% 0, 100% 100%, 0 100%);

            i {
                font-size: 1.5rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
</style>
