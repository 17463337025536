<script setup lang="ts">
import { onClickOutside, useElementBounding, useElementSize, type UseElementBoundingReturn } from "@vueuse/core"
import type { Ref } from "vue"
import { computed, ref } from "vue"

const isOpen = ref(false)
const container: Ref<HTMLElement> = ref(null!)
const button: Ref<HTMLElement> = ref(null!)
const menu: Ref<HTMLElement> = ref(null!)

const props = withDefaults(
    defineProps<{
        containerClass?: string
        menuClass?: string
        buttonClass?: string
        applyContainerWidthToMenu?: boolean
        menuStyle?: any
    }>(),
    {
        containerClass: undefined,
        menuClass: undefined,
        buttonClass: undefined,
        applyContainerWidthToMenu: false,
        menuStyle: undefined,
    },
)

export type CalculateStyleValues = {
    top: string
    left: string
    right: string
    width: string
    applyContainerWidthToMenu: boolean
    buttonRect: UseElementBoundingReturn
    menuRect: UseElementBoundingReturn
    containerRect: UseElementBoundingReturn
    style: CSSStyleDeclaration
}

const emit = defineEmits<{
    (e: "opened"): void
    (e: "closed"): void
    (e: "calculateStyle", values: CalculateStyleValues): void
}>()

const containerRect = useElementBounding(container)
const buttonRect = useElementBounding(button)
const menuRect = useElementBounding(menu)
const { width: windowWidth } = useElementSize(document.body)

// we use v-if to hide the menu below so that this computation is only done when the menu is actually visible
const menuStyle = computed(() => {
    const top = buttonRect.top.value + buttonRect.height.value + "px"
    let left = "auto"
    let right = "auto"
    let width = "auto"

    // console.log(button)
    // console.log("left", buttonRect.left.value)
    // console.log("width", buttonRect.width.value)
    // console.log("right", buttonRect.right.value)
    // console.log("window", windowWidth.value)
    // console.log("-")

    if (menuRect.width.value + buttonRect.left.value >= windowWidth.value) {
        // menu hits the right screen edge, position it to the right
        right = windowWidth.value - buttonRect.right.value + "px"
        left = "auto"
    } else {
        // position normally at button level
        left = buttonRect.left.value + "px"
        right = "auto"
    }

    if (props.applyContainerWidthToMenu) {
        width = containerRect.width.value + "px"
    }

    // values that will be passed to the user, in case they want to override the style
    const values: CalculateStyleValues = {
        top,
        left,
        right,
        width,
        applyContainerWidthToMenu: props.applyContainerWidthToMenu,
        buttonRect: buttonRect,
        menuRect: menuRect,
        containerRect: containerRect,
        style: { ...props.menuStyle, top, left, right, width },
    }

    // allow the user to override the style
    emit("calculateStyle", values)

    // if the menu is below the screen, make the body higher
    if (menuRect.bottom.value > document.body.getBoundingClientRect().height) {
        document.body.style.height = menuRect.bottom.value + "px"
    }

    return values.style
})

onClickOutside(container, () => closeDropdown())

async function openDropdown() {
    isOpen.value = true

    // await nextTick()
    containerRect.update()
    buttonRect.update()
    menuRect.update()

    emit("opened")
}

function toggleDropdown() {
    if (isOpen.value) {
        closeDropdown()
    } else {
        openDropdown()
    }
}

/**
 * @param _sideEffect Do whatever you want when closing the dropdown. (@click="closeDropdown(foo = 123)")
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function closeDropdown(_sideEffect: any = undefined) {
    isOpen.value = false
    emit("closed")
}
</script>

<template>
    <div ref="container" :class="containerClass">
        <div ref="button" :class="buttonClass">
            <slot v-bind="{ openDropdown, toggleDropdown, closeDropdown, isOpen }"></slot>
        </div>

        <div v-if="isOpen" ref="menu" :style="menuStyle" style="position: fixed; z-index: 5" :class="menuClass">
            <slot name="menu" v-bind="{ closeDropdown }"></slot>
        </div>
    </div>
</template>

<style scoped>
/* bootstrap sets this to absolute by default, which breaks getting the size of the menu */
:deep(.dropdown-menu) {
    position: static;
    display: block;
}
</style>
