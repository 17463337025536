<script setup lang="ts">
import { useMenuBuilder } from "@/composables/useMenuBuilder"
import { compare } from "@/vf"
import { refThrottled } from "@vueuse/core"
import { onMounted, ref, watch, type Ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import VarsityContentProcessor from "../VarsityContentProcessor.vue"

const router = useRouter()
const { routableMenuItems } = useMenuBuilder()
const { t } = useI18n()
const { getFirstChildRoutingData } = useMenuBuilder()

const emits = defineEmits(["close"])
const props = withDefaults(defineProps<{ query?: string }>(), { query: "" })

/*─────────────────────────────────────┐
│   search                             │
└─────────────────────────────────────*/
const searchQuery = ref(props.query)
const searchResults: Ref<{ path: string[]; item: any }[]> = ref([])
const highlighted: Ref<number | null> = ref(null)

function keyDown(event: KeyboardEvent) {
    switch (event.key) {
        case "ArrowDown":
            highlighted.value = highlighted.value === null ? 0 : highlighted.value + 1
            event.preventDefault()
            return false

        case "ArrowUp":
            highlighted.value = highlighted.value === null ? 0 : highlighted.value - 1
            event.preventDefault()
            return false

        case "Enter": {
            const idx = highlighted.value || 0

            if (searchQuery.value === "sentry") {
                throw new Error("Sentry test error")
            }

            if (searchResults.value[idx]) {
                navigate(searchResults.value[idx])
            }

            return false
        }

        default:
            highlighted.value = null
            break
    }

    return true
}

function keyPress(event: KeyboardEvent) {
    highlighted.value = null

    return true
}

// todo: remove recursion as we use an already flattened model now
async function updateSearch() {
    const searchValue = searchQuery.value.toLocaleLowerCase()

    const getResults = (menu, path) => {
        const results = []

        for (const item of menu) {
            if (item.settings?.hideInSearch) {
                continue
            }

            if (item.children) {
                if (
                    item.label.toLocaleLowerCase().indexOf(searchValue) >= 0 ||
                    t(item.label).toLocaleLowerCase().indexOf(searchValue) >= 0 ||
                    item.settings?.keywords?.indexOf(searchValue) >= 0
                ) {
                    let firstChildRoute = getFirstChildRoutingData(item)
                    results.push({
                        path: [...path, item.label],
                        item: {
                            label: item.label,
                            route: firstChildRoute.name,
                            params: firstChildRoute.params,
                        },
                        weight: 3,
                    })
                }

                results.push(...getResults(item.children, [...path, item.label]))
            } else {
                let weight = null

                if (
                    item.label.toLocaleLowerCase().indexOf(searchValue) >= 0 ||
                    t(item.label).toLocaleLowerCase().indexOf(searchValue) >= 0
                ) {
                    weight = 4
                }

                if (item.settings?.keywords?.indexOf(searchValue) >= 0) {
                    weight = 4
                }

                if (item.url.indexOf(searchValue) >= 0) {
                    weight = 3
                }

                if (item.route.indexOf(searchValue) >= 0) {
                    weight = 2
                }

                if (weight !== null) {
                    results.push({
                        path: [...path, item.label],
                        item,
                        weight: weight,
                    })
                }
            }
        }

        return results
    }
    searchResults.value = getResults(routableMenuItems.value, []).sort((a, b) => compare(b.weight, a.weight))
}

function navigate(result) {
    router.push({ name: result.item.route, params: result.item.params })
    close()
}

function close() {
    emits("close")
}

watch(refThrottled(searchQuery, 300), updateSearch)
onMounted(updateSearch)
</script>

<template>
    <div class="nav-search-input">
        <div class="nav-search-input-icon"><i class="fa fa-fw fa-search"></i></div>
        <input
            type="search"
            class="nav-search-input-control"
            @keydown="keyDown($event)"
            v-model="searchQuery"
            v-focus
        />
        <div class="nav-search-input-icon c-pointer" @click="close()">
            <i class="fa fa-fw fa-times"></i>
        </div>
    </div>

    <div class="nav-search-results">
        <div
            class="nav-search-result"
            v-for="(result, $index) of searchResults"
            :class="{ active: $index === highlighted }"
            @click="navigate(result)"
        >
            <b>
                <VarsityContentProcessor :content="$t(result.item.label)"></VarsityContentProcessor>
            </b>
            <div class="text-muted nav-search-path">
                <span class="nav-search-path-item" v-for="item of result.path">
                    <VarsityContentProcessor :content="$t(item)"></VarsityContentProcessor>
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/styles/variables";
.nav-search-input {
    flex: 0 0 auto;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    align-items: center;

    .nav-search-input-control {
        flex: 1;
        border: none;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        background-color: transparent;
        padding: 1rem;
    }

    .nav-search-input-icon {
        padding: 1rem;
    }
}

.nav-search-results {
    flex: 1;
    overflow-y: hidden;

    .nav-search-result {
        border-bottom: 1px solid #ccc;
        padding: 0.5rem;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: lighten($primary, 30%);
        }

        &.active {
            background-color: $primary;
            color: #fff;
        }
    }
}

.nav-search-path-item {
    padding: 0.1rem 0;

    &:after {
        content: " / ";
    }

    &:last-child:after {
        content: none;
    }
}
</style>
