<script setup lang="ts">
import type { ChampionshipCategory } from "@/model/championship/championship-category"
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import type { BlankRegistration } from "./championship-registration"

const { t } = useI18n()

const props = defineProps<{
    category: ChampionshipCategory
    modelValue: BlankRegistration
    /** Disable submit button? */
    submitting?: boolean
}>()

const emit = defineEmits<{
    (e: "save"): void
    (e: "update:modelValue", value: BlankRegistration): void
}>()

/*─────────────────────────────────────┐
│  blank registration                  │
└─────────────────────────────────────*/
const blankRegistration = computed({
    get: () => props.modelValue,
    set: (value: BlankRegistration) => {
        emit("update:modelValue", value)
    },
})

const blankSubmitAllowed = computed(() => {
    if (!blankRegistration.value.numberOfParticipants) {
        // at least 1 starter is required
        return false
    }

    if (
        blankRegistration.value.numberOfSupervisors === null ||
        blankRegistration.value.numberOfAlternates === null ||
        blankRegistration.value.numberOfParticipants === null
    ) {
        return false
    }

    if (participantsError.value || alternatesError.value || supervisorsError.value) {
        return false
    }

    return true
})

async function submit() {
    emit("save")
}

// async function registerBlank() {
//     if (!blankSubmitAllowed.value) {
//         return
//     }
//
//     registrationSubmitting.value = true
//     await http.post(
//         `/championship/registration/${props.data.registrationId}/select-team/register-blank`,
//         blankRegistration,
//     )
//     registrationSubmitting.value = false
//     emit("updated")
// }

/*─────────────────────────────────────┐
│  validation                          │
└─────────────────────────────────────*/
const participantsError = computed(() => {
    if (!props.category || blankRegistration.value.numberOfParticipants === null) {
        return null
    }

    if (blankRegistration.value.numberOfParticipants < props.category.criteriaMinStarters) {
        return t("@tasks:tasks.championship_registration.wizard.not_enough_participants", {
            min: props.category.criteriaMinStarters,
        })
    }

    if (blankRegistration.value.numberOfParticipants > props.category.criteriaMaxStarters) {
        return t("@tasks:tasks.championship_registration.wizard.too_many_participants", {
            max: props.category.criteriaMaxStarters,
        })
    }

    return null
})
const alternatesError = computed(() => {
    if (!props.category) {
        return null
    }

    if (blankRegistration.value.numberOfAlternates > props.category.criteriaMaxReplacements) {
        return t("@tasks:tasks.championship_registration.wizard.too_many_alternates", {
            max: props.category.criteriaMaxReplacements,
        })
    }

    return null
})
const supervisorsError = computed(() => {
    if (!props.category) {
        return null
    }

    if (blankRegistration.value.numberOfSupervisors > props.category.criteriaMaxEscorts) {
        return t("@tasks:tasks.championship_registration.wizard.too_many_supervisors", {
            max: props.category.criteriaMaxEscorts,
        })
    }

    return null
})
</script>
<template>
    <label for="numberOfParticipants">
        {{ $t("@tasks:tasks.championship_registration.wizard.number_of_participants") }}
    </label>
    <input
        type="number"
        id="numberOfParticipants"
        class="form-control"
        v-model="blankRegistration.numberOfParticipants"
    />
    <div class="text-danger">
        {{ participantsError }}
    </div>

    <label for="numberOfAlternates" class="mt-3">
        {{ $t("@tasks:tasks.championship_registration.wizard.number_of_alternates") }}
    </label>
    <input type="number" id="numberOfAlternates" class="form-control" v-model="blankRegistration.numberOfAlternates" />
    <div class="text-danger">
        {{ alternatesError }}
    </div>

    <label for="numberOfCoaches" class="mt-3">
        {{ $t("@tasks:tasks.championship_registration.wizard.number_of_coaches") }}
    </label>
    <input type="number" id="numberOfCoaches" class="form-control" v-model="blankRegistration.numberOfCoaches" />

    <label for="numberOfSupervisors" class="mt-3">
        {{ $t("@tasks:tasks.championship_registration.wizard.number_of_supervisors") }}
    </label>
    <input
        type="number"
        id="numberOfSupervisors"
        class="form-control"
        v-model="blankRegistration.numberOfSupervisors"
    />
    <div class="text-danger">
        {{ supervisorsError }}
    </div>

    <div class="d-flex justify-content-end mt-4">
        <button class="btn btn-primary mb-5 mt-3" @click="submit()" :disabled="!blankSubmitAllowed || submitting">
            {{ $t("@tasks:tasks.championship_registration.wizard.register_blank_submit") }}
        </button>
    </div>
</template>
