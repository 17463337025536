import type { DebitorProfile } from "@/model/app/debitor-profile"
import type { OrganizationMember } from "@/model/app/organization-member"
import type { OrganizationTeam } from "@/model/app/organization-team"
import type { Category } from "@/model/championship/category"
import type { ChampionshipRegistrationTeam } from "@/model/championship/championship-registration-team"

/** The model as returned by `/championship/registration/{organizationId}/select-team/{taskId}` endpoint */
export type ChampionshipRegistrationSelectTeamData = {
    registrationId: string
    categories: Category[]
    teams: ChampionshipRegistrationTeam[]
    availableTeams: OrganizationTeam[]
    availableMembers: OrganizationMember[]
    availableManagers: OrganizationManager[]
    availableDebitorProfiles: DebitorProfile[]
    contactPerson?: ChampionshipRegistrationContactPerson
    debitorProfile?: DebitorProfile
}

/** The model as returned by `/championship/registration/{organizationId}/approve/{taskId}` endpoint */
export type ChampionshipRegistrationApproveData = {
    registrationId: string
    teams: ChampionshipRegistrationTeam[]
}

export type ChampionshipRegistrationTeamValidationResult = null | {
    ok: boolean
    team: string[]
    teamMembers: {
        organizationMemberId: string
        blockers: string[]
    }[]
    availableMembers: {
        organizationMemberId: string
        blockers: string[]
    }[]
}

export type BlankRegistration = {
    numberOfParticipants: number
    numberOfCoaches: number
    numberOfAlternates: number
    numberOfSupervisors: number
}

export type ChampionshipRegistrationTeamMember = {
    memberId: string
    type: "participant" | "coach" | "alternate" | "supervisor"
}
export type ChampionshipRegistrationContactPerson = {
    contactPersonName?: string
    contactPersonPhone?: string
    contactPersonMail?: string
}

export type OrganizationManager = {
    name: string
    phone: string
    mail: string
}

export function validateTeamName(teamName: string | undefined) {
    return teamName?.length >= 2
}
