<script setup lang="ts">
type StepProps = {
    issue: Issue
    task: ChampionshipRegistrationStepTask
    championship: Championship
    stepData: any
}
import { useLegacyHttpClient } from '@/composables/compat/useLegacyHttpClient'
import { OrganizationMember } from '@/model/app/organization-member'
import { ref, watch } from 'vue'
const props = defineProps<StepProps>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
async function activate(params) {
    params.value = params
    const data = await loadData()
    teams.value = data.teams
    members.value = data.members
    categories.value = data.categories
    if (props.stepData.team) {
        const team = (await http.get(`/championship/team/` + props.stepData.team.id)).content
        console.log("editing team", team)
        // we need the same reference
        selectCategoryById(team.championshipCategory.id)
        teamName.value = team.teamName
        for (const member of team.members) {
            if (!member.organizationMember) {
                continue
            }
            selectedMembers.value[member.type].push(member.organizationMember.id)
        }
    }
}
function selectCategoryById(categoryId) {
    for (const cat of categories.value) {
        if (cat.id === categoryId) {
            selectedCategory.value = cat
            return cat
        }
    }
    return null
}
async function selectedCategoryChanged() {
    const data = await loadData()
    members.value = data.members
    for (const columnId of Object.keys(selectedMembers.value)) {
        selectedMembers.value[columnId] = selectedMembers.value[columnId].filter(memberId => {
            const member = members.value.find(m => m.id === memberId)
            return member && member.blockers.length === 0
        })
    }
    await validate()
}
async function loadData() {
    return (await http.get(`/championship/registration/${props.issue.id}/team`, {})).content
}
function applyTeam() {
    if (!selectedTeam.value) {
        return
    }
    if (!teamName.value) {
        teamName.value = selectedTeam.value.name
    }
    selectedMembers.value.athlete = selectedTeam.value.members.filter(x => !memberHasBlockers(x)).map(x => x.id)
    selectedMembers.value.coach = selectedTeam.value.coaches.map(x => x.id)
    selectedMembers.value.adviser = selectedTeam.value.advisers.map(x => x.id)
}
async function validate() {
    const data = getData()
    try {
        if (props.stepData.team) {
            await http.put(
                `/championship/registration/${props.issue.id}/team/${props.stepData.team.id}?action=validate`,
                data,
            )
        } else {
            await http.post(`/championship/registration/${props.issue.id}/team?action=validate`, data)
        }
        teamValidation.value = null
    } catch (e) {
        console.log("team validation blockers:", e)
        teamValidation.value = e.content
    }
}
function getData() {
    return {
        teamName: teamName.value,
        category: selectedCategory.value ? selectedCategory.value.id : null,
        members: [
            ...selectedMembers.value.athlete.map(x => ({ id: x, type: "athlete" })),
            ...selectedMembers.value.coach.map(x => ({ id: x, type: "coach" })),
            ...selectedMembers.value.adviser.map(x => ({ id: x, type: "adviser" })),
        ],
    }
}
async function submit() {
    const data = getData()
    try {
        let response
        if (props.stepData.team) {
            response = await http.put(
                `/championship/registration/${props.issue.id}/team/${props.stepData.team.id}`,
                data,
            )
        } else {
            response = await http.post(`/championship/registration/${props.issue.id}/team?action=signup`, data)
        }
        if (response.statusCode === 204) {
            emit("updated")
        }
    } catch (e) {
        if (e.content && e.content.error) {
            errorMessage.value = e.content.error
        }
    }
}
function memberHasBlockers(member) {
    return members.value.find(x => x.id === member.id).blockers.length > 0
}
function selectShowErrorsForMember(member, column) {
    showErrorsForMember.value = member.blockers.length > 0 ? column + member.id : null
    return true
}
function selectionChanged(column: string, member: OrganizationMember) {
    const isSelected = selectedMembers.value[column].includes(member.id)
    if (isSelected) {
        for (const otherColumn of columns) {
            if (otherColumn[0] !== column) {
                selectedMembers.value[otherColumn[0]] = selectedMembers.value[otherColumn[0]].filter(
                    x => x !== member.id,
                )
            }
        }
    }
    validate()
}
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const http = useLegacyHttpClient()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const teams = ref()
const members = ref<any[]>()
const categories = ref()
const teamName = ref()
const selectedTeam = ref()
const selectedMembers = ref({ athlete: [], coach: [], adviser: [] })
const selectedCategory = ref(null)
const showErrorsForMember = ref(null)
const teamValidation = ref(null)
const errorMessage = ref("")
const columns = [
    ["athlete", "Aktive Starter"],
    ["coach", "Trainer"],
    ["adviser", "Begleitpersonen"],
]
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
watch(selectedCategory, selectedCategoryChanged)
await activate(props)
</script>

<template>
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <div class="row">
        <div class="col-md-3">
            <div class="my-3">
                <b>{{ $t("@championship:registration.window.edit_team.teamName") }}</b>
                <!--            <div class="d-flex justify-content-between align-items-center">-->
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('@championship:registration.window.edit_team.teamName')"
                    v-model="teamName"
                    @blur="validate()"
                />
                <!--            </div>-->
            </div>

            <div class="my-3">
                <b>{{ $t("@championship:registration.window.edit_team.category") }}</b>
                <select class="form-control" v-model="selectedCategory">
                    <option disabled selected value>
                        {{ $t("@championship:registration.window.edit_team.category") }}
                    </option>
                    <option v-for="cat in categories" :value="cat">{{ cat.name }}</option>
                </select>
            </div>

            <div class="text-danger" v-if="teamValidation">
                {{ teamValidation.error }}

                <ul v-if="teamValidation.blockers && teamValidation.blockers.team">
                    <li v-for="m in teamValidation.blockers.team">
                        {{ $t("@championship:registration.blocker." + m) }}
                    </li>
                </ul>
            </div>

            <button class="btn btn-primary mt-3" :disabled="teamValidation" @click="submit()">Jetzt anmelden</button>
        </div>
        <div class="col-md-9">
            <div class="px-3 py-2 bg-dark d-flex align-items-center text-white">
                <div>Mitglieder</div>
                <div class="form-inline mx-2">
                    <select class="form-control form-inline" v-model="selectedTeam">
                        <option disabled selected>Team</option>
                        <option :value="team" v-for="team in teams">{{ team.name }}</option>
                    </select>
                </div>
                <button class="btn btn-primary" @click="applyTeam()" :disabled="!selectedTeam">
                    {{ $t("@championship:registration.window.edit_team.applyFilter") }}
                </button>
            </div>

            <div class="row">
                <div class="col-md-4" v-for="column in columns">
                    <b>{{ column[1] }}</b>
                    <div class="checkbox" v-for="member in members">
                        <label
                            class="form-check-label"
                            :class="{ 'text-muted': member.blockers.length !== 0 }"
                            @click="selectShowErrorsForMember(member, column[0])"
                        >
                            <input
                                type="checkbox"
                                v-model="selectedMembers[column[0]]"
                                :value="member.id"
                                :disabled="member.blockers.length !== 0"
                                @change="selectionChanged(column[0], member)"
                            />
                            <div class="form-toggler"></div>

                            <span>{{ member.firstName }} {{ member.lastName }}</span>
                        </label>

                        <div class="text-danger" v-if="showErrorsForMember === column[0] + member.id">
                            <div v-for="blocker in member.blockers">
                                {{ $t("@championship:registration.blocker." + blocker) }}
                            </div>
                        </div>
                        <template v-if="column[0] == 'athlete'">
                            <div
                                class="text-danger"
                                v-for="blocker in teamValidation?.blockers?.members?.find(
                                    i => i.member.organizationMember.id == member.id,
                                )?.blockers || []"
                            >
                                {{ $t("@championship:registration.blocker." + blocker) }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
