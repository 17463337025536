<script setup lang="ts">
import { type AccountContact } from "@/model/app/account-contact"
import { toRefs } from "vue"

const props = defineProps<{ address: AccountContact }>()
const { address } = toRefs(props)
</script>

<template>
    <div>
        <div>{{ address.addressLine }}</div>
        <div>{{ address.street }} {{ address.streetNumber }}</div>
        <div>{{ address.zip }} {{ address.city }}</div>
        <div>{{ address.country }}</div>
    </div>
</template>
