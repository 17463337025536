import { type Championship } from "@/model/championship/championship"
import type { ChampionshipRegistrationStepTask } from "@/model/championship/championship-registration-step-task"
import type { Issue } from "@/model/tasks/issue"
import { useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { ref } from "vue"

export type StepProps = {
    issue: Issue
    task: ChampionshipRegistrationStepTask
    championship: Championship
    stepData: any
}

export const useChampionshipRegistration = defineStore("championshipRegistration", () => {
    const http = useHttpClient()
    let loadedId: string | null = null
    const item = ref<Championship | null>(null)
    const stepData = ref({})

    async function useStep(issue: Issue, id: string) {
        if (loadedId !== id) {
            loadedId = id
            item.value = (await http.get<Championship>("championship/registration/" + id)).data
            stepData.value = {
                organization: issue.owner,
            }
        }

        return {
            item,
            stepData,
        }
    }

    return {
        useStep,
    }
})
