<script setup lang="ts">
import { useHashTab } from "./useHashTab"

defineProps<{ tab: string }>()
const { activeTab, selectTab } = useHashTab()!
</script>

<template>
    <div class="nav-item">
        <a href="#" class="nav-link" :class="{ active: activeTab === tab }" @click.prevent="selectTab(tab)">
            <slot />
        </a>
    </div>
</template>
