<script lang="ts" setup>
import VarsityInlineConfirm from "@/components/VarsityInlineConfirm.vue"
import VarsityInlineConfirmButton from "@/components/VarsityInlineConfirmButton.vue"
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { createInlineConfirm } from "@/composables/createInlineConfirm"
import { useModuleAction, type Entity, type ModuleConfiguration } from "@/vf"
import { inject } from "vue"

const props = defineProps<{
    confirmTeleportTarget?: HTMLElement
}>()

const item = inject<Entity>("item")
const moduleAction = useModuleAction()

const config: ModuleConfiguration = inject("module-config")
const mode = config.show.page(item).mode

const inlineConfirm = createInlineConfirm({
    action() {
        return useLegacyHttpClient().delete(config.entityPath(item))
    },
    afterAction() {
        moduleAction.executeAfterAction(config.afterDeleteAction, item, false, true)
    },
})
</script>

<template>
    <VarsityInlineConfirmButton class="btn btn-danger btn-shape-skewed" :controller="inlineConfirm" v-if="item?.id">
        <i class="fa fa-fw fa-trash" v-if="mode === 'router'"></i>
        {{ $t("crud:button.delete") }}
    </VarsityInlineConfirmButton>

    <Teleport :to="confirmTeleportTarget" :disabled="!confirmTeleportTarget">
        <VarsityInlineConfirm :controller="inlineConfirm">
            <template #confirmation>
                {{
                    $t("crud:delete.confirmation_detail", {
                        type: $t(config.readableName.singular),
                        name: config.readableName.entity(item),
                    })
                }}
            </template>
            <template #success>
                {{
                    $t("crud:delete.success.message", {
                        type: $t(config.readableName.singular),
                        name: config.readableName.entity(item),
                        interpolation: { escapeValue: false },
                    })
                }}
            </template>
        </VarsityInlineConfirm>
    </Teleport>
</template>
