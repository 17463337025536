<script setup lang="ts">
import { VbList } from "@/components/crud"
import { ref } from "vue"
import { createModuleConfiguration } from "./config"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
const props = withDefaults(
    defineProps<{
        organization: any
        subjectType: any
        subjectId: any
    }>(),
    {
        organization: null,
        subjectType: null,
        subjectId: null,
    },
)
/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
const config = createModuleConfiguration(props.organization, props.subjectType, props.subjectId)
const list = ref<typeof VbList>()

defineExpose({
    refresh: () => list.value.refresh(),
})
</script>

<template>
    <VbList v-bind="{ config }" ref="list" :toolbar="false"></VbList>
    <!-- <div class="table-responsive">
        <table class="table table-striped">
            <tr>
                <th
                    v-for="(field, $index) in fields"
                    :class="
                        (field.sortable ? 'sortable' : '') +
                        ' ' +
                        (orderField == field.name ? 'sort-' + orderDirection : '') +
                        ' table-col-' +
                        $index +
                        ' ' +
                        (field.thClass ? field.thClass() : '')
                    "
                    @click="orderBy(field.name)"
                >
                    <span v-t="field.title"></span>
                </th>
                <th class="text-right list-actions" v-t="'crud:list.actions'"></th>
            </tr>
            <tr v-for="(item, $index) in list">
                <td
                    v-for="(field, $index) in fields"
                    :style="itemClickable(item, field) ? 'cursor: pointer;' : ''"
                    @click="itemClicked(item, field)"
                    :class="'table-col-' + $index + ' ' + (field.tdClass ? field.tdClass(item) : '')"
                >
                    <span v-if="field.type == 'string'">{{ readField(item, field) }}</span>
                    <span v-if="field.type == 'integer'">{{ readField(item, field) }}</span>
                    <span v-if="field.type == 'float'">{{ readField(item, field) }}</span>
                    <span v-if="field.type == 'currency'">{{ $currency(readField(item, field)) }}</span>
                    <span v-if="field.type == 'datetime'">{{ $datetime(readField(item, field)) }}</span>
                    <span v-if="field.type == 'datetimeshort'">{{ $shortdatetime(readField(item, field)) }}</span>
                    <span v-if="field.type == 'date'">{{ $date(readField(item, field)) }}</span>
                    <span v-if="field.type == 'bool' || field.type == 'boolean'">
                        {{ $bool(readField(item, field)) }}
                    </span>
                    <span v-if="field.type == 'html'" v-html="readField(item, field)"></span>
                </td>
                <td class="text-right list-actions"></td>
            </tr>
        </table>
    </div>
    <div class="p-3">
        <div class="justify-content-between pager">
            <div class="text-muted pager-entries-per-page">
                <select class="custom-select w-auto" v-model="itemsPerPage">
                    <option :value="5">5</option>
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                </select>
                <span v-t="'crud:list.entries_per_page'"></span>
            </div>
            <div class>
                <ul class="pagination justify-content-center">
                    <li :class="'page-item ' + (page == 1 ? 'disabled' : '')">
                        <a class="page-link" href="#" :title="$t('crud:list.first_page')" @click="goPage(1)">
                            <i class="fa fa-fw fa-fast-backward"></i>
                        </a>
                    </li>
                    <li :class="'page-item ' + (page == 1 ? 'disabled' : '')">
                        <a class="page-link" href="#" :title="$t('crud:list.prev_page')" @click="goPage(page - 1)">
                            <i class="fa fa-fw fa-backward"></i>
                        </a>
                    </li>
                    <li v-for="i in pagination" :class="'page-item ' + (i == page ? 'active' : '')">
                        <a class="page-link" href="#" @click="goPage(i)">
                            {{ i }}
                        </a>
                    </li>
                    <li :class="'page-item ' + (page == pageCount ? 'disabled' : '')">
                        <a class="page-link" href="#" :title="$t('crud:list.next_page')" @click="goPage(page + 1)">
                            <i class="fa fa-fw fa-forward"></i>
                        </a>
                    </li>
                    <li :class="'page-item ' + (page == pageCount ? 'disabled' : '')">
                        <a class="page-link" href="#" :title="$t('crud:list.last_page')" @click="goPage(pageCount)">
                            <i class="fa fa-fw fa-fast-forward"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="text-md-right text-muted">
                <span
                    :html="$t('crud:list.page_info', { totalItems: totalItems, page: page, pageCount: pageCount })"
                ></span>
            </div>
        </div>
    </div> -->
</template>
