import { ref } from "vue"

export function useLocale() {
    const locale = ref(localStorage.getItem("locale") ?? "de")

    const changeLocale = (newLocale: string) => {
        locale.value = newLocale
        try {
            localStorage.setItem("locale", newLocale)
        } catch (e) {
            console.error(e)
        }
    }
    return { locale, changeLocale }
}
