<script setup lang="ts">
import { useMouseInElement } from "@vueuse/core"
import { computed, nextTick, reactive, ref, watch, type Ref } from "vue"

const props = defineProps<{
    always?: boolean
}>()

const active = ref(false)
const container: Ref<HTMLElement> = ref(null)
const bubble: Ref<HTMLElement> = ref(null)

const bubblePos = reactive({ left: "0px", top: "0px" })

async function mouseEnter() {
    active.value = true

    const rect = container.value.getBoundingClientRect()
    await nextTick()

    const bubbleWidth = bubble.value.getBoundingClientRect().width
    const windowWidth = window.innerWidth

    if (rect.left + bubbleWidth > windowWidth) {
        bubblePos.left = windowWidth - bubbleWidth + "px"
    } else {
        bubblePos.left = rect.left + "px"
    }

    bubblePos.top = rect.bottom + "px"
}

// function mouseLeave() {
//     active.value = false
// }

const { isOutside: isOutsideOfContainer } = useMouseInElement(container)
const { isOutside: isOutsideOfBubble } = useMouseInElement(bubble)

const isOutsideOfRelevantElements = computed(() => isOutsideOfContainer.value && isOutsideOfBubble.value)

watch(isOutsideOfRelevantElements, () => {
    setTimeout(() => {
        if (isOutsideOfRelevantElements.value) {
            active.value = false
        }
    }, 200)
})
</script>

<template>
    <span
        class="content-element-help"
        :class="{ active, always: props.always }"
        ref="container"
        @mouseenter="mouseEnter"
    >
        <i class="fa fa-question-circle"></i>
        <Teleport to="#help-bubble-target">
            <span class="help-bubble" :class="{ active }" ref="bubble" :style="bubblePos"><slot></slot></span>
        </Teleport>
    </span>
</template>
