<script lang="ts" setup>
import { provide, toRef } from "vue"

const props = defineProps<{
    name: string
    value: any
}>()

provide(props.name, toRef(props, "value"))
</script>

<template>
    <slot />
</template>
