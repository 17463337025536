interface NestedRecordStringString extends Record<string, NestedRecordStringString | string> {}

/**
 *  transform keys like signatures[id][city] to an object with a structure of {signatures: {id: city: "city"}},
 * allowing arbitrary nesting of keys.
 */
export function arrayNotationToObject(input: Record<string, string>): NestedRecordStringString {
    const result: NestedRecordStringString = {}
    for (const key in input) {
        const keys = key.split("[").map(k => k.replace("]", ""))
        let current = result
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i]
            if (i === keys.length - 1) {
                current[k] = input[key]
            } else {
                if (!current[k]) {
                    current[k] = {}
                }
                current = current[k] as NestedRecordStringString
            }
        }
    }

    return result
}

export function formDataToObject(formData: FormData): NestedRecordStringString {
    const dataObject: Record<string, string> = {}
    formData.forEach((value, key) => {
        dataObject[key] = value as string
    })

    return arrayNotationToObject(dataObject)
}
