import CookieConsentOverlay from "@/components/CookieConsentOverlay.vue"
import { useDialogs } from "@/vf"
import { useStorage } from "@vueuse/core"
import { defineStore } from "pinia"

export const useCookieConsent = defineStore("cookieConsent", () => {
    const allTypes = [
        {
            name: "functional",
            children: ["functional"],
        },
        {
            name: "externmedia",
            children: ["youtube", "googlemaps"],
        },
    ]
    const whitelist = useStorage("cookie-consent-whitelist", [])
    const shown = useStorage("cookie-consent-shown", false)

    function acceptAll() {
        shown.value = true
        whitelist.value = [...allTypes.map(t => t.children).flat()]
    }

    function acceptSingle(type: string) {
        shown.value = true
        if (!whitelist.value.includes(type)) {
            whitelist.value.push(type)
        }
    }

    function declineAll() {
        shown.value = true
        whitelist.value = []
    }

    function isAccepted(type: string) {
        return whitelist.value.includes(type)
    }

    function openOverlay() {
        useDialogs().open({
            component: CookieConsentOverlay,
            locked: true,
            props: {},
        })
    }

    return {
        openOverlay,
        acceptAll,
        declineAll,
        acceptSingle,
        isAccepted,
        whitelist,
        allTypes,
        shown,
    }
})
