<script setup lang="ts">
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import { useApp, useHttpClient } from "@/vf"
import { computedAsync } from "@vueuse/core"
import { computed, toRefs } from "vue"

const { apiLink } = useApp()
const http = useHttpClient()
const props = defineProps<{
    selectedOrganization: { id: string; name: string; label: string }
    formDocuments: { name: string; id: string }[]
}>()
const { selectedOrganization, formDocuments } = toRefs(props)

interface ApiResponse {
    id: string
    firstName: string
    lastName: string
    birthDate: string
    gender: string
    street: string
    streetNumber: string
    zip: string
    city: string
    country: string
}

const athletes = computedAsync<ApiResponse[]>(async () => {
    return (await http.get<ApiResponse[]>("legal/form/athletes/" + selectedOrganization.value.id)).data
}, [])

const conditionIds = computed(() => {
    return formDocuments.value.join(",")
})
</script>

<template>
    <form method="get" :action="apiLink('legal/form/pdf/athletes/' + selectedOrganization.id)" target="_blank">
        <input type="hidden" name="c" :value="conditionIds" />
        <table class="table">
            <tr>
                <th></th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.firstName") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.lastName") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.birthDate") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.gender") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.street") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.streetNumber") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.zip") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.city") }}</th>
                <th>{{ $t("@cms:onlineForm.bunchAdd.country") }}</th>
                <th></th>
            </tr>
            <tr v-for="athlete of athletes">
                <td>
                    <CheckboxToggle name="a[]" :value="athlete.id" />
                </td>
                <td>
                    <input type="text" v-model="athlete.firstName" class="form-control" disabled />
                </td>
                <td>
                    <input type="text" v-model="athlete.lastName" class="form-control" disabled />
                </td>
                <td>
                    <input type="date" v-model="athlete.birthDate" class="form-control" disabled />
                </td>
                <td>
                    <select class="form-control" v-model="athlete.gender" disabled>
                        <option value="m">{{ $t("@cms:onlineForm.bunchAdd.gender.male") }}</option>
                        <option value="f">{{ $t("@cms:onlineForm.bunchAdd.gender.female") }}</option>
                    </select>
                </td>
                <td>
                    <input type="text" v-model="athlete.street" class="form-control" disabled />
                </td>
                <td>
                    <input type="text" v-model="athlete.streetNumber" class="form-control" disabled />
                </td>
                <td>
                    <input type="text" v-model="athlete.zip" class="form-control" disabled />
                </td>
                <td>
                    <input type="text" v-model="athlete.city" class="form-control" disabled />
                </td>
                <td>
                    <input type="text" v-model="athlete.country" class="form-control" disabled />
                </td>
                <td>
                    <a :href="apiLink('legal/form/pdf/' + conditionIds + '/member/' + athlete.id)" target="_blank">
                        {{ $t("@cms:onlineForm.downloadSingleAthlete") }}
                    </a>
                </td>
            </tr>
        </table>
        <button class="btn btn-primary" type="submit">
            {{ $t("@cms:onlineForm.downloadSelectedAthletes") }}
        </button>
    </form>
</template>
<style scoped>
.table td {
    vertical-align: middle;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
}
</style>