<script setup lang="ts">
import type { OrganizationTeam } from "@/model/app/organization-team"
import type { ChampionshipCategory } from "@/model/championship/championship-category"
import { useHttpClient } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import { computed, reactive, ref } from "vue"
import { useI18n } from "vue-i18n"
import ChampionshipRegistrationBlankRegistration from "./ChampionshipRegistrationBlankRegistration.vue"
import ChampionshipRegistrationContactPersonForm from "./ChampionshipRegistrationContactPersonForm.vue"
import ChampionshipRegistrationEditTeam from "./ChampionshipRegistrationEditTeam.vue"
import {
    validateTeamName,
    type BlankRegistration,
    type ChampionshipRegistrationContactPerson,
    type ChampionshipRegistrationSelectTeamData,
    type ChampionshipRegistrationTeamMember,
} from "./championship-registration"

const props = defineProps<{
    data: ChampionshipRegistrationSelectTeamData
}>()

const emit = defineEmits<{
    (e: "saved"): void
}>()

const { t } = useI18n()
const http = useHttpClient()

type StepType = "contactPerson" | "category" | "method" | "name" | "blank" | "selectMembers"
type RegistrationMethod = "blank" | "named"

const stepNames: Record<StepType, string> = reactive({
    contactPerson: t("@tasks:tasks.championship_registration.wizard.step_button.contactPerson"),
    category: t("@tasks:tasks.championship_registration.wizard.step_button.category"),
    method: t("@tasks:tasks.championship_registration.wizard.step_button.method"),
    name: t("@tasks:tasks.championship_registration.wizard.step_button.name"),
    blank: t("@tasks:tasks.championship_registration.wizard.step_button.blank"),
    selectMembers: t("@tasks:tasks.championship_registration.wizard.step_button.selectMembers"),
})

const methodNames: Record<RegistrationMethod, string> = reactive({
    blank: t("@tasks:tasks.championship_registration.wizard.registration_method.blank"),
    named: t("@tasks:tasks.championship_registration.wizard.registration_method.named"),
    teamFilter: t("@tasks:tasks.championship_registration.wizard.registration_method.teamFilter"),
})

/*
step order:
contactPerson -> category -> method

depending on selected method:
  blank: name -> blank
  named: name -> selectMembers
  teamFilter: selectTeamFilter -> selectMembers
 */

/*─────────────────────────────────────┐
│  wizard steps                        │
└─────────────────────────────────────*/
const currentStep = ref<StepType>("contactPerson")
function nextStep() {
    switch (currentStep.value) {
        case "contactPerson":
            currentStep.value = "category"
            previousSteps.push({ type: "contactPerson" })
            break
        case "category":
            currentStep.value = "method"
            previousSteps.push({ type: "category" })
            break
        case "method":
            currentStep.value = (
                {
                    blank: "name",
                    named: "name",
                    teamFilter: "selectTeamFilter",
                } as Record<RegistrationMethod, StepType>
            )[registrationMethod.value]
            previousSteps.push({ type: "method" })
            break
        case "name":
            currentStep.value = (
                {
                    blank: "blank",
                    named: "selectMembers",
                } as Record<RegistrationMethod, StepType>
            )[registrationMethod.value]
            previousSteps.push({ type: "name" })
            break
    }
}

function backToStep(step: StepType) {
    currentStep.value = step

    // remove all "previous steps" from the list that are after the selected step
    let index = previousSteps.findIndex(x => x.type === step)
    if (index >= 0) {
        previousSteps.splice(index)
    }
}

const previousSteps = reactive<{ type: StepType }[]>([])

/*─────────────────────────────────────┐
│  contact person                      │
└─────────────────────────────────────*/
const contactPerson = ref<ChampionshipRegistrationContactPerson>({
    contactPersonName: "",
    contactPersonPhone: "",
    contactPersonMail: "",
})
const contactPersonValid = ref(false)

/*─────────────────────────────────────┐
│  registration method                 │
└─────────────────────────────────────*/
const registrationMethod = ref<RegistrationMethod>()

function selectMethod(method: RegistrationMethod) {
    registrationMethod.value = method
    nextStep()
}

/*─────────────────────────────────────┐
│  category selection                  │
└─────────────────────────────────────*/
const selectedCategory = ref<ChampionshipCategory>()
const categoryValid = computed(() => !!selectedCategory.value)

/*─────────────────────────────────────┐
│  name                                │
└─────────────────────────────────────*/
const teamName = ref<string>()
const teamNameValid = computed(() => validateTeamName(teamName.value))

/*─────────────────────────────────────┐
│  select members                      │
└─────────────────────────────────────*/
const members = ref<ChampionshipRegistrationTeamMember[]>([])

/*─────────────────────────────────────┐
│  blank                               │
└─────────────────────────────────────*/
const blankRegistration = reactive<BlankRegistration>({
    numberOfParticipants: null,
    numberOfAlternates: null,
    numberOfCoaches: null,
    numberOfSupervisors: null,
})

/*─────────────────────────────────────┐
│  submit                              │
└─────────────────────────────────────*/
// when set to true, the submit buttons are disabled
const submitting = ref(false)

async function save() {
    if (registrationMethod.value === "blank") {
        await registerBlank()
    } else {
        await registerNamed()
    }

    emit("saved")
}

async function registerBlank() {
    submitting.value = true
    try {
        await http.post(`/championship/registration/${props.data.registrationId}/select-team/register-blank`, {
            teamName: teamName.value,
            categoryId: selectedCategory.value.id,
            ...blankRegistration,
            ...contactPerson.value,
        })
    } finally {
        submitting.value = false
    }
}

async function registerNamed() {
    submitting.value = true
    try {
        await http.post(`/championship/registration/${props.data.registrationId}/select-team/register-named`, {
            teamName: teamName.value,
            categoryId: selectedCategory.value.id,
            members: members.value,
            ...contactPerson.value,
        })
    } finally {
        submitting.value = false
    }
}
</script>
<template>
    <div class="pl-3 mb-5 mt-4">
        <button
            v-for="step of previousSteps"
            :key="step.type"
            class="d-flex flex-row align-items-center py-3 px-4 bg-primary text-white mb-3 wizard-button font-weight-normal"
            @click="backToStep(step.type)"
        >
            <div>
                <i class="fa fa-fw fa-chevron-left"></i>
            </div>
            <div class="flex-grow-1 pl-3">
                <b>{{ stepNames[step.type] }}</b>

                <div v-if="step.type === 'contactPerson'">
                    {{ contactPerson.contactPersonName }}, {{ contactPerson.contactPersonPhone }},
                    {{ contactPerson.contactPersonMail }}
                </div>

                <div v-if="step.type === 'category'">
                    {{ selectedCategory?.name }}
                </div>

                <div v-if="step.type === 'method'">
                    {{ methodNames[registrationMethod] }}
                </div>

                <div v-if="step.type === 'name'">
                    {{ teamName }}
                </div>
            </div>
        </button>

        <AnimateIf :if="currentStep === 'contactPerson'">
            <form @submit.prevent="nextStep()" class="px-2">
                <ChampionshipRegistrationContactPersonForm
                    :data="data"
                    v-model="contactPerson"
                    @validate="contactPersonValid = $event"
                />

                <button class="btn btn-primary mt-4" type="submit" :disabled="!contactPersonValid">
                    {{ $t("@tasks:tasks.championship_registration.wizard.continue") }}
                </button>
            </form>
        </AnimateIf>
        <AnimateIf :if="currentStep === 'category'">
            <form @submit.prevent="nextStep()" class="px-2">
                <div>
                    <b>
                        {{ $t("@tasks:tasks.championship_registration.wizard.please_provide_category") }}
                    </b>
                </div>

                <label for="categoryId" class="mt-3">
                    {{ $t("@tasks:tasks.championship_registration.wizard.category") }}
                </label>
                <select id="categoryId" class="form-control" v-model="selectedCategory">
                    <option v-for="category of data.categories" :value="category">
                        {{ category.name }}
                    </option>
                </select>

                <button class="btn btn-primary mt-4" type="submit" :disabled="!categoryValid">
                    {{ $t("@tasks:tasks.championship_registration.wizard.continue") }}
                </button>
            </form>
        </AnimateIf>
        <AnimateIf :if="currentStep === 'method'">
            <div class="pl-5">
                <div class="mb-3">
                    <b>
                        {{ $t("@tasks:tasks.championship_registration.wizard.please_select_method") }}
                    </b>
                </div>
                <button class="wizard-button" @click="selectMethod('blank')">
                    {{ $t("@tasks:tasks.championship_registration.wizard.register_blank") }}
                </button>
                <button class="wizard-button" @click="selectMethod('named')">
                    {{ $t("@tasks:tasks.championship_registration.wizard.register_named") }}
                </button>
            </div>
        </AnimateIf>
        <AnimateIf :if="currentStep === 'name'">
            <form @submit.prevent="nextStep()" class="px-2 pl-4">
                <div class="mb-3">
                    <b>
                        {{ $t("@tasks:tasks.championship_registration.wizard.please_enter_name") }}
                    </b>
                </div>
                <div class="my-3">
                    <b>{{ $t("@tasks:tasks.championship_registration.wizard.team_name") }}</b>
                    <input type="text" class="form-control" v-model="teamName" />
                </div>

                <button class="btn btn-primary mt-4" type="submit" :disabled="!teamNameValid">
                    {{ $t("@tasks:tasks.championship_registration.wizard.continue") }}
                </button>
            </form>
        </AnimateIf>
        <AnimateIf :if="currentStep === 'blank'">
            <div class="pl-4">
                <ChampionshipRegistrationBlankRegistration
                    :category="selectedCategory"
                    v-model="blankRegistration"
                    :submitting="submitting"
                    @save="save()"
                />
            </div>
        </AnimateIf>
        <AnimateIf :if="currentStep === 'selectMembers'">
            <ChampionshipRegistrationEditTeam
                v-bind="{ data, categoryId: selectedCategory.id }"
                v-model="members"
                :submitting="submitting"
                @save="save()"
            />
        </AnimateIf>
    </div>
</template>
