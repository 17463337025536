<script setup lang="ts">
type StepProps = {
    issue: Issue
    task: ChampionshipRegistrationStepTask
    championship: Championship
    stepData: any
}
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import { useLegacyHttpClient } from '@/composables/compat/useLegacyHttpClient'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import OrderConfirmationList from '@/pages/Order/OrderConfirmation/OrderConfirmationList.vue'
import { useDialogs } from '@/vf'
import { ref } from 'vue'
const props = defineProps<StepProps>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const http = useLegacyHttpClient()
const dialogs = useDialogs()
const orderState = ref()
async function refresh() {
    const response = await http.get(`/championship/registration/${props.issue.id}/registrations`)
    orderState.value = response.content.orderState
}
const inlineConfirm = createInlineConfirm({
    async action() {
        showError.value = false
        try {
            await http.post(`/championship/registration/${props.issue.id}/lock-registration`, {})
        } catch {
            showError.value = true
        }
    },
    afterAction() {
        if (!showError.value) {
            emit("updated")
        }
    },
})
await refresh()
const showError = ref(false)
</script>

<template>
    <div v-if="showError" class="alert alert-danger">
        {{ $t("@championship:registration.undo_final_registration.error") }}
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <div>
            {{ $t("@tasks:tasks.championship_registration.accept_terms") }}
        </div>
        <div>
            <VarsityInlineConfirmButton
                class="btn btn-primary"
                :controller="inlineConfirm"
                v-if="orderState === 'cancelled' || orderState === 'new' || orderState === 'open'"
            >
                {{ $t("@championship:registration.register_organization") }}
            </VarsityInlineConfirmButton>
        </div>
    </div>

    <VarsityInlineConfirm :controller="inlineConfirm">
        <template #confirmation>
            {{ $t("@championship:registration.final_registration.confirm") }}
        </template>
        <template #success>
            {{ $t("@championship:registration.final_registration.success") }}
        </template>
    </VarsityInlineConfirm>
    <div class="mt-5">
        <OrderConfirmationList
            v-bind="{
                organization: props.stepData.organization.id,
                subjectType: 'championship',
                subjectId: props.championship.id,
            }"
        ></OrderConfirmationList>
    </div>
</template>
