<script lang="ts" setup>
import VarsityContentProcessor from "@/components/VarsityContentProcessor.vue"
import { useToggleableValue } from "@/vf"
import { type FaqPage } from "./FaqTypes"

const props = defineProps<{
    page: FaqPage
}>()
const [activeItem, setActiveItem] = useToggleableValue()
</script>

<template>
    <div class="accordion-enumeration-card" v-for="item in page.items">
        <div class="accordion-enumeration-card-top" @click="setActiveItem(item)">
            <h3>{{ item.question }}</h3>
            <div class="accordion-enumeration-card-toggle" :class="{ active: activeItem === item }">
                <i class="fa fa-fw fa-plus"></i>
            </div>
        </div>

        <div v-animate-show="activeItem === item">
            <div class="accordion-enumeration-card-content">
                <VarsityContentProcessor :content="item.answer"></VarsityContentProcessor>
            </div>
        </div>
    </div>
</template>
