import { determineApiAddress, useHttpClient } from "../useHttpClient"

export function useApp() {
    const http = useHttpClient()

    function apiLink(url: string, params?: { [key: string]: string | number }): string {
        let link = "//" + determineApiAddress() + "/api" + (url.startsWith("/") ? "" : "/") + url
        if (params) {
            link +=
                (link.indexOf("?") >= 0 ? "&" : "?") +
                Object.keys(params)
                    .map(value => `${value}=${encodeURIComponent(params[value])}`)
                    .join("&")
        }
        // link += (link.indexOf("?") >= 0 ? "&" : "?") + this.securityService.urlParam

        return link
    }

    function cssApiLink(url: string): string {
        return `url(${apiLink(url)})`
    }

    async function logout() {
        try {
            await http.post("logout", undefined, { headers: { "X-Account": "use-any" } })
        } catch (e) {
            console.error("Logout error: ", e)
        }
        //await this.liveAccountSwitch(null)
        location.href = "/"
    }

    return ((window as any).app = {
        apiLink,
        cssApiLink,
        logout,
    })
}
