<script setup lang="ts">
import FrontendTree from "@/pages/Cms/Frontend/FrontendTree.vue"
import { useHttpClient } from "@/vf"
import { ref } from "vue"
import { useRoute } from "vue-router"

const props = defineProps<{
    path?: string
    id?: string
}>()
const route = useRoute()

type FrontendCmsPage = {
    slug: string
    tree: any
    externalUrl?: string
}

const { get } = useHttpClient()
const page = ref<FrontendCmsPage>(null)
let path: string
if (props.id) {
    path = "page-id/" + props.id
} else {
    path = "page" + (props.path ?? route.path)
}

const loadingError = ref(false)
try {
    page.value = (await get<FrontendCmsPage>(`/cms-frontend/${path}`)).data
} catch {
    page.value = null
    loadingError.value = true
}

if (page.value.externalUrl) {
    window.location.href = page.value.externalUrl
}
</script>

<template>
    <div>
        <FrontendTree v-if="page" v-bind="{ tree: page.tree, page }"></FrontendTree>
        <slot v-else name="fallback"></slot>
    </div>
</template>
