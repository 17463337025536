<script setup lang="ts">
import VarsityInlineConfirm from "@/components/VarsityInlineConfirm.vue"
import { createInlineConfirmCollection } from "@/composables/createInlineConfirm"
import type { ChampionshipRegistrationTeam } from "@/model/championship/championship-registration-team"
import { $currency, $formatDate, createInlineTable, useHttpClient } from "@/vf"
import VfListCompose from "@/vf/components/crud/list/VfListCompose.vue"
import { computed } from "vue"
import ChampionshipRegistrationApprovalStateIcon from "./ChampionshipRegistrationApprovalStateIcon.vue"
import ChampionshipRegistrationEditTeamWizard from "./ChampionshipRegistrationEditTeamWizard.vue"
import type { ChampionshipRegistrationSelectTeamData } from "./championship-registration"

const props = withDefaults(
    defineProps<{
        data?: ChampionshipRegistrationSelectTeamData
        teams: ChampionshipRegistrationTeam[]
        showPrice?: boolean
        showEdit?: boolean
        showDelete?: boolean
        viewMode: "customer" | "admin"
    }>(),
    {
        showPrice: true,
        showEdit: false,
        showDelete: false,
    },
)
const emit = defineEmits<{
    (e: "updated"): void
}>()

const inlineTable = createInlineTable({
    refresh: () => emit("updated"),
})

const colspan = computed(() => {
    let span = 5

    if (props.showPrice) {
        span++
    }

    if (props.showDelete || props.showEdit) {
        span++
    }

    return span
})

const http = useHttpClient()
const deleteConfirms = createInlineConfirmCollection<{ id: string }>((id: string) => ({
    async action() {
        await http.delete(`/championship/registration/${props.data.registrationId}/team/${id}`)
        emit("updated")
    },
}))
</script>

<template>
    <table class="table table-striped">
        <thead>
            <tr>
                <th></th>
                <th>{{ $t("@tasks:tasks.championship_registration.team_list.team_name") }}</th>
                <th>{{ $t("@tasks:tasks.championship_registration.team_list.category") }}</th>
                <th>{{ $t("@tasks:tasks.championship_registration.team_list.number_of_members") }}</th>
                <th>{{ $t("@tasks:tasks.championship_registration.team_list.contact_person") }}</th>
                <th v-if="showPrice">{{ $t("@tasks:tasks.championship_registration.team_list.total_price") }}</th>
                <th v-if="showEdit || showDelete"></th>
            </tr>
        </thead>

        <tbody>
            <template v-for="team of props.teams" :key="team.id">
                <tr>
                    <td style="width: 1px" class="text-center pl-4">
                        <ChampionshipRegistrationApprovalStateIcon :state="team.approvalState" />
                    </td>
                    <td>
                        {{ team.teamName }}
                        <div
                            v-if="props.viewMode === 'admin' && team.waitingListApprovalAcceptedAt"
                            class="text-muted small"
                        >
                            {{
                                $t(
                                    "@tasks:tasks.championship_registration.waiting_list.waiting_list_approval_accepted_at",
                                    {
                                        date: $formatDate(team.waitingListApprovalAcceptedAt, "dd.MM.yyyy"),
                                        time: $formatDate(team.waitingListApprovalAcceptedAt, "HH:mm"),
                                    },
                                )
                            }}
                        </div>
                    </td>
                    <td>
                        {{ team.championshipCategory.name }}
                    </td>
                    <td>
                        <div>
                            {{
                                $t("@tasks:tasks.championship_registration.team_list.number_of_participants", {
                                    n: team.numberOfParticipants,
                                })
                            }}
                        </div>
                        <div>
                            {{
                                $t("@tasks:tasks.championship_registration.team_list.number_of_alternates", {
                                    n: team.numberOfAlternates,
                                })
                            }}
                        </div>
                        <div>
                            {{
                                $t("@tasks:tasks.championship_registration.team_list.number_of_coaches", {
                                    n: team.numberOfCoaches,
                                })
                            }}
                        </div>
                        <div>
                            {{
                                $t("@tasks:tasks.championship_registration.team_list.number_of_supervisors", {
                                    n: team.numberOfSupervisors,
                                })
                            }}
                        </div>
                    </td>
                    <td>
                        <div v-if="team.contactPersonName">
                            <i class="fa fa-fw fa-user"></i>
                            {{ team.contactPersonName }}
                        </div>
                        <div v-if="team.contactPersonPhone">
                            <i class="fa fa-fw fa-phone"></i>
                            {{ team.contactPersonPhone }}
                        </div>
                        <div v-if="team.contactPersonMail">
                            <i class="fa fa-fw fa-envelope"></i>
                            {{ team.contactPersonMail }}
                        </div>
                    </td>
                    <td v-if="showPrice">
                        {{ $currency(team.totalPrice) }}
                    </td>
                    <td class="text-right" v-if="showEdit || showDelete">
                        <button
                            v-if="showEdit"
                            type="button"
                            class="btn btn-primary"
                            @click="
                                inlineTable.open(
                                    { team: team, data: props.data },
                                    ChampionshipRegistrationEditTeamWizard,
                                    team.id,
                                )
                            "
                        >
                            <i class="fa fa-fw fa-edit"></i>
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="deleteConfirms(team.id).toggle()"
                            v-if="showDelete"
                        >
                            <i class="fa fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
                <tr class="open-row">
                    <td :colspan="colspan" class="p-0">
                        <VarsityInlineConfirm :controller="deleteConfirms(team.id)">
                            <template #confirmation>
                                {{ $t("@tasks:tasks.championship_registration.team_list.delete_confirmation") }}
                            </template>
                            <template #success>
                                {{ $t("@tasks:tasks.championship_registration.team_list.delete_success") }}
                            </template>
                        </VarsityInlineConfirm>
                    </td>
                </tr>
                <slot name="secondaryRow" v-bind="{ team, colspan }"></slot>
                <VfListCompose :colspan="colspan" :row-id="team.id" :inline-table="inlineTable"></VfListCompose>
            </template>
        </tbody>
    </table>
</template>
