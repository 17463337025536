<script setup lang="ts">
import type { ConditionFormData } from "@/components/form"
import ConditionForm from "@/components/form/ConditionForm.vue"
import { type OrganizationMember } from "@/model/app/organization-member"
import type { User } from "@/model/app/user"
import { generateRandomString, useApp, useAppConfig, useHttpClient } from "@/vf"
import { reactive, ref, toRefs, watch } from "vue"

const http = useHttpClient()
const { appConfig } = useAppConfig()
const { apiLink } = useApp()

const props = defineProps<{
    formDocuments: string[]
    selectedInputType: string
    myProfile: User
    myAthletes: { account: OrganizationMember }[]
    selectedAthlete: OrganizationMember | null
    organizations: { id: string; name: string; label: string }[]
}>()
const { formDocuments, selectedInputType, myProfile, myAthletes, selectedAthlete, organizations } = toRefs(props)

const formData = reactive<ConditionFormData>({
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    street: "",
    streetNumber: "",
    zip: "",
    city: "",
    organization: "",
    country: "",
})
const submitInProgress = ref(false)
const formErrors = ref<Record<string, string[]>>({})
const form = ref([])
watch(
    [selectedInputType, form],
    async () => {
        if (selectedInputType.value === "myData") {
            resetFormData()
            formData.firstName = appConfig.value.login.firstName
            formData.lastName = appConfig.value.login.lastName
            formData.birthDate = appConfig.value.login.birthDate?.split("T")?.[0]
            formData.gender = appConfig.value.login.gender
            // try to find a gender field and then try to set it
            for (const formElement of form.value) {
                if (formElement.type === "radio" && formElement.config?.mapping == "gender") {
                    for (const line of formElement.choices) {
                        switch (appConfig.value.login.gender) {
                            case "m": {
                                if (["m", "male", "männlich"].includes(line.toLowerCase())) {
                                    formData.gender = line
                                }

                                break
                            }
                            case "f": {
                                if (["f", "w", "female", "weiblich"].includes(line.toLowerCase())) {
                                    formData.gender = line
                                }

                                break
                            }
                        }
                    }
                }
            }

            useDebitorProfileAddress()
        } else if (selectedInputType.value == "organization") {
            resetFormData()
            myAthletes.value = (
                await http.get<{ athletes: { account: OrganizationMember }[] }>("my-varsity/my-athletes/manager")
            ).data.athletes
            if (myAthletes.value.length > 0) {
                selectedAthlete.value = myAthletes.value[0].account
                // data was reseted above, so apply the selected athlete data again
                useSelectedAthlete()
            }
        }
    },
    { immediate: true },
)

function resetFormData() {
    formData.firstName = ""
    formData.lastName = ""
    formData.birthDate = ""
    formData.gender = ""
    formData.street = ""
    formData.streetNumber = ""
    formData.zip = ""
    formData.city = ""
    formData.organization = ""
    formData.country = ""
    // streetStreetNumberChanged()
    // zipCityChanged()
}
function useSelectedAthlete() {
    if (!selectedAthlete.value) return
    console.log("load athlete", selectedAthlete.value)
    formData.firstName = selectedAthlete.value.firstName
    formData.lastName = selectedAthlete.value.lastName
    formData.organization = selectedAthlete.value.organizationName
}
watch(selectedAthlete, () => useSelectedAthlete(), { immediate: true })

function useDebitorProfileAddress() {
    if (!myProfile.value.primaryDebitorProfile) return
    const address = myProfile.value.primaryDebitorProfile
    console.log("load address", address)
    formData.street = address.street
    formData.streetNumber = address.streetNumber
    formData.zip = address.zip
    formData.city = address.city
    formData.country = address.country
    // streetStreetNumberChanged()
    // zipCityChanged()
}
/*─────────────────────────────────────┐
│   submit                             │
└─────────────────────────────────────*/

async function submit(form: HTMLFormElement) {
    submitInProgress.value = true

    const validation = await http.post<Record<string, string[]>>(
        "/legal/form/validate",
        {
            ids: formDocuments.value.join(","),
            ...formData,
        },
        { validateStatus: status => [422, 204].includes(status) },
    )

    if (validation.status === 422) {
        formErrors.value = validation.data
        submitInProgress.value = false
        return
    }

    // reset errors, as apparently the validation succeeded this time
    formErrors.value = {}

    // create a new form to submit to the pdf endpoint in a new window using POST (because the data is too long for GET)
    const pdfForm = document.createElement("form")
    pdfForm.action = apiLink("legal/form/pdf/?ids=" + formDocuments.value.join(","))
    pdfForm.method = "POST"
    // open in same tab, because POST and popups don't work well together (popup blockers)
    // pdfForm.target = "_blank"

    // copy data to new form
    new FormData(form).forEach((value, key) => {
        const input = document.createElement("input")
        input.type = "hidden"
        input.name = key
        input.value = value as string
        pdfForm.appendChild(input)
    })

    document.body.appendChild(pdfForm)
    pdfForm.submit()

    submitInProgress.value = false
}

const formId = generateRandomString()
</script>

<template>
    <ConditionForm
        :form-ids="formDocuments"
        v-model="formData"
        :form-errors="formErrors"
        :form-id="formId"
        :organizations="organizations"
        @form-changed="form = $event"
        @submit="submit($event)"
    ></ConditionForm>
    <div class="d-flex justify-content-between mt-5">
        <button class="btn btn-primary" type="reset" :disabled="submitInProgress" :form="formId">
            {{ $t("@cms:onlineForm.reset") }}
        </button>
        <button class="btn btn-primary" type="submit" :disabled="submitInProgress" :form="formId">
            {{ $t("@cms:onlineForm.submit") }}
        </button>
    </div>
</template>
