<script setup lang="ts">
type StepProps = {
    issue: Issue
    task: ChampionshipRegistrationStepTask
    championship: Championship
    stepData: any
}
import { useLegacyHttpClient } from '@/composables/compat/useLegacyHttpClient'
import { ref } from 'vue'
import Step1EditTeam from './step1_edit_team.vue'
const props = defineProps<StepProps>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
async function refresh() {
    const response = await http.get(`/championship/registration/${props.issue.id}/registrations`)
    registrations.value = response.content.teams
    orderState.value = response.content.orderState
}
async function create() {
    props.stepData.team = null
    await http.post(`/championship/registration/${props.issue.id}/add-team`, {})
    emit("updated")
}
async function nextStep() {
    props.stepData.team = null
    await http.post(`/championship/registration/${props.issue.id}/finish-teams`, {})
    emit("updated")
}
function edit(team) {
    if (props.stepData.team == team) {
        props.stepData.team = null
    } else {
        props.stepData.team = team
    }
}
const http = useLegacyHttpClient()
const registrations = ref<{ teamName: string; id: string }[]>()
const orderState = ref()
await refresh()
</script>

<template>
    <div>
        <div class="d-flex justify-content-between">
            <button class="btn btn-success" @click="create()">
                {{ $t("@championship:registration.window.team.new") }}
            </button>
            <button class="btn btn-success" @click="nextStep()">
                {{ $t("@championship:registration.window.team.continue") }}
            </button>
        </div>

        <div class="my-4" v-for="team in registrations">
            <div class="d-flex justify-content-between align-items-center my-2">
                <div>{{ team.teamName }}</div>
                <button class="btn btn-primary" type="button" @click="edit(team)">
                    {{ $t("@tasks:tasks.championship_registration.show_registrations") }}
                </button>
            </div>

            <div class="bg-primary pt-1"></div>
            <div class="mt-2" v-if="team.id === props.stepData.team?.id">
                <Step1EditTeam v-bind="props" @updated="$emit('updated')"></Step1EditTeam>
            </div>
        </div>
    </div>
</template>
