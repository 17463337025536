import { defineStore } from "pinia"
import { computed, markRaw, ref, type Component } from "vue"
import VfDialogTypeFeedback from "./VfDialogFeedback.vue"

export type TranslationVars = Record<string, string>

export type DialogResult<T> = {
    output: T | null
    wasCancelled: boolean
    text?: string
}

export class DialogController<ResultType, PropsType = SimpleDialogOptions>
    implements PromiseLike<DialogResult<ResultType>>
{
    public resolve!: (value: DialogResult<ResultType>) => void
    public reject!: () => void
    public readonly promise: Promise<DialogResult<ResultType>>
    public readonly props: PropsType
    public readonly component: Component
    public readonly locked: boolean

    constructor(options: DialogOptions) {
        this.component = markRaw(options.component)
        this.props = options.props
        this.locked = options.locked ?? false

        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    then<TResult1 = DialogResult<ResultType>, TResult2 = never>(
        onfulfilled?: (value: DialogResult<ResultType>) => TResult1 | PromiseLike<TResult1>,
        onrejected?: (reason: any) => TResult2 | PromiseLike<TResult2>,
    ): PromiseLike<TResult1 | TResult2> {
        return this.promise.then(onfulfilled, onrejected)
    }

    ok(output: ResultType | null, text?: string) {
        this.resolve({
            wasCancelled: false,
            output,
            text,
        })
    }

    cancel(output: ResultType | null = null) {
        this.resolve({
            wasCancelled: true,
            output,
        })
    }

    whenClosed(cb?: (result: DialogResult<ResultType>) => any) {
        if (cb) {
            this.then(cb)
        }

        return this
    }

    catch(cb: (result: DialogResult<ResultType>) => any) {
        return this.whenClosed(cb)
    }
}

export const useDialogsStore = defineStore("vf-dialogs", () => {
    const dialogs = ref(new Set<DialogController<any>>())

    function registerDialog(controller: DialogController<any>) {
        dialogs.value.add(controller)

        controller.whenClosed(() => {
            removeDialog(controller)
        })
    }

    function removeDialog(controller: DialogController<any>) {
        dialogs.value.delete(controller)
    }

    return {
        registerDialog,
        removeDialog,
        dialogs,
        hasOpenDialogs: computed(() => dialogs.value.size > 0),
        last: computed(() => [...dialogs.value].pop()),
    }
})

const componentByDialogType = {
    error: VfDialogTypeFeedback,
    success: VfDialogTypeFeedback,
    info: VfDialogTypeFeedback,
    confirm: VfDialogTypeFeedback,
    question: VfDialogTypeFeedback,
    loading: VfDialogTypeFeedback,
    progress: VfDialogTypeFeedback,
    prompt: VfDialogTypeFeedback,
}

export interface DialogService {
    open<T>(options: SimpleDialogOptions | DialogOptions): DialogController<T>

    alert<T>(
        title: string,
        message: string,
        options?: Omit<SimpleDialogOptions, "type" | "message">,
    ): DialogController<T>

    success<T>(options?: Omit<SimpleDialogOptions, "type">): DialogController<T>

    error<T>(options?: Omit<SimpleDialogOptions, "type">): DialogController<T>

    info<T>(messageKey: string, messageVars?: TranslationVars): DialogController<T>

    confirm(messageKey: string, messageVars?: TranslationVars): Promise<boolean>

    prompt(
        messageKey: string,
        placeholderKey?: string,
        options?: { inputType: "textarea" | "input" | "number" },
        messageVars?: TranslationVars,
    ): Promise<string | undefined>

    safeDelete(messageKey: string, required: string, messageVars?: TranslationVars): Promise<boolean>

    Buttons: { [name: string]: DialogButton }
}

export function useDialogs(): DialogService {
    const store = useDialogsStore()

    function open<T>(options: SimpleDialogOptions | DialogOptions): DialogController<T> {
        let autoClose = null

        if (!("component" in options)) {
            autoClose = options.autoClose
            options = normalizeSimpleOptions(options)
            options = {
                component: componentByDialogType[options.type],
                props: options,
                locked: options.locked,
            }
        }

        options = normalizeOptions(options)

        const controller = new DialogController<T>(options)
        store.registerDialog(controller)

        if (autoClose) {
            setTimeout(() => controller.ok(null), autoClose)
        }

        return controller
    }

    function alert<T>(
        title: string,
        message: string,
        options: Omit<SimpleDialogOptions, "type" | "message"> = {},
    ): DialogController<T> {
        return open({
            title,
            message,
            type: "info",
            translate: true,
            buttons: [Buttons.BUTTON_OK],
            ...options,
        })
    }

    function success<T>(options: Omit<SimpleDialogOptions, "type">): DialogController<T> {
        return open(normalizeSimpleOptions({ type: "success", autoClose: 2000, translate: true, ...options }))
    }

    function error<T>(options: Omit<SimpleDialogOptions, "type">): DialogController<T> {
        return open(
            normalizeSimpleOptions({
                type: "error",
                translate: true,
                buttons: [Buttons.BUTTON_OK],
                ...options,
            }),
        )
    }

    function info<T>(messageKey: string, messageVars = {}): DialogController<T> {
        return open({
            type: "info",
            message: messageKey,
            messageVars: messageVars,
            translate: true,
            buttons: [Buttons.BUTTON_OK],
        })
    }

    async function confirm(messageKey: string, messageVars = {}): Promise<boolean> {
        return (
            await open({
                message: messageKey,
                messageVars: messageVars,
                translate: true,
                buttons: [Buttons.BUTTON_NO, Buttons.BUTTON_YES],
                type: "question",
            })
        ).output as boolean
    }

    async function prompt(
        messageKey: string,
        placeholderKey: string | undefined = undefined,
        options: { inputType: "textarea" | "input" | "number"; placeholder?: string } = { inputType: "input" },
        messageVars = {},
    ): Promise<string | undefined> {
        const ctrl = await open({
            message: messageKey,
            messageVars: messageVars,
            translate: true,
            buttons: [Buttons.BUTTON_CANCEL, Buttons.BUTTON_OK],
            type: "prompt",
            placeholder: placeholderKey,
            ...options,
        })
        if (ctrl.wasCancelled) {
            throw Error("cancelled")
        }
        return ctrl.text
    }

    async function safeDelete(messageKey: string, required: string, messageVars = {}): Promise<boolean> {
        try {
            const text = await prompt(messageKey, undefined, { inputType: "input" }, messageVars)
            return text === required
        } catch (e) {
            return false
        }
    }

    return {
        open,
        alert,
        success,
        error,
        info,
        confirm,
        prompt,
        safeDelete,
        Buttons,
    }
}

export interface DialogButton {
    label: string
    icon?: string
    translate?: boolean
    handler: (ok: (result: any) => any, cancel: () => any, text: string) => void
    class?: string
}

export interface DialogOptions<PropsType = any | undefined> {
    component: Component
    props: PropsType
    locked?: boolean
}

export interface SimpleDialogOptions {
    type: "success" | "error" | "progress" | "info" | "question" | "loading" | "prompt"
    message: string | Element | Component | { outerHTML: string }
    messageVars?: any
    autoClose?: false | number
    translate?: boolean
    buttons?: DialogButton[]
    locked?: boolean
    placeholder?: string
    inputType?: "textarea" | "input" | "number"
    progress?: {
        // obviously only for progress type
        running: boolean
        error: boolean
        progress: number
    }
    icon?: string
    title?: string
    requiredValue?: string
}

export const Buttons: { [name: string]: DialogButton } = {
    BUTTON_OK: {
        label: "dialog.button.ok",
        translate: true,
        class: "btn btn-primary",
        handler: (ok, cancel, text) => ok(text),
    },
    BUTTON_YES: {
        label: "dialog.button.yes",
        translate: true,
        class: "btn btn-primary btn-raised",
        handler: ok => ok(true),
    },
    BUTTON_NO: {
        label: "dialog.button.no",
        translate: true,
        class: "btn btn-secondary",
        handler: ok => ok(false),
    },
    BUTTON_CANCEL: {
        label: "dialog.button.cancel",
        translate: true,
        class: "btn btn-secondary",
        handler: (ok, cancel) => cancel(),
    },
}

function normalizeSimpleOptions(
    options: Omit<Partial<SimpleDialogOptions>, "message"> & Pick<SimpleDialogOptions, "message">,
): SimpleDialogOptions {
    const normalizedOptions: SimpleDialogOptions = {
        ...options,
        autoClose: options.autoClose,
        translate: !!options.translate,
        locked: !!options.locked,
        type: options.type ?? "info",
        buttons: options.buttons ?? [],
        message: options.message ?? "",
        progress: options.progress,
    }

    for (const button of normalizedOptions.buttons ?? []) {
        if (!button.translate) {
            button.translate = false
        }

        if (!button.class) {
            button.class = "btn btn-secondary"
        }
    }

    return normalizedOptions
}

function normalizeOptions(options: DialogOptions): DialogOptions {
    options.locked ??= false

    return options
}
