export function classesToArray(classes: string | string[]) {
    if (Array.isArray(classes)) {
        return classes
    }

    if (typeof classes === "string") {
        return classes.split(" ")
    }

    throw new Error("classesToArray: classes must be string or array")
}
