<script setup lang="ts">
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { useNavbarState } from "@/stores"
import { useApp, useAppConfig } from "@/vf"
import { reactive, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"

const { t } = useI18n()
const navbarState = useNavbarState()
const http = useLegacyHttpClient()
const router = useRouter()
const { refreshAppConfig } = useAppConfig()

const loginError = ref("")
const credentials = reactive({
    username: "",
    password: "",
})

async function submit() {
    loginError.value = ""
    try {
        let req = http.createRequest("login").asPost().withContent(credentials)
        await req.send()
        await refreshAppConfig()

        if (router.hasRoute("@Scoring.Main.index")) {
            await router.push({ name: "@Scoring.Main.index" })
        } else if (router.hasRoute("@App.My.profile")) {
            await router.push({ name: "@App.My.profile" })
        }
    } catch (r) {
        console.log(r.content.error)
        // TODO translations
        if (r.content.error == "User account is disabled.") {
            loginError.value = t("@app:login.inline_login.login_locked")
        } else {
            loginError.value = t("@app:login.inline_login.login_failed")
        }
        setTimeout(() => (loginError.value = ""), 2000)
    }
}
</script>

<template>
    <div style="overflow: hidden; background-color: #eff1f1">
        <div class="bg-primary text-white px-4 py-3">
            <h1>{{ $t("@app:login.inline_login.login") }}</h1>
        </div>
        <div class="cms-admin-element-body">
            <form class="" @submit.prevent="submit()">
                <template v-if="!loginError">
                    <div class="form-group d-block">
                        <label for="username" class="font-weight-bold">
                            {{ $t("@app:login.inline_login.username") }}
                        </label>
                        <input
                            type="text"
                            class="form-control"
                            id="username"
                            v-model="credentials.username"
                            autocomplete="username"
                            required
                        />
                    </div>
                    <div class="form-group d-block">
                        <label for="password" class="font-weight-bold">
                            {{ $t("@app:login.inline_login.password") }}
                        </label>
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            v-model="credentials.password"
                            autocomplete="current-password"
                            required
                        />
                    </div>
                </template>
                <template v-if="loginError">
                    <div class="text-danger navbar-login-fw align-self-center">{{ loginError }}</div>
                    <div class="navbar-login-fw"></div>
                </template>
                <div class="text-right d-block">
                    <button type="submit" class="btn btn-primary">{{ $t("login.login") }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
