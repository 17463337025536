import { inject, onMounted, onUnmounted, provide, ref, type Ref } from "vue"

export type InlineConfirmAction = () => Promise<any> | any

export interface InlineConfirmOptions {
    action: InlineConfirmAction
    afterAction?: () => void | Promise<void>
    onCancel?: () => void
}

export interface InlineConfirm {
    isOpen: Ref<boolean>
    open(): void | Promise<void>
    close(): void | Promise<void>
    toggle(): void
    action(): void
    afterAction(): void | Promise<void>
    onOpenOrClose(cb: (open: boolean) => void): void
    onClose(cb: () => void | Promise<void>): void
    onOpen(cb: () => void | Promise<void>): void
}

export function createInlineConfirm(options: InlineConfirmOptions): InlineConfirm
export function createInlineConfirm(action: InlineConfirmAction): InlineConfirm

export function createInlineConfirm(optionsOrAction: InlineConfirmOptions | InlineConfirmAction): InlineConfirm {
    const isOpen = ref(false)
    // used by the inline confirm element to animate itself
    const openOrCloseCallback: Ref<(open: boolean) => void | Promise<void>> = ref(() => {})
    // used by the button to know when to collapse
    const closeCallback: Ref<() => Promise<void> | void> = ref(() => {})
    // used by the button to react to external programmatic opening
    const openCallback: Ref<() => void | Promise<void>> = ref(() => {})

    const options: InlineConfirmOptions =
        typeof optionsOrAction === "function" ? { action: optionsOrAction } : optionsOrAction

    async function open() {
        isOpen.value = true
        await openCallback.value()
        await openOrCloseCallback.value(true)
    }

    async function close() {
        isOpen.value = false
        await openOrCloseCallback.value(false)
        await closeCallback.value()
        options.onCancel?.()
    }

    function toggle() {
        if (isOpen.value) {
            close()
        } else {
            open()
        }
    }

    function onOpenOrClose(cb: (open: boolean) => void | Promise<void>) {
        openOrCloseCallback.value = cb
    }

    function onClose(cb: () => void | Promise<void>) {
        closeCallback.value = cb
    }

    function onOpen(cb: () => void | Promise<void>) {
        openCallback.value = cb
    }

    return {
        isOpen,
        toggle,
        open,
        close,
        action: options.action,
        afterAction: options.afterAction ?? (() => null),
        onOpenOrClose,
        onClose,
        onOpen,
    }
}

export function createInlineConfirmCollection<T>(optionFactory: (key: string, args: T) => InlineConfirmOptions) {
    const collection: Map<string, InlineConfirm> = new Map()

    return function get(key: string, args: T = undefined): InlineConfirm {
        if (!collection.has(key)) {
            collection.set(key, createInlineConfirm(optionFactory(key, args)))
        }

        return collection.get(key)
    }
}

export function createInlineConfirmGroup() {
    provide("inlineConfirmGroup", [])
}

export function useInlineConfirmGroup(inlineConfirm: InlineConfirm) {
    const confirms: InlineConfirm[] = inject("inlineConfirmGroup", [])

    async function closeOther() {
        confirms.forEach(async confirm => {
            if (confirm !== inlineConfirm && confirm.isOpen) {
                await confirm.close()
            }
        })
    }

    onMounted(() => {
        confirms.push(inlineConfirm)
    })

    onUnmounted(() => {
        confirms.splice(confirms.indexOf(inlineConfirm), 1)
    })

    return {
        closeOther,
    }
}
