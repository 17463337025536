<script setup lang="ts">
import type { NewsPost } from "@/model/c-m-s/news-post"
import NewsCardVertical from "./NewsCardVertical.vue"

const props = withDefaults(
    defineProps<{
        posts: NewsPost[]
        perRow: number
        centered?: boolean
    }>(),
    {
        centered: false,
    },
)
</script>

<template>
    <div
        class="news-grid"
        :class="centered ? 'flex' : 'grid'"
        :style="centered ? {} : { '--news-grid-per-row': perRow } as any"
    >
        <NewsCardVertical v-for="post of props.posts" :key="post.id" :post="post" />
    </div>
</template>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.news-grid.grid {
    display: grid;
    gap: 2rem;
    /* grid-template-rows: repeat(auto-fill, minmax(400px, 1fr)); */
    grid-auto-rows: minmax(400px, 1fr);
    grid-template-columns: 1fr;
    --news-grid-per-row: auto-fill;
}

@include media-breakpoint-up(md) {
    .news-grid.grid {
        grid-template-columns: repeat(var(--news-grid-per-row), minmax(250px, 1fr)) !important;
    }
}

.news-grid.flex {
    display: flex;
    justify-content: center;
    gap: 2rem;
}
.news-grid.flex :deep(.news-card) {
    max-width: 350px;
    flex: 1 1;
}
</style>
