import { type FormModel } from "@/vf"
import { inject, ref, type Ref } from "vue"

// gets provided by VfForm and is used in multiple parts of the crud form system. todo: add a proper type to schema and formModel
export interface VfFormInject {
    schema: any
    formModel: Ref<FormModel>
    renderedList: Ref<any[]>
}

export function findSchemaForName(name: string, elementName: string, path?: string) {
    const form = inject<VfFormInject | null>("vf-form", null)
    if (!form) {
        throw Error(elementName + ": no VfForm found")
    }
    const nameParts = name.split(".")
    if (nameParts.length > 1 && elementName != "VfFormLabel") {
        let helpText = "<" + elementName + ' name="' + nameParts[nameParts.length - 1] + '"/>'
        for (let i = nameParts.length - 2; i >= 0; i--) {
            helpText = '<VfFormGroup name="' + nameParts[i] + '">' + helpText + "</VfFormGroup>"
        }

        throw Error(
            "You cannot use dot-syntax in the name of a form field other than VfFormLabel. Use " +
                helpText +
                " instead.",
        )
    }
    let trace = ""
    let schema: any = { children: form.schema.value }
    if (path) {
        // walk down the path without using it later in the generated schema
        for (const namePart of path.split(".")) {
            if (/^\d+$/.test(namePart)) {
                // ignore numeric-only path parts because they are from array indexes
                continue
            }
            schema = schema.children.find((i: any) => i.name == namePart)

            if (!schema) {
                throw Error(elementName + ': no form field named "' + namePart + '" found (' + trace + ")")
            }

            trace += "[path]" + namePart + "."
        }
    }
    for (const namePart of nameParts) {
        if (/^\d+$/.test(namePart)) {
            continue
        }
        schema = schema.children.find((i: any) => i.name == namePart)

        if (!schema) {
            throw Error(elementName + ': no form field named "' + namePart + '" found (' + trace + ")")
        }
        trace += "[name]" + namePart + "."
    }

    return schema
}

export function getVfFormInject(): VfFormInject | undefined {
    return inject<VfFormInject | undefined>("vf-form", undefined)
}

export function findDataForName(name: string, elementName: string, form?: VfFormInject): Ref<any> {
    if (!form) {
        form = getVfFormInject()
    }
    if (!form) {
        throw Error(elementName + ": no VfForm found")
    }
    const nameParts = name.split(".")
    nameParts.pop()
    let data = form.formModel.value
    for (const namePart of nameParts) {
        data = data[namePart]
    }
    return ref(data)
}
