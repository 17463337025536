<script setup lang="ts">
import { computed, reactive, ref, watch, watchEffect } from "vue"
import type {
    ChampionshipRegistrationContactPerson,
    ChampionshipRegistrationSelectTeamData,
    OrganizationManager,
} from "./championship-registration"

const props = defineProps<{
    modelValue: ChampionshipRegistrationContactPerson
    data?: ChampionshipRegistrationSelectTeamData
}>()
const emit = defineEmits<{
    (e: "update:modelValue", value: ChampionshipRegistrationContactPerson): void
    (e: "validate", valid: boolean): void
}>()

function normalize(
    contactPerson: Partial<ChampionshipRegistrationContactPerson>,
): ChampionshipRegistrationContactPerson {
    return {
        contactPersonName: contactPerson.contactPersonName ?? "",
        contactPersonPhone: contactPerson.contactPersonPhone ?? "",
        contactPersonMail: contactPerson.contactPersonMail ?? "",
    }
}

const values = reactive<ChampionshipRegistrationContactPerson>(normalize(props.modelValue))
watch(
    () => values,
    () => {
        emit("update:modelValue", normalize(values))
    },
    { deep: true },
)

const valid = computed(
    () =>
        values.contactPersonName?.length > 1 &&
        values.contactPersonPhone?.length > 1 &&
        values.contactPersonMail?.length > 1 &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.contactPersonMail),
)

watchEffect(() => emit("validate", valid.value))

/*─────────────────────────────────────┐
│  copy from manager                   │
└─────────────────────────────────────*/
const selectedManager = ref<OrganizationManager>(null)
function copyFromManager() {
    values.contactPersonName = selectedManager.value.name ?? ""
    values.contactPersonPhone = selectedManager.value.phone ?? ""
    values.contactPersonMail = selectedManager.value.mail ?? ""
    selectedManager.value = null
}
</script>
<template>
    <b>
        {{ $t("@tasks:tasks.championship_registration.wizard.please_provide_team_contact_person") }}
    </b>

    <div class="my-3" v-if="props.data?.availableManagers?.length > 0">
        <b>{{ $t("@championship:registration.window.edit_team.contactPerson.copy_from_manager") }}</b>
        <select class="form-control" @change="copyFromManager()" v-model="selectedManager">
            <option value=""></option>
            <option v-for="manager of props.data.availableManagers" :value="manager">
                {{ manager.name }}
            </option>
        </select>
    </div>

    <div class="my-3">
        <b>{{ $t("@championship:registration.window.edit_team.contactPerson.name") }}</b>
        <input type="text" class="form-control" v-model="values.contactPersonName" />
    </div>

    <div class="my-3">
        <b>{{ $t("@championship:registration.window.edit_team.contactPerson.phone") }}</b>
        <input type="tel" class="form-control" v-model="values.contactPersonPhone" />
    </div>

    <div class="my-3">
        <b>{{ $t("@championship:registration.window.edit_team.contactPerson.mail") }}</b>
        <input type="email" class="form-control" v-model="values.contactPersonMail" />
    </div>
</template>
