import { useApp } from "@/vf"

export type ColorBlendConfig = {
    colorBlend: boolean
    colorBlendColor: string
    colorBlendMode: string
    colorBlendOpacity: number
}

export function createBackgroundImageColorBlend(config: ColorBlendConfig, fileName: string) {
    return [
        `background-image: ` + createBackgroundImageColorBlendValue(config, fileName),
        `background-blend-mode: ${config.colorBlendMode ?? "normal"}`,
    ]
}

export function createBackgroundImageColorBlendValue(config: ColorBlendConfig, fileName: string) {
    const { apiLink } = useApp()
    const backgrounds = []

    if (config.colorBlend) {
        const alphaHex = Math.round((config.colorBlendOpacity / 100) * 255)
            .toString(16)
            .padStart(2, "0")
        const color = config.colorBlendColor.substring(0, 7) + alphaHex
        backgrounds.push(`linear-gradient(${color}, ${color})`)
    }

    if (fileName) {
        backgrounds.push(`url(${apiLink("/media/" + fileName)})`)
    }

    return backgrounds.join(", ")
}
