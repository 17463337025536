<script setup lang="ts">
import type { Championship } from "@/model/championship/championship"
import { $date, useApp } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import { ref, toRefs } from "vue"
import CmsEventCardInner from "./CmsEventCardInner.vue"

const props = defineProps<{
    event: Championship
}>()

const { event } = toRefs(props)
const { apiLink } = useApp()
const expanded = ref(false)
</script>

<template>
    <div class="event-card">
        <div class="row event-card__row">
            <div class="col-lg-2 col-4 event-card__col">
                <div class="event-card__logo" v-if="event.logoName">
                    <img class="img-fluid" :alt="event.brandName" :src="apiLink(`/media/${event.logoName}`)" />
                </div>
            </div>
            <div class="col-lg-2 col-8 event-card__col event-card__col--name">
                <b>{{ $t("@championship:registration.event.name") }}</b>
                <div>{{ event.name }}</div>
            </div>
            <div class="col-lg-2 col-md-4 col-12 event-card__col">
                <b class="d-none d-md-block">{{ $t("@championship:registration.event.location") }}</b>
                <div>
                    {{ event.location }}
                    <span class="d-lg-none d-xl-none">({{ event.city }})</span>
                </div>
            </div>
            <div class="col-lg-2 col-12 event-card__col d-none d-lg-block">
                <b>{{ $t("@championship:registration.event.city") }}</b>
                <div>{{ event.city }}</div>
            </div>
            <div class="col-lg-4 col-md-8 col-12 event-card__col event-card__col--date">
                <b class="d-none d-md-block">{{ $t("@championship:registration.date") }}</b>
                <div>
                    {{
                        event.dateFrom === event.dateUntil
                            ? $date(event.dateFrom)
                            : $date(event.dateFrom) + " - " + $date(event.dateUntil)
                    }}
                </div>
            </div>
            <div class="event-card__toggle" :class="{ active: expanded }" @click="expanded = !expanded">
                <i class="fa fa-fw fa-plus"></i>
            </div>
        </div>
        <AnimateIf :if="expanded">
            <CmsEventCardInner :event="event" />
        </AnimateIf>
    </div>
</template>
