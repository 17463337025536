import type { AxiosInstance, AxiosRequestConfig } from "axios"
import type { Component } from "vue"
import type { RouteRecordRaw, Router } from "vue-router"
import { VfSaveDeleteDialog, type Entity, type HttpContent, type ModuleConfiguration } from ".."

export { default as vfConfig } from "@/vf.config"

export type HttpRequestHandlerArguments = {
    method: "post" | "put" | "get" | "delete"
    url: string
    data?: HttpContent
    config?: AxiosRequestConfig
    client: AxiosInstance
}
export type HttpRequestHandler = (args: HttpRequestHandlerArguments) => void
export type ReconfigureRoutesFn = (router: Router, newRoutes: (RouteRecordRaw & { moduleId: string })[]) => void

export interface VfConfig {
    http: { requestHandlers: HttpRequestHandler[] }
    reconfigureRoutes: ReconfigureRoutesFn
    safeDeleteDialog: {
        component: (config: ModuleConfiguration, entity: Entity) => Component
    }
    getLocalStorage: () => Promise<Storage>
    getSessionStorage: () => Promise<Storage>
}

export interface VfConfigOptions {
    http?: { requestHandlers?: HttpRequestHandler[] }
    reconfigureRoutes?: ReconfigureRoutesFn
    safeDeleteDialog?: {
        component?: (config: ModuleConfiguration, entity: Entity) => Component
    }
    getLocalStorage?: () => Promise<Storage>
    getSessionStorage?: () => Promise<Storage>
}

export function defineVfConfig(options: VfConfigOptions): VfConfig {
    const config: VfConfig = {
        http: {
            requestHandlers: [],
        },
        reconfigureRoutes() {
            console.error("reconfigureRoutes() not implemented")
        },
        safeDeleteDialog: {
            component: () => VfSaveDeleteDialog,
        },
        getLocalStorage: async () => window.localStorage,
        getSessionStorage: async () => window.sessionStorage,
    }

    config.http.requestHandlers.push(...(options.http?.requestHandlers ?? []))
    config.reconfigureRoutes = options.reconfigureRoutes ?? config.reconfigureRoutes
    config.getLocalStorage = options.getLocalStorage ?? config.getLocalStorage
    config.getSessionStorage = options.getSessionStorage ?? config.getSessionStorage
    config.safeDeleteDialog.component = options.safeDeleteDialog?.component ?? config.safeDeleteDialog.component

    return config
}
