<script setup lang="ts">
import VbList from "@/components/crud/VbList.vue"
import VbListSearch from "@/components/crud/VbListSearch.vue"
import VbListToolbar from "@/components/crud/VbListToolbar.vue"
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import type { LoginSearchFilter } from "@/model/app/login-search-filter"
import FilterSearchFilterButton from "@/pages/Tasks/Issue/FilterSearchFilterButton.vue"
import ProjectFilter from "@/pages/Tasks/Issue/ProjectFilter.vue"
import { type ListState } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import { ref } from "vue"
import { useRouter } from "vue-router"
import FilterOnlyMessagesButton from "../Issue/FilterOnlyMessagesButton.vue"
import FilterMyOrganizationsDropdown from "../Issue/FilterOrganizationsDropdown.vue"
import { createModuleConfiguration } from "./config"

const http = useLegacyHttpClient()

const props = withDefaults(
    defineProps<{
        allowNew?: boolean
    }>(),
    { allowNew: true },
)

const router = useRouter()
const filterOnlyMessages = ref(false)
const config = createModuleConfiguration("tasks/issue", filterOnlyMessages)
config.list.newButton = () => props.allowNew
let listState: ListState

const openProjectFilters = ref<boolean>(false)
const loginSearchFilters = ref<[LoginSearchFilter]>(
    (await http.get("/login/search-filter/?listId=" + encodeURIComponent(config.list.id()))).content,
)
console.log(config.list.id())
const loginSearchFilterActiveFilterId = ref<string>("")
const projectFilterRef = ref(null)

function onLoaded({ listState: newState }: { listState: ListState }) {
    listState = newState
    if (router.currentRoute.value.query.issue) {
        listState.columnClicked(
            { id: router.currentRoute.value.query.issue as string },
            { name: "virtual", title: "virtual", type: "string" },
        )
    }
}

const originalShow = config.show.page
config.show.page = item => {
    const listItem = listState?.list?.find(i => i.id === item.id)
    if (listItem) {
        listItem.unreadMessagesCount = 0
    }
    return originalShow(item)
}

function updateLoginSearchFilters(filters: [LoginSearchFilter]) {
    loginSearchFilters.value = filters
}
function loadLoginSearchFilter(filterId: string) {
    projectFilterRef.value.openLoginSearchFilter(loginSearchFilterActiveFilterId.value === filterId ? "" : filterId)
}
function showProjectFilters(open: boolean = true) {
    openProjectFilters.value = open
}
function setLoginSearchFilterActiveFilterId(filterId: string) {
    loginSearchFilterActiveFilterId.value = filterId
}
</script>

<template>
    <VbList :config="config" @loaded="onLoaded">
        <template #toolbar="{ refresh }">
            <VbListToolbar></VbListToolbar>
            <FilterOnlyMessagesButton
                v-bind="{ config }"
                @toggle="filterOnlyMessages = $event"
            ></FilterOnlyMessagesButton>
            <FilterMyOrganizationsDropdown v-bind="{ config }" :customer-view="true"></FilterMyOrganizationsDropdown>
            <VbListSearch @search="refresh()"></VbListSearch>
            <div class="mt-2">
                <FilterSearchFilterButton
                    v-bind="{ filter }"
                    v-for="filter in loginSearchFilters"
                    :class="{ 'btn-primary-dark': filter.id === loginSearchFilterActiveFilterId }"
                    @click="loadLoginSearchFilter(filter.id)"
                ></FilterSearchFilterButton>
            </div>
            <div class="mt-4" v-animate-show="openProjectFilters">
                <ProjectFilter
                    v-bind="{ config, admin: true, loginSearchFilters, loginSearchFilterActiveFilterId }"
                    @update-login-search-filters="updateLoginSearchFilters"
                    @set-login-search-filter-active-filter-id="setLoginSearchFilterActiveFilterId"
                    @show-project-filters="showProjectFilters"
                    ref="projectFilterRef"
                />
            </div>
        </template>
    </VbList>
</template>
