<script lang="ts" setup>
import { computed, ref } from "vue"
import CmsFaqPage from "./faq/CmsFaqPage.vue"
import CmsFaqSidebarGroup from "./faq/CmsFaqSidebarGroup.vue"
import { type FaqGroup, type FaqPage } from "./faq/FaqTypes"

const props = defineProps<{
    element: any
}>()

const group = computed<FaqGroup>(() => props.element.group)
const page = computed<FaqPage>(() => props.element.page)

const selectedPage = ref<FaqPage>()
</script>

<template>
    <div v-if="group">
        <div class="bg-primary text-white px-4 py-3" v-if="group.name">
            {{ group.name }}
        </div>
        <div class="row mt-3">
            <div class="col-md-3">
                <div class="accordion-enumeration-menu">
                    <CmsFaqSidebarGroup
                        v-for="childGroup in group.children"
                        :group="childGroup"
                        :selected-page="selectedPage"
                        @page-selected="selectedPage = $event"
                    />
                </div>
            </div>
            <div class="col-md-9">
                <CmsFaqPage :page="selectedPage" v-if="selectedPage" />
            </div>
        </div>
    </div>

    <div v-if="page">
        <CmsFaqPage :page="page" />
    </div>
</template>
