export function registerYouTubePlaceholder(vueApp) {
    vueApp.directive("youtube-placeholder", {
        mounted(el: HTMLElement) {
            el.addEventListener("click", (event: MouseEvent) => {
                if ((event.target as HTMLElement).classList.contains("youtube-placeholder")) {
                    const videoId = (event.target as HTMLElement).getAttribute("data-video-id")
                    const iframe = document.createElement("iframe")
                    iframe.setAttribute("src", `https://www.youtube.com/embed/${videoId}`)
                    iframe.setAttribute("frameborder", "0")
                    iframe.setAttribute("allowfullscreen", "1")
                    iframe.setAttribute(
                        "allow",
                        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                    )
                    iframe.setAttribute("width", "560")
                    iframe.setAttribute("height", "315")
                    ;(event.target as HTMLElement).replaceWith(iframe)
                }
            })
        },
    })
}
