<script setup lang="ts">
import { VfFormRow } from "@/vf"
import VfFormGroup from "@/vf/components/crud/form/VfFormGroup.vue"
import { inject, ref, type Ref } from "vue"

const { data, schema, name, removeRow } = inject<{
    data: any
    schema: Ref
    name: Ref
    removeRow: (index: number) => void
}>("vf-form-collection", {
    data: ref(null),
    schema: ref(null),
    name: ref(null),
    removeRow: () => {},
})
</script>

<template>
    <VfFormGroup v-for="(item, index) in data[name]" :key="index" :name="index.toString()">
        <slot v-bind="{ removeRow: () => removeRow(index), item, index }">
            <VfFormRow :name="index.toString()" :schema="schema"></VfFormRow>
            <button type="button" class="btn btn-danger" @click="removeRow(index)">
                {{ $t("crud:button.delete") }}
            </button>
        </slot>
    </VfFormGroup>
</template>
