<script setup lang="ts">
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { useNavbarState } from "@/stores"
import { ref } from "vue"

const lostPasswordError = ref(null)
const passwordLinkRequested = ref(null)
const lostPassword = ref({
    email: "",
})
const http = useLegacyHttpClient()
const loading = ref(false)
const navbarState = useNavbarState()

async function submitLostPassword() {
    try {
        loading.value = true
        lostPasswordError.value = null
        await http.post("request_password", { email: lostPassword.value.email })
        passwordLinkRequested.value = true
    } catch (r) {
        if (r.statusCode == 400) {
            lostPasswordError.value = "Passwortanfrage wurde bereits gestellt."
        }
        if (r.statusCode == 404) {
            lostPasswordError.value = "E-Mail nicht gefunden."
        }
    }
    loading.value = false
}
</script>

<template>
    <form @submit.prevent="submitLostPassword()">
        <div class="card-body">
            <h5 class="card-title">{{ $t("login.lost_password") }}</h5>
            <p class="text-justify">{{ $t("login.lost_password_text") }}</p>
            <div class="alert alert-danger" v-if="lostPasswordError">{{ lostPasswordError }}</div>
            <div class="alert alert-success" v-if="passwordLinkRequested">
                {{ $t("login.lost_password_requested") }}
            </div>
            <div class="my-4" v-if="!passwordLinkRequested">
                <div class="form-group">
                    <label for="lostpassword_email">{{ $t("login.email") }}</label>
                    <input
                        type="email"
                        id="lostpassword_email"
                        class="form-control"
                        required
                        v-model="lostPassword.email"
                    />
                </div>
            </div>

            <div class="d-flex justify-content-between align-items-lg-start mt-3 flex-column-reverse flex-lg-row">
                <button type="button" class="btn btn-secondary" @click="navbarState.openCard('login')">
                    {{ $t("login.back") }}
                </button>
                <button type="submit" class="btn btn-primary mb-4" :disabled="loading || passwordLinkRequested">
                    {{ $t("login.request") }}
                </button>
            </div>
        </div>
    </form>
</template>
