<script setup lang="ts">
import { getHref, getTarget } from "@/pages/Cms/Frontend/Elements/link-service"
import { useApp } from "@/vf"
import Swiper from "swiper"
import type { Ref } from "vue"
import { onBeforeUnmount, onMounted, ref } from "vue"
import { calculateStyleString } from "../../CmsPage/configuration"

// props
const props = defineProps<{
    element: any
}>()

// functions
function attached() {
    setTimeout(() => {
        if (!containerElement.value) {
            return
        }

        swiper = new Swiper(containerElement.value, {
            direction: "horizontal",
            loop: true,
            slidesPerView: "auto",
            spaceBetween: 30,
        })
    }, 1000)
}

function prevSlide() {
    swiper.slidePrev()
}

function nextSlide() {
    swiper.slideNext()
}

// composables
const { apiLink } = useApp()

// refs
let swiper: Swiper

const containerElement: Ref<HTMLDivElement> = ref(null)
const pageNextElement: Ref<HTMLElement> = ref(null)
const pagePrevElement: Ref<HTMLElement> = ref(null)

// setup
onMounted(() => attached())
onBeforeUnmount(() => swiper?.destroy())
</script>

<template>
    <div
        ref="containerElement"
        class="swiper-container picture-gallery"
        :class="'picture-gallery--' + (props.element.config.pictureGalleryLayout || 'landscape')"
    >
        <div class="swiper-wrapper">
            <template v-for="child in element?.columns[0]">
                <div
                    class="swiper-slide"
                    v-if="element"
                    :style="
                        'background-image: url(' +
                        apiLink('/media/' + child.fileName) +
                        '); ' +
                        calculateStyleString(child)
                    "
                >
                    <a
                        :href="getHref(child)"
                        :target="getTarget(child)"
                        v-if="child.config?.buttonLink && !(child.overlayHeadline || child.overlayText)"
                        class="position-absolute d-block w-100 h-100"
                    ></a>
                    <div class="hover-overlay" v-if="child.overlayHeadline || child.overlayText">
                        <h4 class="hover-overlay__headline">{{ child.overlayHeadline }}</h4>
                        <p class="hover-overlay__text">{{ child.overlayText }}</p>
                        <a
                            :href="getHref(child)"
                            :target="getTarget(child)"
                            v-if="child.config?.buttonLink"
                            class="hover-overlay__btn btn btn-primary"
                        >
                            {{ child.buttonText }}
                        </a>
                    </div>
                </div>
            </template>
            <div class="swiper-slide" style="background-image: url(/assets/placeholder/pl1.jpg)" v-if="!element"></div>
            <div class="swiper-slide" style="background-image: url(/assets/placeholder/pl2.jpg)" v-if="!element"></div>
            <div class="swiper-slide" style="background-image: url(/assets/placeholder/pl3.jpg)" v-if="!element"></div>
            <div class="swiper-slide" style="background-image: url(/assets/placeholder/pl4.jpg)" v-if="!element"></div>
            <div
                class="swiper-slide"
                style="background-image: url(/assets/placeholder/Varsity-Style-Story.jpg)"
                v-if="!element"
            ></div>
        </div>
        <div ref="pagePrevElement" class="swiper-button-prev" @click="prevSlide()">
            <i class="fa fa-fw fa-chevron-left"></i>
        </div>
        <div ref="pageNextElement" class="swiper-button-next" @click="nextSlide()">
            <i class="fa fa-fw fa-chevron-right"></i>
        </div>
    </div>
</template>
