<script lang="ts" setup>
import { type Entity, injectMandatory, type ModuleConfiguration } from "@/vf"
import { computed, ref } from "vue"
import VbBackButton from "@/components/crud/VbBackButton.vue"
import VbDeleteButton from "@/components/crud/VbDeleteButton.vue"
import VbSaveButton from "@/components/crud/VbSaveButton.vue"

const props = defineProps<{
    item: Entity
}>()
const config = injectMandatory<ModuleConfiguration>("module-config", "VbEditToolbar must be used inside VbEdit")

const mode = computed(() => config.show.page(props.item).mode)
const deleteConfirmBox = ref(null)
</script>

<template>
    <div>
        <div class="d-flex justify-content-between">
            <VbBackButton v-if="config.edit.backButton() && mode !== 'router'"></VbBackButton>
            <VbDeleteButton
                v-if="config.edit.deleteButton(item)"
                :confirm-teleport-target="deleteConfirmBox"
            ></VbDeleteButton>
            <VbSaveButton></VbSaveButton>
        </div>
        <div ref="deleteConfirmBox"></div>
    </div>
</template>
