export function transformDate(dateString: string) {
    let yyyy, dd, mm, result

    if ((result = /^(\d{1,2}).(\d{1,2}).(\d{4})$/.exec(dateString))) {
        // format: dd.mm.yyyy
        yyyy = result[3]
        dd = parseInt(result[1])
        mm = parseInt(result[2])
    } else if ((result = /^(\d{1,2}).(\d{1,2}).(\d{2})$/.exec(dateString))) {
        // format: dd.mm.yy
        yyyy = "20" + result[3]
        dd = parseInt(result[1])
        mm = parseInt(result[2])
    } else if ((result = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.exec(dateString))) {
        // format: dd/mm/yyyy
        yyyy = result[3]
        dd = parseInt(result[2])
        mm = parseInt(result[1])
    } else if ((result = /^(\d{1,2})\/(\d{1,2})\/(\d{2})$/.exec(dateString))) {
        // format: dd/mm/yy
        yyyy = "20" + result[3]
        dd = parseInt(result[2])
        mm = parseInt(result[1])
    } else if ((result = /^(\d{4})-(\d{1,2})-(\d{1,2})$/.exec(dateString))) {
        // format: yyyy-mm-dd
        return dateString
    } else {
        try {
            return new Date(dateString).toISOString().split("T")[0]
        } catch {
            return undefined
        }
    }

    dd = `${dd}`.padStart(2, "0")
    mm = `${mm}`.padStart(2, "0")

    return yyyy + "-" + mm + "-" + dd
}

export function transformGender(gender: string) {
    if (gender.substring(0, 1).toLowerCase() == "m") {
        return "m"
    }

    return "f"
}
