<script setup lang="ts">
import ConditionForm from "@/components/form/ConditionForm.vue"
import { useHttpClient } from "@/vf"
import { ref } from "vue"

const http = useHttpClient()
const emit = defineEmits<{
    (e: "signed", form: FormData): void
}>()

async function save() {
    saving.value = true
    const formData = new FormData(document.getElementById("conditionForm") as HTMLFormElement)
    const pdfData = (
        await http.post<Blob>("/legal/form/pdf/?condition=Staff.PrimaryContract", formData, {
            responseType: "blob",
        })
    ).data

    const form = new FormData()
    form.append("document", pdfData)
    emit("signed", form)
}

const hasEmptySignatureFields = ref(true)
const saving = ref(false)
</script>

<template>
    <ConditionForm
        condition="Staff.PrimaryContract"
        formId="conditionForm"
        @has-empty-signature-fields="hasEmptySignatureFields = $event"
    ></ConditionForm>
    <div class="text-right">
        <button class="btn btn-primary" :disabled="hasEmptySignatureFields || saving" @click="save()">
            <template v-if="saving">
                <i class="fas fa-spinner fa-spin"></i>
            </template>
            <template v-else>
                {{ $t("@tasks:tasks.staff.sign") }}
            </template>
        </button>
    </div>
</template>

<style scoped></style>