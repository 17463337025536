<script setup lang="ts">
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import type { Ref } from "vue"
import { ref } from "vue"
import { useNavbarState } from "@/stores"

const http = useLegacyHttpClient()
const success: Ref<any> = ref(null)
const navbarState = useNavbarState()

const queryParams = new URLSearchParams(window.location.search)
if (queryParams.has("activate")) {
    try {
        await http.post("/registration/activate", {
            token: queryParams.get("activate"),
        })
        success.value = true
    } catch {
        success.value = false
    }
}
</script>

<template>
    <div class="card-body">
        <h5 class="card-title" v-t="'@app:registration.activation.title'">Aktivieren</h5>
        <div class="alert alert-success" v-t="'@app:registration.activation.success'" v-if="success"></div>
        <div class="alert alert-danger" v-t="'@app:registration.activation.failed'" v-if="success === false"></div>
        <div class="mt-4 text-right">
            <button class="btn btn-primary" @click="navbarState.openCard(null)">OK</button>
        </div>
    </div>
</template>
