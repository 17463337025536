<script setup lang="ts">
import type { ChampionshipRegistrationTeam } from "@/model/championship/championship-registration-team"
import { computed, ref } from "vue"
import ChampionshipRegistrationApprovalStateIcon from "./ChampionshipRegistrationApprovalStateIcon.vue"
import ChampionshipRegistrationTeamList from "./ChampionshipRegistrationTeamList.vue"
import type { ChampionshipRegistrationApproveData } from "./championship-registration"

const props = withDefaults(
    defineProps<{
        data: ChampionshipRegistrationApproveData
        teams: ChampionshipRegistrationTeam[]
        approveState: "approved" | "waiting_list_approved"
        viewMode: "customer" | "admin"
    }>(),
    {
        approveState: "approved",
    },
)

const emit = defineEmits<{
    (e: "updated"): void
}>()

/*─────────────────────────────────────┐
│  validation                          │
└─────────────────────────────────────*/
const approvalStates = ref<Record<string, string>>(
    Object.fromEntries(props.data.teams.map(team => [team.id, team.approvalState])),
)
const allTeamsDecided = computed(() => {
    return props.teams.every(team => approvalStates.value[team.id] !== undefined)
})
</script>
<template>
    <ChampionshipRegistrationTeamList :teams="teams" :show-price="false" :view-mode="props.viewMode">
        <template #secondaryRow="{ team }">
            <tr>
                <td colspan="5" class="bg-light pl-5">
                    <div class="mb-3">Bitte setze einen Status:</div>

                    <div class="pl-4">
                        <label class="d-block" v-if="approveState === 'approved'">
                            <ChampionshipRegistrationApprovalStateIcon state="approved" />
                            <input type="radio" v-model="approvalStates[team.id]" value="approved" class="ml-2" />
                            freigeben
                        </label>
                        <label class="d-block" v-if="approveState === 'waiting_list_approved'">
                            <ChampionshipRegistrationApprovalStateIcon state="waiting_list_approved" />
                            <input
                                type="radio"
                                v-model="approvalStates[team.id]"
                                value="waiting_list_approved"
                                class="ml-2"
                            />
                            Warteliste freigegeben
                        </label>
                        <label class="d-block">
                            <ChampionshipRegistrationApprovalStateIcon state="waiting_list" />
                            <input type="radio" v-model="approvalStates[team.id]" value="waiting_list" class="ml-2" />
                            Warteliste
                        </label>
                        <label class="d-block">
                            <ChampionshipRegistrationApprovalStateIcon state="declined" />
                            <input type="radio" v-model="approvalStates[team.id]" value="declined" class="ml-2" />
                            ablehnen
                        </label>
                    </div>
                </td>
            </tr>
        </template>
    </ChampionshipRegistrationTeamList>

    <slot name="bottom" v-bind="{ allTeamsDecided, approvalStates }"></slot>
</template>
