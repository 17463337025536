<script lang="ts" setup>
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import { injectMandatory, type ListState } from "@/vf"
import { type ModuleConfiguration } from "@/vf/module-configuration"
import { computed, inject, type Ref } from "vue"

const emit = defineEmits<{
    (e: "reset"): void
    (e: "selectAll"): void
}>()

const config = injectMandatory<Ref<ModuleConfiguration>>(
    "module-config",
    "VbListCustomizeFields: ModuleConfiguration is not provided",
)
const listState = inject("list-state") as ListState
const fieldGroups = computed(() => config.value.list.fieldGroups)

function groupChanged(group) {
    // for (const field of group.fields) {
    //     listState.customizeFields[group.name].fields[field.name].enabled = listState.customizeFields[group.name].enabled
    // }
}

function fieldInGroupChanged(group) {
    // listState.customizeFields[group.name].enabled =
    //     group.fields.filter(f => listState.customizeFields[group.name].fields[f.name].enabled).length > 0
}
</script>

<template>
    <div class="open-row" style="overflow: hidden">
        <div class="open-row-container">
            <div class="d-flex flex-row flex-wrap">
                <div v-for="group of fieldGroups" class="mr-5">
                    <CheckboxToggle
                        class="toggle-primary-dark"
                        v-model="listState.customizeFields[group.name].enabled"
                        @change="groupChanged(group)"
                    >
                        <b>{{ $t(group.title) }}</b>
                    </CheckboxToggle>

                    <div v-for="field in group.fields">
                        <CheckboxToggle
                            v-model="listState.customizeFields[group.name].fields[field.name].enabled"
                            @change="fieldInGroupChanged(group)"
                        >
                            {{ $t(field.title) }}
                        </CheckboxToggle>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between mt-4">
                <button class="btn btn-primary" @click="listState.resetCustomizeFields(true, true)">
                    alle auswählen
                </button>
                <button class="btn btn-primary" @click="listState.resetCustomizeFields(true)">
                    Standard zurücksetzen
                </button>
                <!--                <button class="btn btn-primary">übernehmen</button>-->
            </div>
        </div>
    </div>
</template>
