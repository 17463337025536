<script setup lang="ts">
import { useApp } from "@/vf"
//import { readXlsxFile } from "read-excel-file"
import VarsityAthleteTemplate from "@/assets/templates/VarsityAthleteTemplate.xltx?url"
import { computed, ref, toRefs } from "vue"
import { transformDate, transformGender } from "./transformers"

const { apiLink } = useApp()

const props = defineProps<{
    formDocuments: { name: string; id: string }[]
    organizations: { id: string; name: string; label: string }[]
}>()
const { formDocuments, organizations } = toRefs(props)
const inputElement = ref<HTMLInputElement | null>(null)

const displayWrongFormatError = ref(false)
const displayWrongFileError = ref(false)

const athletes = ref<
    {
        firstName: string
        lastName: string
        birthDate: string
        gender: string
        street: string
        streetNumber: string
        zip: string
        city: string
        country: string
        // minorRepresentative: string
    }[]
>([])

function verifyHeadRows(rows: string[][]) {
    if (rows.length < 2) {
        console.log("Format Error: Not enough rows")
        return false
    }
    const germanHeader = rows[0]
    const englishHeader = rows[1]
    if (germanHeader.length !== 9) {
        console.log("Format Error: Wrong number of columns")
        return false
    }
    if (germanHeader.length !== englishHeader.length) {
        console.log("Format Error: Different number of columns")
        return false
    }
    return true
}

function readRows(rows: string[][]) {
    if (!verifyHeadRows(rows)) {
        displayWrongFormatError.value = true
        return
    }

    for (let i = 2; i < rows.length; i++) {
        const row = rows[i]

        if (row.length < 9) {
            continue
        }

        if (!row[0]?.trim()?.length && !row[1]?.trim()?.length) {
            continue
        }

        athletes.value.push({
            firstName: row[0],
            lastName: row[1],
            birthDate: row[2] ? transformDate(row[2]) : "",
            gender: row[3] ? transformGender(row[3]) : "",
            street: row[4],
            streetNumber: row[5],
            zip: row[6],
            city: row[7],
            country: row[8],
            // minorRepresentative: row[9],
        })
    }
}

async function readFile() {
    displayWrongFileError.value = false
    const file = inputElement.value.files[0]
    const fileName = file.name.toLowerCase()

    if (fileName.endsWith(".csv")) {
        const { parse } = await import("papaparse")
        let fr = new FileReader()

        fr.onload = event => {
            readRows(parse(event.target.result as string).data as string[][])
        }

        fr.readAsText(file)

        return
    }

    if (fileName.endsWith(".xlsx")) {
        const { default: readXlsxFile } = await import("read-excel-file") // make sure to lazy load those dependencies
        readRows((await readXlsxFile(file)).map(row => row.map(cell => cell?.toString())))

        return
    }

    displayWrongFileError.value = true
}

const conditionIds = computed(() => {
    return formDocuments.value.join(",")
})
const selectedOrganization = ref<{ id: string; name: string; label: string }>(
    organizations.value[0] ?? {
        id: "",
        name: "",
        label: "",
    },
)
</script>

<template>
    <!--suppress HtmlUnknownTarget -->
    <a class="btn btn-secondary" :href="VarsityAthleteTemplate">
        {{ $t("@cms:onlineForm.upload.downloadTemplate") }}
    </a>
    <label class="btn btn-primary">
        {{ $t("@cms:onlineForm.upload.selectFile") }}
        <input type="file" @change="readFile" ref="inputElement" accept=".csv,.xlsx" class="d-none" />
    </label>
    <div class="alert alert-danger" v-if="displayWrongFileError">{{ $t("@cms:onlineForm.upload.wrongFileType") }}</div>
    <div class="alert alert-danger" v-if="displayWrongFormatError">
        {{ $t("@cms:onlineForm.upload.wrongFileFormat") }}
    </div>
    <template v-if="athletes.length > 0">
        <select v-model="selectedOrganization" class="form-control">
            <option v-for="organization of organizations" :value="organization">
                {{ organization.label }}
            </option>
        </select>
        <form method="post" :action="apiLink('legal/form/pdf/post-data/' + selectedOrganization.id)" target="_blank">
            <input type="hidden" name="c" :value="conditionIds" />
            <table class="table">
                <tr>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.firstName") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.lastName") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.birthDate") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.gender") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.street") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.streetNumber") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.zip") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.city") }}</th>
                    <th>{{ $t("@cms:onlineForm.bunchAdd.country") }}</th>
                    <!--                    <th>{{ $t("@cms:onlineForm.bunchAdd.minorRepresentative") }}</th>-->
                </tr>
                <tr v-for="(athlete, index) of athletes">
                    <td>
                        <input
                            type="text"
                            :name="'a[' + index + '][firstName]'"
                            v-model="athlete.firstName"
                            class="form-control"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            :name="'a[' + index + '][lastName]'"
                            v-model="athlete.lastName"
                            class="form-control"
                        />
                    </td>
                    <td>
                        <input
                            type="date"
                            :name="'a[' + index + '][birthDate]'"
                            v-model="athlete.birthDate"
                            class="form-control"
                        />
                    </td>
                    <td>
                        <select class="form-control" :name="'a[' + index + '][gender]'" v-model="athlete.gender">
                            <option value="m">{{ $t("@cms:onlineForm.bunchAdd.gender.male") }}</option>
                            <option value="f">{{ $t("@cms:onlineForm.bunchAdd.gender.female") }}</option>
                        </select>
                    </td>
                    <td>
                        <input
                            type="text"
                            :name="'a[' + index + '][street]'"
                            v-model="athlete.street"
                            class="form-control"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            :name="'a[' + index + '][streetNumber]'"
                            v-model="athlete.streetNumber"
                            class="form-control"
                        />
                    </td>
                    <td>
                        <input type="text" :name="'a[' + index + '][zip]'" v-model="athlete.zip" class="form-control" />
                    </td>
                    <td>
                        <input
                            type="text"
                            :name="'a[' + index + '][city]'"
                            v-model="athlete.city"
                            class="form-control"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            :name="'a[' + index + '][country]'"
                            v-model="athlete.country"
                            class="form-control"
                        />
                    </td>
                    <!--                    <td>-->
                    <!--                        <input-->
                    <!--                            type="text"-->
                    <!--                            :name="'a[' + index + '][minorRepresentative]'"-->
                    <!--                            v-model="athlete.minorRepresentative"-->
                    <!--                            class="form-control"-->
                    <!--                        />-->
                    <!--                    </td>-->
                    <td>
                        <a
                            :href="
                                apiLink(
                                    'legal/form/pdf/query-data/' + selectedOrganization.id + '?c=' + conditionIds,
                                    athlete,
                                )
                            "
                            target="_blank"
                        >
                            <i class="fa fa-download ml-3"></i>
                        </a>
                    </td>
                </tr>
            </table>
            <button class="btn btn-primary" type="submit">
                {{ $t("@cms:onlineForm.downloadAllAthletes") }}
            </button>
        </form>
    </template>
</template>

<style scoped>
table td {
    padding: 2px;
}

table th {
    text-align: center;
}
</style>
