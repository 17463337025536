import { useRouteQuery } from "@vueuse/router"
import { inject, provide, ref } from "vue"

export const injectSymbol = Symbol("VfHashTab")

export function useHashTab() {
    return inject<ReturnType<typeof createHashTab> | null>(injectSymbol, null)
}

export function createHashTab(name: string = "default", defaultTab?: string) {
    let queryParamName = "_tab"

    if (name !== "default") {
        queryParamName += "_" + name
    }

    const queryParam = useRouteQuery(queryParamName)
    const activeTab = ref(defaultTab)

    function selectTab(tab: string) {
        activeTab.value = tab
        queryParam.value = tab
    }

    // get tab from query params
    if (queryParam.value) {
        selectTab(queryParam.value as string)
    }

    // composable object that is provided down to child components
    const obj = {
        activeTab,
        selectTab,
    }
    provide(injectSymbol, obj)

    return obj
}
