<script setup lang="ts">
import type {LoginSearchFilter} from "@/model/app/login-search-filter"

const props = defineProps<{
    filter: LoginSearchFilter
}>()
</script>

<template>
    <button
        type="button"
        class="btn btn-primary btn-shape-rounded btn-shape-rounded-white">
        <i class="fas fa-filter"></i>
        <span>
            {{ props.filter.name }}
        </span>
    </button>
</template>
