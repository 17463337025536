<script setup lang="ts">
import VarsityContentProcessor from "@/components/VarsityContentProcessor.vue"
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import type { OrganizationMember } from "@/model/app/organization-member"
import type { User } from "@/model/app/user"
import OnlineFormFileUpload from "@/pages/Cms/Frontend/Elements/OnlineForm/OnlineFormFileUpload.vue"
import OnlineFormMultipleExistingAthletes from "@/pages/Cms/Frontend/Elements/OnlineForm/OnlineFormMultipleExistingAthletes.vue"
import OnlineFormPlainInput from "@/pages/Cms/Frontend/Elements/OnlineForm/OnlineFormPlainInput.vue"
import { useApp, useAppConfig, useHttpClient } from "@/vf"
import { ref, toRefs, watch, type Ref } from "vue"

const props = defineProps<{
    element: {
        title: string
        text: string
        documents: { id: string; name: string }[]
    }
}>()
const { element } = toRefs(props)

const { apiLink } = useApp()
const formDocuments = ref([])
const http = useHttpClient()

const selectedInputType = ref("freeEntry")
const myAthletes: Ref<{ account: OrganizationMember }[]> = ref([])
const myProfile = ref<User>(null)
const selectedAthlete: Ref<OrganizationMember | null> = ref(null)
const selectedOrganization = ref<{ id: string; name: string; label: string }>(null)
let organizations: { id: string; name: string; label: string }[] = []
const { appConfig } = useAppConfig()
if (appConfig.value.account) {
    organizations = (await http.get<{ id: string; name: string; label: string }[]>("legal/form/organizations")).data
}

watch([selectedInputType], async () => {
    if (selectedInputType.value === "myData") {
        myProfile.value = (await http.get<User>("my/profile")).data
    } else if (selectedInputType.value == "organization") {
        myAthletes.value = (
            await http.get<{ athletes: { account: OrganizationMember }[] }>("my-varsity/my-athletes/manager")
        ).data.athletes
        if (myAthletes.value.length > 0) {
            selectedAthlete.value = myAthletes.value[0].account
        }
    }
})
function prepareTextElement(html: string) {
    return html?.replace("&lt;help&gt;", "<help>")?.replace("&lt;/help&gt;", "</help>") ?? ""
}
</script>

<template>
    <div class="bg-primary text-white px-4 py-3">
        <h1>
            {{ element.title }}
        </h1>
    </div>
    <div style="background-color: #eff1f1" class="cms-admin-element-body">
        <div style="padding: 2rem">
            <p class="onlineform-text-content">
                <VarsityContentProcessor
                    :content="prepareTextElement(element.text)"
                    allow-all-tags
                ></VarsityContentProcessor>
            </p>
            <div v-for="document of element.documents">
                <a target="_blank" :href="apiLink('legal/view/' + document.id + '?disposition=attachment')">
                    <i class="fa-solid fa-paperclip"></i>
                    <u class="ml-2" style="color: black">{{ document.name }}</u>
                </a>
            </div>
            <div class="text-right">
                <a
                    target="_blank"
                    class="btn btn-primary"
                    :href="apiLink('legal/form/pdf/?ids=' + element.documents.map(i => i.id).join(','))"
                >
                    {{ $t("@cms:onlineForm.downloadAll") }}
                </a>
            </div>
        </div>

        <div class="border-top" style="padding: 2rem">
            {{ $t("@cms:onlineForm.description") }}
        </div>
        <div class="border-top" style="padding: 2rem" v-if="appConfig.account">
            <select class="form-control" v-model="selectedInputType">
                <option value="freeEntry">{{ $t("@cms:onlineForm.freeEntry") }}</option>
                <option value="myData">{{ $t("@cms:onlineForm.myData") }}</option>
                <option value="organization">{{ $t("@cms:onlineForm.organization") }}</option>
                <option value="multipleExisingAthletes" v-if="organizations?.length">
                    {{ $t("@cms:onlineForm.multipleExisingAthletes") }}
                </option>
                <option value="fileUpload">{{ $t("@cms:onlineForm.fileUpload") }}</option>
            </select>

            <div v-if="selectedInputType === 'myData' && !myProfile?.primaryDebitorProfile">
                <div class="alert alert-info mt-3">
                    <VarsityContentProcessor
                        :content="$t('@cms:onlineForm.my_data_no_debitor_profile')"
                    ></VarsityContentProcessor>
                </div>
            </div>

            <select class="form-control mt-2" v-model="selectedAthlete" v-if="selectedInputType === 'organization'">
                <option v-for="athlete of myAthletes" :value="athlete.account">{{ athlete.account.name }}</option>
            </select>

            <select
                class="form-control mt-2"
                v-model="selectedOrganization"
                v-if="selectedInputType === 'multipleExisingAthletes'"
            >
                <option v-for="organization of organizations" :value="organization">{{ organization.label }}</option>
            </select>
        </div>
        <div class="border-top" style="padding: 2rem">
            <div class="mb-3">{{ $t("@cms:onlineForm.selectDocument") }}:</div>
            <div v-for="document of element.documents">
                <label>
                    <CheckboxToggle
                        :label="document.name"
                        :value="document.id"
                        v-model="formDocuments"
                    ></CheckboxToggle>
                </label>
            </div>
        </div>
        <div class="border-top" style="background-color: #eff1f1" v-if="formDocuments.length > 0">
            <OnlineFormPlainInput
                v-if="['freeEntry', 'myData', 'organization'].includes(selectedInputType)"
                v-bind="{
                    formDocuments,
                    selectedInputType,
                    myProfile,
                    myAthletes,
                    selectedAthlete,
                    organizations,
                }"
            />
            <OnlineFormMultipleExistingAthletes
                v-if="selectedInputType === 'multipleExisingAthletes' && selectedOrganization"
                v-bind="{ selectedOrganization, formDocuments }"
            />
            <OnlineFormFileUpload v-if="selectedInputType === 'fileUpload'" v-bind="{ formDocuments, organizations }" />
        </div>
    </div>
</template>

<style scoped>
.onlineform-text-content span {
    background-color: transparent !important;
}
</style>
