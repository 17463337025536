<script setup lang="ts">
import { computed, ref } from "vue"

const props = withDefaults(
    defineProps<{
        disabled?: boolean
        disabledAccept?: boolean
        disabledClear?: boolean
    }>(),
    {
        disabled: false,
        disabledAccept: false,
        disabledClear: false,
    },
)

const emit = defineEmits<{
    (e: "selected"): void
    (e: "clear"): void
}>()

const value = ref()

function setValue(newValue: any) {
    value.value = newValue
}

const disableAccept = computed(() => props.disabledAccept || props.disabled)
const disableClear = computed(() => props.disabledAccept || props.disabled)
</script>

<template>
    <div class="d-flex flex-column">
        <slot v-bind="{ setValue }" />

        <div class="d-flex justify-content-end">
            <div class="button" @click="$emit('selected')" :class="{ disabled: disableAccept }">
                <i class="fa fa-fw fa-check"></i>
            </div>
            <div class="button" @click="$emit('clear')" :class="{ disabled: disableClear }">
                <i class="fa fa-fw fa-times"></i>
            </div>
        </div>
    </div>
</template>

<style scoped>
.button {
    cursor: pointer;
    background-color: var(--color-light);
    padding: 0.5rem;
    margin-left: 0.25rem;
    aspect-ratio: 1;
}

.button.disabled {
    opacity: 0.5;
    cursor: default;
}
</style>
