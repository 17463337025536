<script setup lang="ts">
import RotateChevron from "@/components/RotateChevron.vue"
import type { FaqTree } from "./sidebar-types"
import { useFaqSidebarStore } from "./useFaqSidebarStore"

const props = defineProps<{
    tree: FaqTree
}>()

const store = useFaqSidebarStore()

const isOpen = store.isOpen(props.tree)

function open() {
    if (props.tree.type === "group") {
        store.toggleGroup(props.tree)
        return
    }

    if (props.tree.type === "page") {
        store.selectPage(props.tree)
        return
    }
}
</script>

<template>
    <div class="accordion-enumeration-menu-item" @click="open" :class="{ active: isOpen }">
        <RotateChevron v-if="tree.type === 'group'" :open="isOpen"></RotateChevron>
        {{ tree.name }}
    </div>

    <div v-if="tree.type === 'group'" class="ml-2" v-animate-show="isOpen">
        <div v-for="child in tree.children" :key="child.id">
            <VarsityFaqSidebarMenu :tree="child"></VarsityFaqSidebarMenu>
        </div>
    </div>
</template>
