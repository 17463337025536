import { type App } from "vue"
import { createI18n } from "vue-i18n"
import { useServerSideLocales } from "../composables/useServerSideLocales"

export async function registerI18n(vueApp: App, locale: string = "de") {
    const { messages } = await useServerSideLocales()

    const i18n = createI18n({
        legacy: false,
        locale,
        messages,
        missingWarn: false,
        fallbackWarn: false,
        silentTranslationWarn: true,
        silentFallbackWarn: true,
        warnHtmlMessage: false,
    })

    vueApp.use(i18n)

    // vueApp.provide("i18n", i18n)
    // vueApp.provide("t", i18n.global.t)

    vueApp.mixin({
        methods: {
            $t: i18n.global.t,
        },
    })
    ;(window as any).i18n = i18n

    return i18n.global
}
