import { ref } from "vue"

export function useToggleableValue<T>(initialValue: T | null = null) {
    const state = ref<T | null>()
    state.value = initialValue

    function toggle(value: T) {
        state.value = state.value === value ? null : value
    }

    return [state, toggle] as const
}
