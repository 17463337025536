<script setup lang="ts">
// generated from file:///home/nnscr/code/wiezwei/varsity-booking/api/src/CMSBundle/Resources/aurelia/elements/image-group.ts
import { useApp } from "@/vf"
import { calculateStyleString } from "../../CmsPage/configuration"
import { getHref, getTarget } from "./link-service"
// props
const props = defineProps<{
    element: any
}>()

const { apiLink } = useApp()
</script>

<!-- generated from file:///home/nnscr/code/wiezwei/varsity-booking/api/src/CMSBundle/Resources/aurelia/elements/image-group.html -->
<template>
    <div class="image-group">
        <template v-for="child in element?.columns[0]">
            <a
                class="image-group-link"
                :style="
                    calculateStyleString(child) +
                    ';' +
                    (child.fileName ? 'background-image: url(' + apiLink('/media/' + child.fileName) + ')' : '')
                "
                :href="getHref(child)"
                :target="getTarget(child)"
            >
                {{ child.title }}
            </a>
        </template>
    </div>
</template>
