<script setup lang="ts">
import Flickity from "flickity"
import type { Ref } from "vue"
import { onBeforeUnmount, onMounted, ref } from "vue"
import CmsBigBanner from "./CmsBigBanner.vue"

// props
const props = defineProps<{
    element: any
}>()

// functions
function attached() {
    if (props.element?.columns?.[0]?.length > 1) {
        isSlider.value = true

        if (!containerElement.value) {
            return
        }

        flickity = new Flickity(containerElement.value, {
            wrapAround: true,
            pageDots: false,
        })
    }
}

// refs
let flickity: Flickity = null
const containerElement: Ref<HTMLDivElement> = ref(null)
const isSlider: Ref<any> = ref(false)

onMounted(() => attached())
onBeforeUnmount(() => flickity?.destroy())
</script>

<template>
    <div :class="element.config?.fullWidth ? 'w-screen' : ''">
        <div class="main-carousel" ref="containerElement">
            <CmsBigBanner v-for="slide in element?.columns[0]" :element="slide"></CmsBigBanner>
        </div>
    </div>
</template>
