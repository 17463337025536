<script setup lang="ts">
import VarsityFooter from "@/components/layout/VarsityFooter.vue"
import VarsityHeader from "@/components/layout/VarsityHeader.vue"
import { useCookieConsent } from "@/composables/useCookieConsent"
import { onMounted } from "vue"
import { RouterView, useRoute } from "vue-router"
import StagingWatermark from "./StagingWatermark.vue"
import VarsityLoadingSplash from "./components/VarsityLoadingSplash.vue"
import VarsityMobileHeader from "./components/layout/VarsityMobileHeader.vue"
import VarsityMobileSidebar from "./components/layout/VarsityMobileSidebar.vue"
import VarsityNavSearchDialog from "./components/search/VarsityNavSearchDialog.vue"
import VarsityFaqSidebar from "./pages/Faq/Sidebar/VarsityFaqSidebar.vue"
import { VfDialogContainer, useAppConfig } from "./vf"

const route = useRoute()
const { appConfig } = useAppConfig()
const { shown, openOverlay } = useCookieConsent()
onMounted(() => {
    if (!shown) {
        openOverlay()
    }
})
const isStaging = import.meta.env.VITE_ENVIRONMENT === "staging"
</script>

<template>
    <StagingWatermark v-if="isStaging" />
    <template v-if="!route.meta?.hideLayout">
        <VarsityMobileHeader />
        <VarsityHeader />
        <VarsityNavSearchDialog />
        <VarsityFaqSidebar />
        <VarsityMobileSidebar />
    </template>

    <!--  :class="route.meta.containerFluid ? 'container-fluid' : 'container'"-->
    <div
        class="flex-grow-1 d-flex flex-column"
        :class="{
            'main-container': !route.meta?.isCms && !route.meta?.wideContainer,
            'container-fluid': !route.meta?.isCms,
        }"
    >
        <RouterView v-slot="{ Component }">
            <!-- <Transition> -->
            <Suspense>
                <component :is="Component" :key="route.path" />
            </Suspense>
            <!-- </Transition> -->
        </RouterView>
    </div>

    <Suspense v-if="!route.meta?.hideLayout && (!appConfig.account || appConfig.account.type === 'User')">
        <VarsityFooter></VarsityFooter>
    </Suspense>

    <VfDialogContainer></VfDialogContainer>
    <VarsityLoadingSplash></VarsityLoadingSplash>
</template>

<style lang="scss">
@import "./styles/main.scss";

.main-container {
    max-width: 1700px;
}

.sfErrorIframe {
    position: fixed;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    z-index: 9999;
}

.sfErrorIframeClose {
    position: fixed;
    right: 5%;
    top: 5%;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: #000;
    cursor: pointer;
    z-index: 99999;
    font-size: 22px;
    text-align: center;
}
</style>
