import { useRequestTracker } from "./useRequestTracker"
import { HttpClient } from "aurelia-http-client"
import { initialize } from "aurelia-pal-browser"
import { ref } from "vue"
import { useLocale } from "@/composables/useLocale"
import { determineApiAddress } from "@/vf"

initialize()

export class BackgroundHttpClient extends HttpClient {
    public runningHttpRequests = ref(0)

    constructor() {
        super()

        // interceptor below has different this scope
        const runningHttpRequests = this.runningHttpRequests
        const { locale } = useLocale()

        this.configure(x => {
            x.withBaseUrl(`//${determineApiAddress()}/api`)
            x.withCredentials(true)
            x.withInterceptor({
                request(message) {
                    // @ts-ignore
                    if (!message.__background__) runningHttpRequests.value++
                    message.headers.add("Accept-Language", locale.value)
                    reqTracker.start(message)
                    return message
                },
                requestError(error) {
                    runningHttpRequests.value--
                    throw error
                },
                response(message) {
                    // @ts-ignore
                    if (!message.requestMessage.__background__) runningHttpRequests.value--
                    reqTracker.end(message)

                    // check if the answer is a redirect to the login page and redirect the user if that is the case
                    const headers = message.headers
                    if (headers.has("x-security-page") && headers.get("x-security-page") === "login") {
                        // todo change redirect based on the base url.
                        window.location.href = "/login"
                    }
                    //
                    // // check if the frontend rev changed.
                    // if (headers['x-frontend-rev'] && app.frontendRev && headers['x-frontend-rev'].value !== app.frontendRev) {
                    //     app.requestReload();
                    // }
                    // app.httpResponse(message);

                    return message
                },
                responseError(error) {
                    if (!error.requestMessage) {
                        console.error(error)
                        return
                    }
                    // @ts-ignore
                    if (!error.requestMessage.__background__) runningHttpRequests.value--
                    reqTracker.end(error)
                    // app.httpResponseError(error);
                    throw error
                    // noinspection UnreachableCodeJS
                    return error // to mute the error about returning nothing
                },
            })
        })
    }

    get(url, params = undefined) {
        return this.createRequest(url).asGet().withParams(params).send()
    }

    runBg(req) {
        req.transformers.push(function (client, processor, message) {
            message.__background__ = true
        })
        return req.send()
    }

    getBg(url, params = undefined) {
        return this.runBg(this.createRequest(url).asGet().withParams(params))
    }

    deleteBg(url, params = undefined) {
        return this.runBg(this.createRequest(url).asDelete().withParams(params))
    }

    postBg(url, content = null, params = null, progressCallback?: (event?: Event) => void) {
        const req = this.createRequest(url).asPost()

        if (content) {
            req.withContent(content)
        }
        if (params) {
            req.withParams(params)
        }
        if (progressCallback) {
            req.withProgressCallback(progressCallback)
        }

        return this.runBg(req)
    }

    putBg(url, content = null, params = null, progressCallback?: (event?: Event) => void) {
        const req = this.createRequest(url).asPut()

        if (content) {
            req.withContent(content)
        }
        if (params) {
            req.withParams(params)
        }
        if (progressCallback) {
            req.withProgressCallback(progressCallback)
        }

        return this.runBg(req)
    }
}

const httpClient = new BackgroundHttpClient()
export const useLegacyHttpClient = () => {
    return httpClient
}

const reqTracker = useRequestTracker()
