import { useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { computed, ref, type ComputedRef } from "vue"
import { type FaqGroup, type FaqPage, type FaqTree } from "./sidebar-types"

export const useFaqSidebarStore = defineStore("faq-sidebar", () => {
    const selectedPage = ref<FaqPage>()
    const root = ref<FaqGroup>()
    const loading = ref(true)
    const expandedGroupIds = ref<string[]>([])

    const http = useHttpClient()

    async function refresh(routeName?: string | undefined) {
        loading.value = true
        root.value = (await http.get<any>("/faq/")).data

        let faqPageId = selectedPage.value?.id

        if (routeName) {
            const routeFaqPageId = (await http.get<any>(`/faq/for-route/${routeName}`)).data.id

            if (routeFaqPageId) {
                // if a faq page is assigned to this route, show it directly
                faqPageId = routeFaqPageId
            }
        }

        if (faqPageId) {
            const faqPage = findPageInTree(faqPageId, root.value)

            // always re-select the page, even if it's the same one, to make sure we have the right reference.
            if (faqPage && faqPage.items.length) {
                selectPage(faqPage)
            }
        }

        loading.value = false
    }

    /**
     * Find a FaqPage in a FaqGroup tree and return the reference.
     */
    function findPageInTree(itemId: string, searchTree: FaqGroup): FaqPage | null {
        for (const item of searchTree.children) {
            if (item.type === "page" && item.id === itemId) {
                return item
            }

            if (item.type === "group") {
                const page = findPageInTree(itemId, item)

                if (page) {
                    return page
                }
            }
        }

        return null
    }

    /**
     * Go through the tree inside-out, finding the next parent for the given child id.
     */
    function findParent(childId: string, searchTree: FaqGroup): FaqGroup | null {
        for (const item of searchTree.children) {
            if (item.type === "group") {
                const parentId = findParent(childId, item)

                if (parentId) {
                    return parentId
                }
            }

            if (item.id === childId) {
                return searchTree
            }
        }

        return null
    }

    /**
     * Go through the tree inside-out, applying the given callback to each parent node.
     */
    function walkParents(item: FaqTree, searchTree: FaqGroup, callback: (item: FaqGroup) => void): FaqGroup | null {
        let parent = findParent(item.id, searchTree)

        while (parent) {
            callback(parent)
            parent = findParent(parent.id, searchTree)
        }

        return parent
    }

    /**
     * Expand the given group
     */
    function expandGroup(group: FaqGroup) {
        if (expandedGroupIds.value.includes(group.id)) {
            return
        }

        expandedGroupIds.value = [...expandedGroupIds.value, group.id]
    }

    /**
     * Collapse the given group
     */
    function collapseGroup(group: FaqGroup) {
        expandedGroupIds.value = expandedGroupIds.value.filter(id => id !== group.id)
    }

    /**
     * Expand or collapse the given group
     */
    function toggleGroup(group: FaqGroup) {
        if (expandedGroupIds.value.includes(group.id)) {
            collapseGroup(group)
        } else {
            expandGroup(group)
        }
    }

    /**
     * Set the selectedPage to the given page and expand all parent groups.
     */
    function selectPage(page: FaqPage) {
        walkParents(page, root.value, item => expandGroup(item))
        selectedPage.value = page
    }

    function isOpen(node: FaqTree): ComputedRef<boolean> {
        return computed(() => expandedGroupIds.value.includes(node.id) || node === selectedPage.value)
    }

    return {
        selectedPage,
        root,
        loading,
        expandedGroupIds,
        selectPage,
        refresh,
        isOpen,
        expandGroup,
        collapseGroup,
        toggleGroup,
    } as const
})

// async function goUp() {
//     selectedItem.value = findParent(selectedItem.value.id, tree.value)
// }
