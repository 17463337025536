<script setup lang="ts">
import type { ChampionshipRegistrationTeam } from "@/model/championship/championship-registration-team"
import { AnimateIf, useHttpClient, useInlineTable, useToggleableValue } from "@/vf"
import { computed, ref } from "vue"
import ChampionshipRegistrationContactPersonForm from "./ChampionshipRegistrationContactPersonForm.vue"
import ChampionshipRegistrationEditTeam from "./ChampionshipRegistrationEditTeam.vue"
import {
    validateTeamName,
    type ChampionshipRegistrationContactPerson,
    type ChampionshipRegistrationSelectTeamData,
    type ChampionshipRegistrationTeamMember,
} from "./championship-registration"

const props = defineProps<{
    data: ChampionshipRegistrationSelectTeamData
    team: ChampionshipRegistrationTeam
}>()

type EditTeamWizardOption = "contactPerson" | "category" | "members" | "teamName"
const [whatToDo, setWhatToDo] = useToggleableValue<EditTeamWizardOption>(null)
const http = useHttpClient()
const inlineTable = useInlineTable()

/*─────────────────────────────────────┐
│  contact person                      │
└─────────────────────────────────────*/
const contactPerson = ref<ChampionshipRegistrationContactPerson>({
    contactPersonName: props.team.contactPersonName,
    contactPersonPhone: props.team.contactPersonPhone,
    contactPersonMail: props.team.contactPersonMail,
})
const contactPersonValid = ref(false)
async function saveContactPerson() {
    if (!contactPersonValid.value) {
        return
    }

    await http.put(`/championship/registration/${props.data.registrationId}/team/${props.team.id}/contact-person`, {
        ...contactPerson.value,
    })
    inlineTable.close({ saved: true })
}

/*─────────────────────────────────────┐
│  edit name                           │
└─────────────────────────────────────*/
const teamName = ref<string>(props.team.teamName)
const teamNameValid = computed(() => validateTeamName(teamName.value))

async function saveTeamName() {
    if (!teamNameValid.value) {
        return
    }

    await http.put(`/championship/registration/${props.data.registrationId}/team/${props.team.id}/team-name`, {
        teamName: teamName.value,
    })
    inlineTable.close({ saved: true })
}

/*─────────────────────────────────────┐
│  edit category                       │
└─────────────────────────────────────*/
// used by both edit category and edit members
const membersSubmitting = ref(false)
const members = ref<ChampionshipRegistrationTeamMember[]>(
    props.team.members.map(member => ({
        memberId: member.memberId,
        type: member.type as ChampionshipRegistrationTeamMember["type"],
    })),
)

const categoryId = ref<string>(props.team.championshipCategory.id)
const editCategoryStep = ref<"selectCategory" | "members">("selectCategory")

function editCategoryNextStep() {
    editCategoryStep.value = "members"
}

async function saveEditCategory() {
    membersSubmitting.value = true
    try {
        await http.put(`/championship/registration/${props.data.registrationId}/team/${props.team.id}`, {
            members: members.value,
            categoryId: props.team.championshipCategory.id,
            teamName: props.team.teamName,
        })
        inlineTable.close({ saved: true })
    } finally {
        membersSubmitting.value = false
    }
}

/*─────────────────────────────────────┐
│  edit members                        │
└─────────────────────────────────────*/
async function saveMembers() {
    membersSubmitting.value = true
    try {
        await http.put(`/championship/registration/${props.data.registrationId}/team/${props.team.id}`, {
            members: members.value,
            categoryId: props.team.championshipCategory.id,
            teamName: props.team.teamName,
        })
        inlineTable.close({ saved: true })
    } finally {
        membersSubmitting.value = false
    }
}
</script>

<template>
    <div>
        <b class="mb-3">{{ $t("@tasks:tasks.championship_registration.edit_team_wizard.what_to_do") }}</b>
        <button
            class="wizard-button font-weight-normal"
            @click="setWhatToDo('contactPerson')"
            :class="{ dark: whatToDo === 'contactPerson' }"
        >
            <b>{{ $t("@tasks:tasks.championship_registration.edit_team_wizard.edit_contact_person") }}</b>
            <div>
                {{ props.team.contactPersonName }}, {{ props.team.contactPersonPhone }},
                {{ props.team.contactPersonMail }}
            </div>
        </button>
        <AnimateIf :if="whatToDo === 'contactPerson'">
            <form @submit.prevent="saveContactPerson()" class="px-2">
                <ChampionshipRegistrationContactPersonForm
                    :data="data"
                    v-model="contactPerson"
                    @validate="contactPersonValid = $event"
                />

                <button class="btn btn-primary mt-4" type="submit" :disabled="!contactPersonValid">
                    {{ $t("@tasks:tasks.championship_registration.wizard.continue") }}
                </button>
            </form>
        </AnimateIf>

        <button
            class="wizard-button font-weight-normal"
            @click="setWhatToDo('category')"
            :class="{ dark: whatToDo === 'category' }"
        >
            <b>
                {{ $t("@tasks:tasks.championship_registration.edit_team_wizard.edit_category") }}
            </b>
            <div>
                {{ props.team.championshipCategory.name }}
            </div>
        </button>
        <AnimateIf :if="whatToDo === 'category' && editCategoryStep === 'selectCategory'">
            <form @submit.prevent="editCategoryNextStep()" class="px-2">
                <div>
                    <b>
                        {{ $t("@tasks:tasks.championship_registration.wizard.please_provide_category") }}
                    </b>
                </div>

                <label for="categoryId" class="mt-3">
                    {{ $t("@tasks:tasks.championship_registration.wizard.category") }}
                </label>
                <select id="categoryId" class="form-control" v-model="categoryId">
                    <option v-for="category of data.categories" :value="category.id">
                        {{ category.name }}
                    </option>
                </select>

                <button class="btn btn-primary mt-4" type="submit">
                    {{ $t("@tasks:tasks.championship_registration.wizard.continue") }}
                </button>
            </form>
        </AnimateIf>
        <AnimateIf :if="whatToDo === 'category' && editCategoryStep === 'members'">
            <div class="px-2">
                <ChampionshipRegistrationEditTeam
                    :data="data"
                    :category-id="categoryId"
                    :submitting="membersSubmitting"
                    v-model="members"
                    @save="saveEditCategory"
                />
            </div>
        </AnimateIf>

        <button
            class="wizard-button font-weight-normal"
            @click="setWhatToDo('members')"
            :class="{ dark: whatToDo === 'members' }"
        >
            <b>
                {{ $t("@tasks:tasks.championship_registration.edit_team_wizard.edit_members") }}
            </b>
            <div>
                {{
                    $t("@tasks:tasks.championship_registration.team_list.number_of_participants", {
                        n: props.team.numberOfParticipants,
                    })
                }},
                {{
                    $t("@tasks:tasks.championship_registration.team_list.number_of_alternates", {
                        n: props.team.numberOfAlternates,
                    })
                }},
                {{
                    $t("@tasks:tasks.championship_registration.team_list.number_of_coaches", {
                        n: props.team.numberOfCoaches,
                    })
                }},
                {{
                    $t("@tasks:tasks.championship_registration.team_list.number_of_supervisors", {
                        n: props.team.numberOfSupervisors,
                    })
                }}
            </div>
        </button>
        <AnimateIf :if="whatToDo === 'members'">
            <div class="px-2">
                <ChampionshipRegistrationEditTeam
                    :data="data"
                    :category-id="props.team.championshipCategory.id"
                    :submitting="membersSubmitting"
                    v-model="members"
                    @save="saveMembers"
                />
            </div>
        </AnimateIf>

        <button
            class="wizard-button font-weight-normal"
            @click="setWhatToDo('teamName')"
            :class="{ dark: whatToDo === 'teamName' }"
        >
            <b>
                {{ $t("@tasks:tasks.championship_registration.edit_team_wizard.edit_team_name") }}
            </b>
            <div>
                {{ props.team.teamName }}
            </div>
        </button>
        <AnimateIf :if="whatToDo === 'teamName'">
            <form @submit.prevent="saveTeamName()" class="px-2">
                <div class="mb-3">
                    <b>
                        {{ $t("@tasks:tasks.championship_registration.wizard.please_enter_name") }}
                    </b>
                </div>
                <div class="my-3">
                    <b>{{ $t("@tasks:tasks.championship_registration.wizard.team_name") }}</b>
                    <input type="text" class="form-control" v-model="teamName" />
                </div>

                <button class="btn btn-primary mt-4" type="submit" :disabled="!teamNameValid">
                    {{ $t("@tasks:tasks.championship_registration.wizard.continue") }}
                </button>
            </form>
        </AnimateIf>
    </div>
</template>
