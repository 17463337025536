import { createRouter, createWebHistory } from "vue-router"
import PathNotFound from "../pages/error/PathNotFound.vue"

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [{ path: "/:pathMatch(.*)*", component: PathNotFound, name: "_404" }],
})

router.afterEach((to, from) => {
    if (typeof from.meta.bodyClass === "string") {
        document.body.classList.remove(from.meta.bodyClass)
    }

    if (typeof to.meta.bodyClass === "string") {
        document.body.classList.add(to.meta.bodyClass)
    }
})

export default router
