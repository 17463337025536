<script setup lang="ts">
import { calculateStyle } from "@/pages/Cms/CmsPage/configuration"
import { getHref, getTarget } from "./link-service"

const props = withDefaults(
    defineProps<{
        class: any
        element: any
    }>(),
    {
        class: null,
        element: null,
    },
)
</script>

<template>
    <a :href="getHref(element)" :class="props.class" :target="getTarget(element)" :style="calculateStyle(element)">
        {{ element.buttonText || element.title }}
        <slot></slot>
    </a>
</template>