<script setup lang="ts">
import { VfProvide } from "@/vf"
import { watch } from "vue"
import { useDialogsStore } from "."
import type { DialogController } from "./useDialogs"

const store = useDialogsStore()

watch(
    () => store.hasOpenDialogs,
    hasOpenDialogs => {
        document.body.classList.toggle("dialog-open", hasOpenDialogs)
    },
)

function clickBackdrop(controller: DialogController<any>) {
    if (controller.locked) {
        return
    }

    controller.cancel(undefined)
}
</script>

<template>
    <div v-if="store.hasOpenDialogs" class="dialog-container">
        <template v-for="controller of store.dialogs" :key="controller">
            <div class="dialog-backdrop" @click="clickBackdrop(controller)"></div>
            <div class="dialog-wrap">
                <div class="dialog">
                    <Suspense>
                        <template #default>
                            <VfProvide name="dialog-controller" :value="controller">
                                <component
                                    :is="controller.component"
                                    v-bind="{ controller, ...controller.props }"
                                ></component>
                            </VfProvide>
                        </template>
                        <template #fallback>
                            <slot name="fallback"></slot>
                        </template>
                    </Suspense>
                </div>
            </div>
        </template>
    </div>
</template>
