<script setup lang="ts">
import { ref, unref, watch, type Ref } from "vue"

const props = withDefaults(
    defineProps<{
        value?: any
        modelValue?: boolean | any[] | string
        inline?: boolean
        label?: any
        // used for FormKit binding
        context?: any
        disabled?: boolean
        name?: string
        type?: "checkbox" | "radio"
    }>(),
    {
        inline: false,
        disabled: false,
        type: "checkbox",
    },
)

// get the state from the props if things have changed
function determineStateByProps() {
    if (unref(props.context?.node?._value) ?? Array.isArray(props.modelValue)) {
        return (props.modelValue as any[]).includes(props.value)
    }
    if (typeof props.modelValue === "string") {
        return props.modelValue === props.value
    }
    return props.modelValue ?? false
}

const checked: Ref<boolean> = ref(determineStateByProps())

watch(
    () => props.modelValue,
    () => {
        checked.value = determineStateByProps()
    },
)

const emit = defineEmits<{
    (e: "change", value: boolean): void
    (e: "update:modelValue", value: boolean): void
}>()

function onInput(checked: boolean) {
    let newValue

    if (Array.isArray(props.modelValue)) {
        // handle array bindings
        newValue = props.modelValue.slice()

        if (checked) {
            newValue.push(props.value)
        } else {
            newValue.splice(newValue.indexOf(props.value), 1)
        }
    } else if (props.type === "radio") {
        // handle radio
        newValue = props.value
    } else {
        // handle boolean bindings
        newValue = checked
    }

    emit("update:modelValue", newValue)
    emit("change", newValue)
    props.context?.node.input(checked)
}
</script>

<template>
    <div class="checkbox" :class="{ 'd-inline-block': inline }">
        <label :class="{ 'd-inline-flex': inline }">
            <input
                @input="onInput(($event.target as HTMLInputElement).checked)"
                v-bind="{ checked, value, disabled, type, name }"
            />
            <div class="form-toggler"></div>
            <slot><span v-t="label" v-if="label"></span></slot>
        </label>
    </div>
</template>

<style lang="scss" scoped>
@import "@/styles/variables";

.checkbox {
    --checkbox-color: #{$primary};
    --checkbox-circle-color: #{darken($primary, 25%)};
}

.toggle-primary-dark {
    --checkbox-color: #{$primary-dark};
    --checkbox-circle-color: #{$primary-dark};
}

.checkbox {
    position: relative;
    margin: 0.5rem 0;

    input[type="checkbox"],
    input[type="radio"] {
        display: none; // use a .form-toggler next to the .checkbox
    }

    label {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;

        .form-toggler,
        span {
            margin: auto 0;
        }

        .form-toggler {
            background-color: #e9e9e8;
            transition: background-color 250ms;
            width: 3rem;
            height: calc(1.25rem + 8px - 2px);
            display: flex;
            padding-left: 0.1rem;
            border: 1px solid var(--checkbox-color);
            border-radius: 16px;
            margin-right: 0.5rem;
        }

        .form-toggler:before {
            content: "";
            background-color: white;
            transition: transform 250ms ease-out;
            width: 1rem;
            height: 1rem;
            margin: auto 0;
            border-radius: 50%;
            //border:           2px solid darken($primary, 25%);
            //box-shadow:       0 0 2px #fff;
            box-shadow: 0 0 0 4px var(--checkbox-circle-color), 0 0 1px 5px #fff;
        }

        input:checked + .form-toggler {
            background-color: var(--checkbox-color);
        }

        input:checked + .form-toggler:before {
            transform: translateX(1.5rem);
        }

        input:disabled + .form-toggler {
            cursor: default;
            //background: #eee;
            opacity: 0.5;
        }

        span {
            color: $input-color;
            flex-grow: 1;
            margin-left: 0.75rem;
        }
    }
}
</style>