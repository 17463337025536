import { HttpResponseMessage, RequestMessage } from "aurelia-http-client"
;(<any>window).enableRequestTracker = true

class RequestTracker {
    list = []

    start(request: RequestMessage) {
        if (!(<any>window).enableRequestTracker) return
        let shorted = request.url
        const max = 40
        if (shorted.length > max) {
            const len = 18
            shorted = shorted.substring(0, len) + "..." + shorted.substring(shorted.length - len)
        }
        this.list.push({
            request: request,
            url: request.url,
            shorted: shorted,
            method: request.method,
        })
        if (this.list.length > 11) {
            this.list.splice(0, 1)
        }
    }

    end(response: HttpResponseMessage) {
        if (!(<any>window).enableRequestTracker) return

        for (const item of this.list) {
            if (item.request === response.requestMessage) {
                const code = response.statusCode

                item.request = undefined
                item.profiler = response.headers.get("x-debug-token-link")
                item.statusCode = code
                item.statusCodeType = code - (code % 100)
                //console.debug('[' + item.method + '] "' + item.url + '" -> ' + item.statusCode + ' ' + (item.profiler ? item.profiler : '(no profiler)'))

                console.debug(
                    `[${item.method}] "${item.url}" -> ${item.statusCode} ${
                        item.profiler ? item.profiler : "(no profiler)"
                    }`,
                )

                if (item.statusCode === 500 && item.profiler) {
                    setTimeout(() => {
                        const iframe = document.createElement("iframe")
                        iframe.classList.add("sfErrorIframe")
                        iframe.src = item.profiler + "?panel=exception"
                        document.body.appendChild(iframe)
                        const close = document.createElement("div")
                        close.classList.add("sfErrorIframeClose")
                        close.innerText = "X"
                        close.addEventListener("click", function () {
                            document.body.removeChild(iframe)
                            document.body.removeChild(close)
                        })
                        document.body.appendChild(close)
                    }, 500)
                }
            }
        }
    }
}

const requestTracker = new RequestTracker()
export const useRequestTracker = () => {
    return requestTracker
}
