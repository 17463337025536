<script setup lang="ts">
import { useCookieConsent } from "@/composables/useCookieConsent"
import { useApp } from "@/vf"
import { computed } from "vue"

const props = defineProps<{
    element: any
}>()

// functions
function generateYoutubeEmbedUrl(uri: string) {
    const videoId = getYouTubeVideoIdFromURL(uri)
    if (!videoId) {
        return null
    }

    return "https://www.youtube-nocookie.com/embed/" + videoId + "?rel=0&autoplay=0"
}

function getYouTubeVideoIdFromURL(uri: string) {
    const url = new URL(uri)
    if (url.pathname === "/watch") {
        return url.searchParams.get("v")
    }
    if (url.pathname.startsWith("/v/")) {
        return url.pathname.substring(3)
    }
    if (url.hostname === "youtu.be") {
        return url.pathname.substring(1)
    }
    return null
}

const { apiLink } = useApp()
const { isAccepted, acceptSingle } = useCookieConsent()
const showActualPlayer = computed(() => isAccepted("youtube"))
</script>

<template>
    <div
        class="youtube-video-surrogate"
        :style="'background-image: url(' + apiLink('/youtube-preview-image/cms/' + element.partId)"
        v-if="!showActualPlayer"
    >
        <div class="bg-dark text-white text-center p-3" style="opacity: 0.9">
            <h3>{{ $t("@app:cookie_consent.youtube.player_title") }}</h3>
            <p>{{ $t("@app:cookie_consent.youtube.player_description") }}</p>
            <button class="btn btn-primary mt-3" @click="acceptSingle('youtube')">
                {{ $t("@app:cookie_consent.youtube.accept") }}
            </button>
        </div>
    </div>

    <!-- allow="autoplay" -->
    <iframe
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        class="border-0 w-100"
        style="aspect-ratio: 1.77"
        allowfullscreen
        :src="generateYoutubeEmbedUrl(element.config.url)"
        v-if="showActualPlayer"
    ></iframe>
</template>