<script setup lang="ts">
import { useInlineTable } from "@/vf"
import CreateNewIssueForm from "./CreateNewIssueForm.vue"

const inlineTable = useInlineTable()
</script>

<template>
    <CreateNewIssueForm @submitted="inlineTable.close({ saved: true })" force-type-of-request="topic">
        <template #buttons>
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-primary" @click="inlineTable.close">
                    {{ $t("crud:button.back") }}
                </button>
                <button type="submit" class="btn btn-primary">
                    {{ $t("@cms:contactForm.submit") }}
                </button>
            </div>
        </template>
    </CreateNewIssueForm>
</template>
