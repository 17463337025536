<script setup lang="ts">
import { useMenuBuilder, type VfMenuItem } from "@/composables/useMenuBuilder"
import { useNavbarDropdownState } from "@/stores/useNavbarDropdownState"
import { unref, type MaybeRef } from "vue"
import { RouterLink } from "vue-router"
import { default as VfDropdown, type CalculateStyleValues } from "../../vf/components/VfDropdown.vue"
import VarsityContentProcessor from "../VarsityContentProcessor.vue"

const props = defineProps<{
    items: VfMenuItem[]
}>()

const state = useNavbarDropdownState()
const { getFirstChildRoutingData } = useMenuBuilder()

/*─────────────────────────────────────┐
│  external url                        │
└─────────────────────────────────────*/
function getExternalUrl(itemRef: MaybeRef<VfMenuItem>) {
    const item = unref(itemRef)

    if (!item) {
        return null
    }

    if (item.settings.externalUrl) {
        return item.settings.externalUrl
    }

    return item.children?.[0]?.settings.externalUrl ?? null
}

function calculateDropdownStyle(values: CalculateStyleValues) {
    values.style.left = "auto"
}

function calculate3rdLevelDropdownStyle(values: CalculateStyleValues) {
    values.style.left = values.buttonRect.left.value + values.buttonRect.width.value + "px"
    values.style.top = values.buttonRect.top.value + "px"
}

function hasValidChildren(item: VfMenuItem) {
    return item.children?.some(child => child.label) ?? false
}
</script>
<template>
    <template v-for="item in props.items">
        <li
            class="nav-item"
            :class="{ active: item.hasActiveChildren, dropdown: item.children?.length > 1 }"
            v-if="!item.settings.hideInRootNavigation"
        >
            <!-- first level (white bar in the header) -->
            <VfDropdown
                menu-class="navbar-submenu"
                container-class="d-flex flex-column align-items-center h-100"
                button-class="flex-grow-1 d-flex align-items-center"
                @closed="state.submenuOnClose(1, item)"
                @calculate-style="values => calculateDropdownStyle(values)"
            >
                <template #default="{ openDropdown: openDropdown1, closeDropdown: closeDropdown1, isOpen }">
                    <a v-if="getExternalUrl(item)" :href="getExternalUrl(item)" class="nav-link">
                        <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                    </a>
                    <RouterLink
                        v-else
                        :to="getFirstChildRoutingData(item)"
                        class="nav-link"
                        @mouseover="state.hover(1, item, openDropdown1, closeDropdown1)"
                        @mouseleave="state.leave(1, item)"
                    >
                        <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                        <i class="fa fa-fw fa-chevron-down small ml-1 dropdown-icon" :class="{ 'is-open': isOpen }"></i>
                    </RouterLink>
                </template>
                <template #menu="{ closeDropdown: closeDropdown1 }">
                    <template v-for="child of item.children">
                        <!-- second level (dropdown that opens down) -->
                        <VfDropdown
                            menu-class="navbar-submenu"
                            @calculate-style="values => calculate3rdLevelDropdownStyle(values)"
                        >
                            <template #default="{ openDropdown: openDropdown2, closeDropdown: closeDropdown2, isOpen }">
                                <RouterLink
                                    :to="getFirstChildRoutingData(child)"
                                    class="navbar-submenu-item"
                                    :class="{ active: child.hasActiveChildren }"
                                    @click="closeDropdown1()"
                                    @mouseover="
                                        state.hover(1, item), state.hover(2, child, openDropdown2, closeDropdown2)
                                    "
                                    @mouseleave="state.leave(1, item), state.leave(2, child)"
                                    v-if="child.label"
                                >
                                    <VarsityContentProcessor :content="$t(child.label)"></VarsityContentProcessor>
                                    <i
                                        v-if="hasValidChildren(child)"
                                        class="fa fa-fw fa-chevron-down small ml-1 dropdown-icon"
                                        :class="{ 'is-open': isOpen }"
                                    ></i>
                                </RouterLink>
                            </template>
                            <template #menu>
                                <!-- third level (dropdown that opens to the right) -->
                                <template v-for="grandChild of child.children">
                                    <RouterLink
                                        :to="getFirstChildRoutingData(grandChild)"
                                        class="navbar-submenu-item"
                                        :class="{ active: grandChild.hasActiveChildren }"
                                        @click="closeDropdown1()"
                                        @mouseover="state.hover(1, item), state.hover(2, child)"
                                        @mouseleave="state.leave(1, item), state.leave(2, child)"
                                        v-if="grandChild.label"
                                    >
                                        <VarsityContentProcessor
                                            :content="$t(grandChild.label)"
                                        ></VarsityContentProcessor>
                                    </RouterLink>
                                </template>
                            </template>
                        </VfDropdown>
                    </template>
                </template>
            </VfDropdown>
        </li>
    </template>
</template>
