<script setup lang="ts">
import { useWindowScroll, useWindowSize } from "@vueuse/core"
import { computed } from "vue"
import CmsFrontend from "../../pages/Cms/Frontend/frontend.vue"

const year = new Date().getFullYear()

const { x, y } = useWindowScroll()
const { width, height } = useWindowSize()
const showScrollToTopButton = computed(() => y.value > height.value)
function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
}
</script>

<template>
    <a
        href="#"
        class="btn-to-top btn btn-primary btn-shape-rounded btn-shape-rounded-white d-sm-none"
        v-show="showScrollToTopButton"
        @click.prevent="scrollToTop"
    >
        <i class="fas fa-chevron-up"></i>
    </a>
    <div class="vb-footer">
        <div class="vb-footer-top">
            <div class="container-fluid">
                <CmsFrontend path="/page-footer"></CmsFrontend>
            </div>
        </div>
        <div class="vb-footer-bottom">
            <img src="/assets/varsity-europe.png" width="241" height="69" alt="Varsity Europe" />

            <div class="mt-4">&copy; {{ year }} Varsity Europe. All rights reserved.</div>
        </div>
    </div>
</template>

<style lang="scss">
@import "@/styles/variables";

body.no-footer .vb-footer {
    display: none;
}

.vb-footer {
    border-top: 0.5rem solid theme-color("primary");

    .vb-footer-top {
        background-color: #ebebeb;
        padding: 3rem 0;

        h3 {
            text-transform: uppercase;
            color: theme-color("primary");
            margin-bottom: 2rem;
        }

        .fa-ul {
            li {
                color: theme-color("primary");
                margin-bottom: 1rem;
            }

            a {
                color: $body-color;
            }
        }
    }

    .vb-footer-bottom {
        background-color: theme-color("primary");
        color: #fdfdfd;
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .vb-footer-contact p {
        margin-left: 2rem;

        i {
            margin-left: -2rem;
            margin-right: 0.5rem;
            width: 1.5rem;
            height: 1.5rem;
            color: #fdfdfd;
            background-color: theme-color("primary");
            display: inline-grid;
            place-items: center;
            border-radius: 50%;
            font-size: 0.9rem;
        }
    }
}
.btn-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 1;
    opacity: 0.75;
    &:hover {
        opacity: 1;
    }
}
</style>
