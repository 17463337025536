<script setup lang="ts">
import type { ChampionshipRegistrationTeamApprovalState } from "@/model/championship/championship-registration-team-approval-state"

const props = defineProps<{ state: ChampionshipRegistrationTeamApprovalState }>()
</script>
<template>
    <i class="fa fa-fw fa-circle-check text-success" v-if="props.state === 'approved'"></i>
    <i class="fa fa-fw fa-spinner text-warning" v-if="props.state === 'waiting_list'"></i>
    <i class="fa fa-fw fa-circle-xmark text-danger" v-if="props.state === 'declined'"></i>
    <i class="fa fa-fw fa-question-circle text-warning" v-if="props.state === 'waiting_list_approved'"></i>
</template>
