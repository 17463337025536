<script setup lang="ts">
import VarsityInlineConfirm from "@/components/VarsityInlineConfirm.vue"
import VarsityInlineConfirmButton from "@/components/VarsityInlineConfirmButton.vue"
import { createInlineConfirm } from "@/composables/createInlineConfirm"
import { useHttpClient } from "@/vf"

const props = defineProps<{
    registrationId: string
}>()

const emit = defineEmits<{
    (e: "resetted"): void
}>()

const http = useHttpClient()
const confirm = createInlineConfirm({
    action() {
        return http.post(`/championship/registration/${props.registrationId}/reset`)
    },
    afterAction() {
        emit("resetted")
    },
})
</script>
<template>
    <VarsityInlineConfirmButton :controller="confirm">
        {{ $t("@tasks:tasks.championship_registration.wizard.reset_registration.button") }}
    </VarsityInlineConfirmButton>

    <VarsityInlineConfirm :controller="confirm">
        <template #confirmation>
            {{ $t("@tasks:tasks.championship_registration.wizard.reset_registration.confirmation") }}
        </template>
        <template #success>
            {{ $t("@tasks:tasks.championship_registration.wizard.reset_registration.success") }}
        </template>
    </VarsityInlineConfirm>
</template>
