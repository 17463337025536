<script lang="ts" setup>
import { useHttpClient } from "@/vf"

const props = withDefaults(
    defineProps<{
        conditionId?: string
        versionId?: string
        configuredCondition?: string // e.g. General.User
        textFactor?: number
        showForm?: boolean
        singleLanguage?: boolean
    }>(),
    {
        singleLanguage: false,
    },
)
const path = props.conditionId
    ? "legal/cms/id/" + props.conditionId + (props.singleLanguage ? "/localized" : "")
    : props.versionId
    ? "legal/cms/version/" + props.versionId
    : "legal/cms/condition/" + props.configuredCondition
const params: { showForm?: boolean } = {}
if (props.showForm) {
    params.showForm = true
}
const data = (
    await useHttpClient().get<{
        parts: {
            id: string
            type: "title" | "text" | "box" | "space" | "input" | "radio" | "checkbox" | "signature" | "date"
            title?: string
            html?: string
            left?: {
                title: string
                html: string
            }
            right?: {
                title: string
                html: string
            }
            space: number
            size: number
            config: any
        }[]
        name?: string
        left?: {
            name: string
        }
        right?: {
            name: string
        }
        version: {
            date: string
            number: string
        }
    }>(path)
).data
const textFactor = !props.textFactor ? 1 : props.textFactor

// const formData = reactive({})
// const formErrors = reactive({})
</script>

<template>
    <div class="d-flex justify-content-between mb-3" v-if="!singleLanguage">
        <div>
            {{ data.left.name }}
            <br />
            {{ data.version.date }} / Version {{ data.version.number }}
        </div>
        <div class="text-right">
            {{ data.right.name }}
            <br />
            {{ data.version.date }} / Version {{ data.version.number }}
        </div>
    </div>
    <div v-else>
        <div>
            {{ data.name }}
            <br />
            {{ data.version.date }} / Version {{ data.version.number }}
        </div>
    </div>
    <template v-for="part of data.parts">
        <div class="d-flex justify-content-between text-justify" style="gap: 3rem" v-if="!singleLanguage">
            <div style="flex-basis: 50%" v-for="side of ['left', 'right']">
                <h2 v-if="part.type == 'title'" :style="{ fontSize: part.size * textFactor + 'px' }">
                    {{ part[side].title }}
                </h2>
                <div v-if="part.type == 'text'" :style="{ fontSize: part.size * textFactor + 'px' }">
                    <div v-for="html of part[side].html" v-html="html"></div>
                </div>
                <div v-if="part.type == 'box'" class="border-dark" :style="{ fontSize: part.size * textFactor + 'px' }">
                    <div v-for="html of part[side].html" v-html="html"></div>
                </div>
                <div v-if="part.type == 'space'" :style="{ height: part.space * 2 + 'px' }"></div>
                <!--                <div v-if="['input', 'radio', 'checkbox', 'date', 'signature'].includes(part.type)">-->
                <!--                    <label-->
                <!--                        class="font-weight-bold"-->
                <!--                        v-for="html of part[side].html"-->
                <!--                        v-html="html"-->
                <!--                        :for="part.id"-->
                <!--                    ></label>-->
                <!--                </div>-->
            </div>
        </div>
        <div v-else>
            <h2 v-if="part.type == 'title'" :style="{ fontSize: part.size * textFactor + 'px' }">
                {{ part.title }}
            </h2>
            <div v-if="part.type == 'text'" :style="{ fontSize: part.size * textFactor + 'px' }">
                <div v-for="html of part.html" v-html="html"></div>
            </div>
            <div v-if="part.type == 'box'" class="border-dark" :style="{ fontSize: part.size * textFactor + 'px' }">
                <div v-for="html of part.html" v-html="html"></div>
            </div>
            <div v-if="part.type == 'space'" :style="{ height: part.space * 2 + 'px' }"></div>
        </div>

        <!--        <template v-if="part.type === 'input'">-->
        <!--            <div v-for="(line, $index) in part.config.lines">-->
        <!--                <input-->
        <!--                    :type="part.config.mapping?.includes('+') || part.config.mapping === 'country' ? 'hidden' : 'text'"-->
        <!--                    class="form-control my-1"-->
        <!--                    :maxlength="line.numChars"-->
        <!--                    :name="'part[' + part.id + '][' + $index + ']'"-->
        <!--                    v-model="formData[part.config.mapping ?? part.text + '_' + $index]"-->
        <!--                    :required="!!part.config.required"-->
        <!--                />-->
        <!--                <div class="alert alert-danger mb-1" v-if="(formErrors?.[part.config.mapping] ?? []).length > 0">-->
        <!--                    <div v-for="err in formErrors?.[part.config.mapping]">{{ err }}</div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </template>-->
        <!--        <div class="my-3" v-if="part.type === 'radio'">-->
        <!--            <label v-for="line in part.choices" class="d-block">-->
        <!--                <input-->
        <!--                    :id="part.id"-->
        <!--                    type="radio"-->
        <!--                    :value="line"-->
        <!--                    v-model="formData[part.config.mapping ?? part.text]"-->
        <!--                    :name="'part[' + part.id + ']'"-->
        <!--                />-->
        <!--                {{ line }}-->
        <!--            </label>-->
        <!--        </div>-->
        <!--        <div class="my-3" v-if="part.type === 'checkbox'">-->
        <!--            <input-->
        <!--                :id="part.id"-->
        <!--                type="checkbox"-->
        <!--                :name="'part[' + part.id + ']'"-->
        <!--                v-model="formData[part.text]"-->
        <!--                value="1"-->
        <!--            />-->
        <!--        </div>-->
        <!--        <div class="my-3" v-if="part.type === 'date'">-->
        <!--            <input-->
        <!--                :id="part.id"-->
        <!--                type="date"-->
        <!--                :name="'part[' + part.id + ']'"-->
        <!--                v-model="formData[part.config.mapping ?? part.text]"-->
        <!--                class="form-control"-->
        <!--            />-->
        <!--        </div>-->
        <!--        <div class="my-3" v-if="part.type === 'signature'">-->
        <!--            <LegalFormSignature :form-element="part" />-->
        <!--        </div>-->
    </template>
</template>

<style scoped>
:deep(p) {
    margin-bottom: 0;
}
</style>
