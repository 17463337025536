<script setup lang="ts">
import { useApp } from "@/vf"
import { calculateStyle } from "../../CmsPage/configuration"
import { getHref, getTarget } from "./link-service"
// props
const props = defineProps<{
    app?: any
    element: any
}>()

const { apiLink } = useApp()
</script>

<template>
    <template v-if="(element.overlayHeadline || element.overlayText) && element.config.buttonLink">
        <a :href="getHref(element)" :target="getTarget(element)" class="img-overlay">
            <img
                alt=""
                class="img-fluid img-overlay__image"
                :src="apiLink('/media/' + element.fileName)"
                :style="calculateStyle(element)"
            />
            <div class="img-overlay__overlay">
                <p class="img-overlay__headline">{{ element.overlayHeadline }}</p>
                <p class="img-overlay__text">{{ element.overlayText }}</p>
            </div>
        </a>
    </template>
    <template v-else-if="element.overlayHeadline || element.overlayText">
        <div class="img-overlay">
            <img
                alt=""
                class="img-fluid img-overlay__image"
                :src="apiLink('/media/' + element.fileName)"
                :style="calculateStyle(element)"
            />
            <div class="img-overlay__overlay">
                <p class="img-overlay__headline">{{ element.overlayHeadline }}</p>
                <p class="img-overlay__text">{{ element.overlayText }}</p>
            </div>
        </div>
    </template>
    <template v-else-if="element.config.buttonLink">
        <a :href="getHref(element)" :target="getTarget(element)">
            <img
                alt=""
                class="img-fluid"
                :src="apiLink('/media/' + element.fileName)"
                :style="calculateStyle(element)"
            />
        </a>
    </template>
    <template v-else>
        <img alt="" class="img-fluid" :src="apiLink('/media/' + element.fileName)" :style="calculateStyle(element)" />
    </template>
</template>
<style lang="scss">
@import "@/styles/variables.scss";
.img-overlay {
    position: relative;
    z-index: 1;
    &__overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        color: #ffffff;
        vertical-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__headline {
        text-transform: uppercase;
        font-style: italic;
        font-weight: bold;
        font-size: 4em;
        margin: -0.25em 0 0 0;
    }
    &__text {
        font-size: 1.5em;
        margin: 0;
    }
    /* &__image { */
    /* } */
}
</style>
