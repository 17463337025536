<script setup lang="ts">
import VarsityContentProcessor from "@/components/VarsityContentProcessor.vue"
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import CreateNewIssueForm from "@/pages/Tasks/Customer/CreateNewIssueForm.vue"
import { useApp, useAppConfig } from "@/vf"
import { ref } from "vue"
import { calculateStyle } from "../../CmsPage/configuration"
// props
const props = defineProps<{
    element: any
}>()
const { appConfig } = useAppConfig()
const { apiLink } = useApp()
const acceptTerms = ref(false)
const sent = ref(false)

function checkSubmit(): boolean {
    return appConfig.value.login || acceptTerms.value
}
</script>

<template>
    <div :style="calculateStyle(element)" style="overflow: hidden; background-color: #eff1f1">
        <div class="bg-primary text-white px-4 py-3">
            <h1>
                {{ element.title }}
            </h1>
        </div>

        <template v-if="!sent">
            <div v-html="element.text" class="mx-4 px-2 mt-5" v-if="element.text"></div>

            <CreateNewIssueForm
                :topic-group="element.config.contactTopicGroup"
                :check-submit="checkSubmit"
                :force-type-of-request="element.config.contactForceTypeTopic ? 'topic' : undefined"
                @submitted="sent = true"
                form-class="cms-admin-element-body"
            >
                <template #buttons>
                    <div class="checkbox" v-if="!appConfig.login">
                        <CheckboxToggle v-model="acceptTerms">
                            <VarsityContentProcessor
                                :content="
                                    $t('@cms:contactForm.acceptTerms', {
                                        link: apiLink('/legal/show/DataPrivacy/pdf'),
                                    })
                                "
                                allow-all-tags
                            ></VarsityContentProcessor>
                        </CheckboxToggle>
                    </div>
                    <div class="text-right d-block">
                        <button type="submit" class="btn btn-primary" :disabled="!appConfig.login && !acceptTerms">
                            {{ $t("@cms:contactForm.submit") }}
                        </button>
                    </div>
                </template>
            </CreateNewIssueForm>
        </template>
        <div class="cms-admin-element-body" style="background-color: rgb(239, 241, 241)" v-if="sent">
            <div class="py-5 text-center">
                {{ $t("@cms:contactForm.success") }}
            </div>
        </div>
    </div>
</template>
