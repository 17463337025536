import type { App } from "vue"

function showSpinner(el: HTMLElement) {
    if (el.classList.contains("wait-loading-parent")) {
        return
    }

    const child = document.createElement("div")
    child.classList.add("wait-loading")

    const spin = document.createElement("i")
    spin.classList.add("fa", "fa-spinner", "fa-spin")
    child.appendChild(spin)
    el.appendChild(child)
    el.classList.add("wait-loading-parent")
    ;(<HTMLButtonElement>el).disabled = true

    return child
}

function hideSpinner(el: HTMLElement) {
    const child = el.querySelector(".wait-loading")

    if (child) {
        try {
            el.removeChild(child)
        } catch (e) {
            // ignore
        }
    }

    el.classList.remove("wait-loading-parent")
    ;(<HTMLButtonElement>el).disabled = false
}

export function registerSpinnerDirective(vueApp: App) {
    vueApp.directive("spinner", (el, binding) => {
        const value = binding.value

        if (value instanceof Promise) {
            showSpinner(el)

            value.finally(() => hideSpinner(el))
            return
        }

        if (value) {
            hideSpinner(el)
        } else {
            showSpinner(el)
        }
    })
}
