import { useLocale } from "@/composables/useLocale"
import { useAppConfig, useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { computed, ref, watchEffect } from "vue"
import { useI18n } from "vue-i18n"

export const useLocaleChooser = defineStore("locale-chooser", () => {
    // fake a language to force i18n to show the language keys instead of the translation
    const fakeLocale = "LANGUAGE_KEYS"
    const currentLocale = ref("de")
    const http = useHttpClient()
    const i18n = useI18n()
    const { appConfig } = useAppConfig()
    const { changeLocale } = useLocale()

    const showFakeLocale = computed(() => {
        for (const route of appConfig.value.routes) {
            if (route.name === "@Translation.Content.system") {
                return true
            }
        }

        return false
    })

    watchEffect(() => {
        const locale = i18n.locale.value
        currentLocale.value = locale == fakeLocale ? locale : locale.substring(0, 2)
    })

    async function switchLocale(newLocale: string, fallbackLocale: string) {
        i18n.fallbackLocale.value = fallbackLocale
        i18n.locale.value = newLocale
        changeLocale(newLocale)

        if (newLocale != fakeLocale) {
            console.log("chaning to locale", newLocale)
            try {
                await http.postBg("my/locale", { locale: newLocale })
            } catch (e) {
                console.error(e)
            }
            location.reload()
        }

        return false
    }

    return {
        switchLocale,
        showFakeLocale,
        currentLocale,
        fakeLocale,
    }
})
