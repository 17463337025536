import type { App } from "vue"

export function registerFocus(vueApp: App) {
    vueApp.directive("focus", {
        mounted: (el: HTMLElement, binding) => {
            if (binding.value === false) {
                return
            }

            el.focus()
        },
    })
}
