<script lang="ts">
import VarsityHelpBubble from "@/components/VarsityHelpBubble.vue"
import { defineComponent, h, type VNode, type VNodeArrayChildren } from "vue"

// not exported from vue
declare type RawChildren = string | number | boolean | VNode | VNodeArrayChildren | (() => any)

export default defineComponent({
    props: {
        content: String,
        allowAllTags: Boolean,
    },
    setup: props => {
        const allowedElementsWithChildren = ["b", "strong", "p", "em", "s", "u", "ol", "ul", "li", "a"]
        const allowedElementsWithoutChildren = ["br"]
        const allowedComponents = { help: VarsityHelpBubble }
        const allowedAttributes = ["class", "id", "href", "target", "rel", "title"]

        /**
         * Takes attributes from the original element and filters them for allowed attributes to be used
         * in the new vnode element.
         */
        function processProps(attributes: NamedNodeMap): Record<string, any> {
            const props: Record<string, any> = {}
            for (let i = 0; i < attributes.length; i++) {
                const attr = attributes[i]

                if (!allowedAttributes.includes(attr.name.toLowerCase())) {
                    continue
                }

                props[attr.name] = attr.value
            }

            return props
        }

        function processNodes(list: NodeListOf<ChildNode>): (string | VNode)[] {
            const output = []

            list.forEach(node => {
                const vnode = processNode(node)

                if (vnode) {
                    output.push(vnode)
                }
            })

            return output
        }

        function processNode(node: Node) {
            if (node.nodeType === node.TEXT_NODE) {
                return [node.textContent]
            }

            if (node.nodeType !== node.ELEMENT_NODE) {
                return null
            }

            const el = node as Element
            const output: RawChildren[] = []

            const tagName = el.tagName.toLowerCase()

            if (Object.prototype.hasOwnProperty.call(allowedComponents, tagName)) {
                output.push(
                    h(allowedComponents[tagName], null, {
                        default: () => processNodes(el.childNodes),
                    }),
                )
            } else if (allowedElementsWithoutChildren.includes(tagName)) {
                output.push(h(tagName, processProps(el.attributes)))
            } else if (allowedElementsWithChildren.includes(tagName)) {
                output.push(h(tagName, processProps(el.attributes), processNodes(el.childNodes)))
            } else if (props.allowAllTags) {
                const attrs = {}

                if (props.allowAllTags) {
                    for (let i = 0; i < (node as Element).attributes.length; ++i) {
                        const attr = (node as Element).attributes.item(i)
                        attrs[attr.name] = attr.value
                    }
                }

                output.push(h(tagName, attrs, processNodes(el.childNodes)))
            } else {
                // forbidden element, convert tag to text and keep processed content
                const contentOffset = el.outerHTML.indexOf(el.innerHTML)
                const contentLength = el.innerHTML.length

                // start tag
                output.push(el.outerHTML.substring(0, contentOffset))

                // content nodes
                output.push(...processNodes(el.childNodes))

                // end tag
                output.push(el.outerHTML.substring(contentOffset + contentLength))
            }

            return output
        }

        return () => {
            const container = document.createElement("div")
            container.innerHTML = props.content

            return processNodes(container.childNodes)
        }
    },
})
</script>
