import type { App } from "vue"

export function registerScroll(vueApp: App) {
    // v-scroll
    vueApp.directive("scroll", {
        mounted: (el: HTMLElement, binding) => {
            if (binding.value === false) {
                return
            }

            el.scrollIntoView({ behavior: "smooth" })
        },
    })
}
