<script setup lang="ts">
import type { OrganizationMember } from "@/model/app/organization-member"
import type { OrganizationTeam } from "@/model/app/organization-team"
import { useHttpClient } from "@/vf"
import { computed, reactive, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import type {
    ChampionshipRegistrationSelectTeamData,
    ChampionshipRegistrationTeamMember,
    ChampionshipRegistrationTeamValidationResult,
} from "./championship-registration"

const props = defineProps<{
    data: ChampionshipRegistrationSelectTeamData
    /** The category id the team is registered for (used for validation) */
    categoryId: string
    modelValue: ChampionshipRegistrationTeamMember[]
    /** Is a submit running? */
    submitting: boolean
}>()

const emit = defineEmits<{
    (e: "save"): void
    (e: "update:modelValue", value: ChampionshipRegistrationTeamMember[]): void
}>()

const http = useHttpClient()
const { t } = useI18n()
const selectedMembers = ref<{
    participant: string[]
    coach: string[]
    supervisor: string[]
    alternate: string[]
}>({ participant: [], coach: [], supervisor: [], alternate: [] })
const errorMessage = ref("")
const columns = reactive([
    ["participant", t("@tasks:tasks.championship_registration.team_list.participants")],
    ["alternate", t("@tasks:tasks.championship_registration.team_list.alternates")],
    ["coach", t("@tasks:tasks.championship_registration.team_list.coaches")],
    ["supervisor", t("@tasks:tasks.championship_registration.team_list.supervisors")],
])

/*─────────────────────────────────────┐
│  sync modelValue to internal value   │
└─────────────────────────────────────*/
// load team from props for edit
if (props.modelValue) {
    selectedMembers.value = {
        participant: props.modelValue.filter(x => x.type === "participant").map(x => x.memberId),
        coach: props.modelValue.filter(x => x.type === "coach").map(x => x.memberId),
        supervisor: props.modelValue.filter(x => x.type === "supervisor").map(x => x.memberId),
        alternate: props.modelValue.filter(x => x.type === "alternate").map(x => x.memberId),
    }
}

const selectedMembersFlat = computed(() => [
    ...selectedMembers.value.participant.map(
        x => ({ memberId: x, type: "participant" } as ChampionshipRegistrationTeamMember),
    ),
    ...selectedMembers.value.coach.map(x => ({ memberId: x, type: "coach" } as ChampionshipRegistrationTeamMember)),
    ...selectedMembers.value.supervisor.map(
        x => ({ memberId: x, type: "supervisor" } as ChampionshipRegistrationTeamMember),
    ),
    ...selectedMembers.value.alternate.map(
        x => ({ memberId: x, type: "alternate" } as ChampionshipRegistrationTeamMember),
    ),
])

watch(selectedMembersFlat, () => {
    emit("update:modelValue", selectedMembersFlat.value)
})

/*─────────────────────────────────────┐
│  submit                              │
└─────────────────────────────────────*/
async function submit() {
    emit("save")
    return
}

/*─────────────────────────────────────┐
│  validation / blockers               │
└─────────────────────────────────────*/
const validationResult = ref<ChampionshipRegistrationTeamValidationResult | null>(null)

/** If a member has *any* blockers it is no longer a valid starter or alternate */
function isValidStarter(member: OrganizationMember) {
    return (memberBlockers.value[member.id]?.length ?? 0) === 0
}

/** Everyone is a valid supervisor or coach, except their framework agreement ist not approved */
function isValidSupervisorOrCoach(member: OrganizationMember) {
    return !memberBlockers.value[member.id]?.includes("not_reviewed")
}

function selectionChanged(column: string, member: OrganizationMember) {
    const isSelected = selectedMembers.value[column].includes(member.id)
    if (isSelected) {
        for (const otherColumn of columns) {
            if (otherColumn[0] !== column) {
                selectedMembers.value[otherColumn[0]] = selectedMembers.value[otherColumn[0]].filter(
                    (x: string) => x !== member.id,
                )
            }
        }
    }
    validate()
}

async function validate() {
    const result = await http.post<ChampionshipRegistrationTeamValidationResult>(
        `/championship/registration/${props.data.registrationId}/select-team/validate`,
        {
            members: selectedMembersFlat.value,
            categoryId: props.categoryId,
        },
    )
    validationResult.value = result.data
    console.log("validation result", validationResult.value)
}

// contains all blockers, keyed by member id
const memberBlockers = computed(() => {
    const blockers: Record<string, string[]> = {}

    if (!validationResult.value) {
        return blockers
    }

    for (const item of validationResult.value.availableMembers ?? []) {
        blockers[item.organizationMemberId] = item.blockers
    }

    return blockers
})

// contains blockers for the whole team
const teamBlockers = computed(() => validationResult.value?.team ?? null)
const validationOk = computed(() => validationResult.value?.ok ?? false)

watch(selectedMembers, () => {
    validate()
})

if (props.modelValue) {
    validate()
}

/*─────────────────────────────────────┐
│  Select saved team                   │
└─────────────────────────────────────*/
const selectedTeam = ref<OrganizationTeam | null>(null)

function applySavedTeam() {
    selectedMembers.value = {
        participant: selectedTeam.value.participants.filter(m => isValidStarter(m)).map(m => m.id),
        coach: selectedTeam.value.coaches.filter(m => isValidSupervisorOrCoach(m)).map(m => m.id),
        supervisor: selectedTeam.value.supervisors.filter(m => isValidSupervisorOrCoach(m)).map(m => m.id),
        alternate: selectedTeam.value.alternates.filter(m => m.type === "alternate").map(m => m.id),
    }
}

/*─────────────────────────────────────┐
│  search                              │
└─────────────────────────────────────*/
const searchValue = ref("")

const filteredMembers = computed(() => {
    return props.data.availableMembers
        .filter(m => {
            const firstLast = `${m.firstName} ${m.lastName}`.toLowerCase()
            const lastFirst = `${m.lastName} ${m.firstName}`.toLowerCase()

            return (
                firstLast.includes(searchValue.value.toLowerCase()) ||
                lastFirst.includes(searchValue.value.toLowerCase())
            )
        })
        .sort((a, b) => {
            const aBlockers = validationResult.value.availableMembers.find(
                x => x.organizationMemberId === a.id,
            )?.blockers
            const bBlockers = validationResult.value.availableMembers.find(
                x => x.organizationMemberId === b.id,
            )?.blockers

            // sort members without blockers to the top of the list and members WITH blockers to the end
            if (!aBlockers && bBlockers) {
                return -1
            }

            if (aBlockers && !bBlockers) {
                return 1
            }

            // if both have blockers or both don't have blockers, sort by name
            const aFullName = `${a.firstName} ${a.lastName}`
            const bFullName = `${b.firstName} ${b.lastName}`

            return aFullName.localeCompare(bFullName)
        })
})
</script>

<template>
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <div class="py-2 px-3 d-flex align-items-center text-white bg-dark">
        <div class="d-flex align-items-center">
            <div>Mitglieder</div>
            <div class="form-inline mx-2">
                <select class="form-control form-inline" v-model="selectedTeam">
                    <option disabled selected>Team</option>
                    <option :value="team" v-for="team in data.availableTeams">{{ team.name }}</option>
                </select>
            </div>
            <button class="btn btn-primary" type="button" @click="applySavedTeam()" :disabled="!selectedTeam">
                {{ $t("@championship:registration.window.edit_team.applyFilter") }}
            </button>
        </div>
        <div class="flex-grow-1">
            <div class="search">
                <div class="d-flex">
                    <div class="search-icon">
                        <i class="fas fa-fw fa-search"></i>
                    </div>
                    <input
                        v-model="searchValue"
                        type="text"
                        class="form-inline search-field flex-grow-1"
                        :placeholder="$t('crud:list.search')"
                    />
                </div>
            </div>
        </div>
        <div class="d-flex flex-column">
            <button class="btn btn-primary mt-3" :disabled="!validationOk || submitting" @click="submit()">
                Jetzt anmelden
            </button>
            <div v-if="teamBlockers">
                <ul>
                    <li v-for="m in teamBlockers">
                        {{ $t("@championship:registration.blocker." + m) }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <table class="table">
        <thead>
            <tr>
                <th></th>
                <th v-for="column in columns">{{ column[1] }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="member in filteredMembers">
                <td :class="{ 'text-muted o-50': !isValidStarter(member) }">
                    <i class="fa fa-fw fa-mars text-muted" v-if="member.gender === 'm'"></i>
                    <i class="fa fa-fw fa-venus text-muted" v-if="member.gender === 'f'"></i>
                    {{ member.firstName }} {{ member.lastName }}
                    <div class="text-danger" v-for="blocker in memberBlockers[member.id] || []">
                        {{ $t("@championship:registration.blocker." + blocker) }}
                    </div>
                </td>
                <td v-for="column in columns">
                    <input
                        v-model="selectedMembers[column[0]]"
                        type="checkbox"
                        class="custom-checkbox"
                        :value="member.id"
                        :disabled="
                            column[0] === 'participant' || column[0] === 'alternate'
                                ? !isValidStarter(member)
                                : !isValidSupervisorOrCoach(member)
                        "
                        @change="selectionChanged(column[0], member)"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</template>
