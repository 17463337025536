<script setup lang="ts">
import type { NewsCategory } from "@/model/c-m-s/news-category"
import type { NewsCategoryGroup } from "@/model/c-m-s/news-category-group"
import type { NewsPost } from "@/model/c-m-s/news-post"
import { useHttpGet } from "@/vf"
import { refDebounced } from "@vueuse/core"
import { computed, reactive, ref } from "vue"
import { useRouter } from "vue-router"
import NewsGrid from "./News/NewsGrid.vue"

const props = defineProps<{
    primaryCategories?: string[]
}>()

const router = useRouter()
const selectedCategories = reactive<string[]>(
    typeof router.currentRoute.value.query.categories === "string"
        ? router.currentRoute.value.query.categories.split(",")
        : [...(props.primaryCategories ?? [])],
)
const searchQuery = ref("")
const url = computed(() => {
    const q = [...selectedCategories.map(category => "categories[]=" + encodeURIComponent(category))]

    if (searchQuery.value) {
        q.push("search=" + encodeURIComponent(searchQuery.value))
    }

    return "/cms/news?" + q.join("&")
})

const urlDebounced = refDebounced(url, 300)
const { data, refresh } = await useHttpGet<{ news: NewsPost[]; categories: NewsCategoryGroup[] }>(urlDebounced, true)

function toggleCategory(category: NewsCategory) {
    selectedCategories.includes(category.id)
        ? selectedCategories.splice(selectedCategories.indexOf(category.id), 1)
        : selectedCategories.push(category.id)

    refresh()
}
</script>

<template>
    <div class="row">
        <div class="col-lg-3 order-1">
            <div class="search-box">
                <input type="text" placeholder="Search" v-model="searchQuery" />
                <i class="fa fa-fw fa-search"></i>
            </div>

            <div v-for="group of data.categories" class="news-cat-group">
                <h3 class="news-cat-group--name">{{ group.name }}</h3>

                <div
                    class="news-cat-group--category"
                    :class="{ active: selectedCategories.includes(category.id) }"
                    v-for="category of group.categories"
                    @click="toggleCategory(category)"
                >
                    <div class="news-cat-group--category--checkbox">
                        <i class="fa fa-check"></i>
                    </div>
                    <span>{{ category.name }}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-9 order-0 order-md-2">
            <h1 v-if="data.news.length == 0" class="text-muted py-5 my-5 text-center">
                {{ $t("@cms:news_post.no_results") }}
            </h1>
            <NewsGrid :posts="data.news" :per-row="3" />
        </div>
    </div>
</template>

<style scoped>
.news-cat-group {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.news-cat-group--name {
    font-family: "Roboto Slab", serif;
    font-weight: bold;
    font-size: 1.5rem;
    padding-left: 1.5rem;
    margin: 1rem 0;
}

.news-cat-group--category {
    display: block;
    padding: 1rem 1.5rem;
    cursor: pointer;
    border-radius: 8px;
    background-color: #b8c9d7;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;

    transition: 150ms ease-in-out background-color;
    margin: 0.75rem 0;
}

.news-cat-group--category.active {
    background-color: var(--primary);
}

.news-cat-group--category--checkbox {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid #fff;
    border-radius: 2px;
    margin-right: 1rem;
    background-color: #fff;
    vertical-align: middle;
    display: inline-grid;
    place-items: center;
}

.active .news-cat-group--category--checkbox {
    color: var(--primary);
}

.search-box {
    background-color: #eef3f6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.search-box input {
    background-color: transparent;
    border: none;
    flex-grow: 1;
    padding: 1rem 2rem;
}

.search-box input::placeholder {
    font-style: italic;
}
</style>
