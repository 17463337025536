<script setup lang="ts">
import CmsFrontend from "@/pages/Cms/Frontend/frontend.vue"
import { onErrorCaptured, ref } from "vue"

const showFallback = ref(false)
onErrorCaptured(() => {
    showFallback.value = true
    return false
})
</script>
<template>
    <CmsFrontend path="/general/404">
        <template #fallback>
            <div class="h-100 d-flex flex-column">
                <div class="card login-card">
                    <div class="card-body pt-0">
                        <div class="text-center py-5 text-primary">
                            <i class="far fa-5x fa-sad-cry"></i>
                        </div>

                        <p class="text-justify">
                            Da ist wohl was schief gelaufen... Unsere Techniker wurden benachrichtigt und arbeiten
                            wahrscheinlich bereits an einer Lösung. Bitte probiere es später einfach erneut.
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </CmsFrontend>
</template>
