<script setup lang="ts">
import VarsityInlineConfirm from "@/components/VarsityInlineConfirm.vue"
import VarsityInlineConfirmButton from "@/components/VarsityInlineConfirmButton.vue"
import CheckboxToggle from "@/components/form/CheckboxToggle.vue"
import { createInlineConfirm } from "@/composables/createInlineConfirm"
import { type Issue } from "@/model/tasks/issue"
import type { IssueState } from "@/model/tasks/issue-state"
import { type Task } from "@/model/tasks/task"
import IssueRespond from "@/pages/Tasks/Issue/IssueRespond.vue"
import ChampionshipRegistrationTask from "@/pages/Tasks/Tasks/ChampionshipRegistration/ChampionshipRegistrationTask.vue"
import ComMessage from "@/pages/Tasks/Tasks/ComMessage.vue"
import JobActivityInvitation from "@/pages/Tasks/Tasks/Staff/JobActivityInvitation.vue"
import ReviewContractTask from "@/pages/Tasks/Tasks/Staff/ReviewContractTask.vue"
import ReviewGeneralTermsTask from "@/pages/Tasks/Tasks/Staff/ReviewGeneralTermsTask.vue"
import SignContractTask from "@/pages/Tasks/Tasks/Staff/SignContractTask.vue"
import { useGlobalState } from "@/stores/useGlobalState"
import { $date, useHttpClient, useInlineTable, useLoadEntity } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import { computed, reactive, ref } from "vue"
import AcceptOrganizationRequest from "../Tasks/AcceptOrganizationRequest.vue"
import AccountInvitation from "../Tasks/AccountInvitation.vue"
import ApproveAddressUpdate from "../Tasks/ApproveAddressUpdate.vue"
import ApproveOrganizationDebitorProfile from "../Tasks/ApproveOrganizationDebitorProfile.vue"
import AuditAthleteUpload from "../Tasks/AuditAthleteUpload.vue"
import ChampionshipRegistrationApprove from "../Tasks/ChampionshipRegistrationApprove.vue"
import ChampionshipRegistrationFinalizeTeams from "../Tasks/ChampionshipRegistrationFinalizeTeams.vue"
import ChampionshipRegistrationPayInvoice from "../Tasks/ChampionshipRegistrationPayInvoice.vue"
import ChampionshipRegistrationSelectPaymentMethod from "../Tasks/ChampionshipRegistrationSelectPaymentMethod.vue"
import ChampionshipRegistrationSelectTeam from "../Tasks/ChampionshipRegistrationSelectTeam.vue"
import ChampionshipRegistrationWaitingList from "../Tasks/ChampionshipRegistrationWaitingList.vue"
import ApproveLoginJobActivityTask from "../Tasks/Staff/ApproveLoginJobActivityTask.vue"
import TaskAttachmentList from "../Tasks/TaskAttachmentList.vue"
import IssueSettings from "./IssueSettings.vue"
import { createModuleConfiguration } from "./config"

const props = withDefaults(
    defineProps<{
        id: string
        viewMode?: "admin" | "customer"
        filterOnlyMessages: boolean
    }>(),
    {
        viewMode: "customer",
        filterOnlyMessages: false,
    },
)

const http = useHttpClient()

const inlineTable = useInlineTable()
const config = createModuleConfiguration()
const { item: issue, refresh } = await useLoadEntity<Issue>(props.id, config)
const { refreshGlobalState } = useGlobalState()
refreshGlobalState() // refresh state to update the unread messages count in the menu

const openReply = ref(false)
const openSettings = ref(false)
const isAdmin = computed(() => props.viewMode === "admin")

async function updated() {
    inlineTable.emit("refresh")
    await refresh()

    console.log("updated: ", issue.value.id, issue.value.state)

    if (issue.value.state == "done") {
        inlineTable.close()
    }
}

async function toggleState(task: Task) {
    if (!canToggleTask(task)) {
        return
    }

    if (["pending", "canceled", "future"].includes(task.state)) {
        return
    }

    const state = task.state == "open" ? "closed" : "open"
    const newIssueState = (
        await http.post<{
            issueState: IssueState
        }>(`tasks/task/${task.id}/state`, { state })
    ).data.issueState
    if (newIssueState != issue.value.state) {
        issue.value.state = newIssueState
        await updated()
    }
    task.state = state
}

const expandedTasks = reactive({}) as { [id: string]: boolean }
const filterOnlyMessages = ref(props.filterOnlyMessages)

function taskIsExpanded(task: Task) {
    return (
        (!["closed", "canceled", "rejected", "future"].includes(task.state) && expandedTasks[task.id] === undefined) ||
        expandedTasks[task.id]
    )
}

function canExpandTask(task: Task) {
    return task.state !== "future"
}

function expandTask(task: Task) {
    if (!canExpandTask(task)) {
        return
    }

    expandedTasks[task.id] = !taskIsExpanded(task)
}

function taskCanBeOpened(task: Task) {
    return (isAdmin.value && task.canBeManuallyOpenedByAdmin) || (!isAdmin.value && task.canBeManuallyOpenedByUser)
}

function taskCanBeClosed(task: Task) {
    return (isAdmin.value && task.canBeManuallyClosedByAdmin) || (!isAdmin.value && task.canBeManuallyClosedByUser)
}

function canToggleTask(task: Task) {
    if (task.state == "closed") {
        return taskCanBeOpened(task)
    }
    if (task.state == "open") {
        return taskCanBeClosed(task)
    }
    return false
}

function canAnyTaskBeClosed() {
    return issue.value.tasks.some(task => taskCanBeClosed(task))
}

function allTasksCanBeClosed() {
    return issue.value.tasks.every(task => taskCanBeClosed(task) || task.state == "closed")
}

const inlineCloseAll = createInlineConfirm({
    async action() {
        await http.post(`tasks/task/close-all/${issue.value.id}`)
    },
    afterAction() {
        updated()
    },
})
</script>

<template>
    <div class="open-row-container-flush">
        <div class="d-flex bg-primary align-items-center text-white py-2 pr-4">
            <div @click="inlineTable.close()" class="c-pointer py-3 pr-3 pl-4">
                <i class="fa fa-fw fa-chevron-left"></i>
                <span>{{ $t("crud:button.back") }}</span>
            </div>

            <div class="pr-3">
                <i class="fa fa-fw fa-ellipsis-vertical"></i>
            </div>

            <div class="flex-grow-1">
                <template v-for="issueGroup in issue.issueGroupTree">
                    {{ issueGroup.name }}
                    <i class="fa fa-fw fa-chevron-right"></i>
                </template>
                <template v-for="issueGroup in issue.productGroupTree">
                    {{ issueGroup.name }}
                    <i class="fa fa-fw fa-chevron-right"></i>
                </template>

                {{ $t(issue.name, issue.nameProps) }}
            </div>

            <CheckboxToggle v-model="filterOnlyMessages">{{ $t("@tasks:issue.only_messages") }}</CheckboxToggle>

            <div
                class="c-pointer p-3 fs-1-5"
                @click="openSettings = !openSettings"
                v-if="viewMode === 'admin'"
                :class="{ 'text-primary-dark': openSettings }"
            >
                <i class="fa fa-fw fa-cog"></i>
            </div>
        </div>

        <AnimateIf :if="openSettings">
            <IssueSettings :issue="issue" @updated="updated" class="border-bottom border-primary px-4 py-3" />
        </AnimateIf>

        <div
            v-for="(task, index) of issue.tasks"
            :class="{ 'bg-light': index % 2 === 0 }"
            class="border-bottom border-primary"
        >
            <AnimateIf :if="!filterOnlyMessages || task.taskType === 'message'">
                <div class="p-4 d-flex align-items-center" :class="task.state === 'future' ? 'bg-future' : 'bg-white'">
                    <span class="p-2 mr-3 c-pointer" @click="expandTask(task)" style="width: 24px">
                        <i
                            class="fa-solid fa-chevron-right transition-transform"
                            :class="{ 'fa-rotate-90': taskIsExpanded(task) }"
                            v-if="canExpandTask(task)"
                        ></i>
                    </span>
                    <div>
                        <div
                            class="border text-center"
                            style="width: 20px; height: 20px"
                            :class="{
                                'c-pointer': canToggleTask(task),
                            }"
                            @click="toggleState(task)"
                        >
                            <i class="fa-solid fa-check" v-if="task.state === 'closed'"></i>
                            <i
                                class="fa-solid fa-times"
                                v-if="task.state === 'canceled' || task.state === 'rejected'"
                            ></i>
                            <i class="fa-solid fa-hourglass" v-if="task.state === 'pending'"></i>
                        </div>
                    </div>
                    <div style="width: 15ex" class="py-2 mx-3">
                        {{ $t("@tasks:issue.task_states." + task.state) }}
                    </div>
                    <div class="border-right align-self-stretch mx-3"></div>
                    <div class="flex-grow-1 c-pointer" @click="expandTask(task)">
                        <div class="text-primary font-weight-bold fs-1-3 font-slab">
                            {{ $t(task.name, task.nameProps) }}
                        </div>
                        <div v-if="$t(task.name + '_subtitle', task.nameProps) !== task.name + '_subtitle'">
                            {{ $t(task.name + "_subtitle", task.nameProps) }}
                        </div>
                    </div>
                    <div v-if="task.hasAttachments">
                        <i class="fa fa-fw fa-paperclip"></i>
                    </div>
                    <div class="border-right align-self-stretch mx-3"></div>
                    <div>
                        <template v-if="task.softDeadline">bis {{ $date(task.softDeadline) }}</template>
                        <template v-else>
                            {{ $date(task.createdAt) }}
                        </template>
                    </div>
                </div>
                <AnimateIf :if="taskIsExpanded(task)">
                    <template v-if="task.taskType === 'message'">
                        <ComMessage v-bind="{ task, index, issue, viewMode }"></ComMessage>
                    </template>

                    <template v-if="task.taskType === 'accept_organization_request'">
                        <AcceptOrganizationRequest
                            v-bind="{ task, issue, index, viewMode }"
                            @updated="updated"
                        ></AcceptOrganizationRequest>
                    </template>

                    <template v-if="task.taskType === 'audit_athlete_upload'">
                        <AuditAthleteUpload
                            v-bind="{ task, issue, index, viewMode }"
                            @updated="updated"
                        ></AuditAthleteUpload>
                    </template>

                    <template v-if="task.taskType === 'account_invitation'">
                        <AccountInvitation
                            v-bind="{ task, issue, index, viewMode }"
                            @updated="updated"
                        ></AccountInvitation>
                    </template>
                    <template v-if="task.taskType === 'job_activity_invitation'">
                        <JobActivityInvitation
                            v-bind="{ task, issue, index, viewMode }"
                            @updated="updated"
                        ></JobActivityInvitation>
                    </template>
                    <template v-if="task.taskType === 'staff_sign_contact'">
                        <SignContractTask
                            v-bind="{ task, issue, index, viewMode }"
                            @updated="updated"
                        ></SignContractTask>
                    </template>
                    <template v-if="task.taskType === 'staff_review_contact'">
                        <ReviewContractTask
                            v-bind="{ task, issue, index, viewMode }"
                            @updated="updated"
                        ></ReviewContractTask>
                    </template>
                    <template v-if="task.taskType === 'staff_review_general_terms'">
                        <ReviewGeneralTermsTask
                            v-bind="{ task, issue, index, viewMode }"
                            @updated="updated"
                        ></ReviewGeneralTermsTask>
                    </template>

                    <ApproveOrganizationDebitorProfile
                        v-if="task.taskType === 'approve_organization_debitor_profile'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ApproveOrganizationDebitorProfile>

                    <ChampionshipRegistrationTask
                        v-if="task.taskType === 'championship_registration_step'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ChampionshipRegistrationTask>

                    <ChampionshipRegistrationSelectTeam
                        v-if="task.taskType === 'championship_registration_select_team'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ChampionshipRegistrationSelectTeam>

                    <ChampionshipRegistrationApprove
                        v-if="task.taskType === 'championship_registration_approve'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ChampionshipRegistrationApprove>

                    <ChampionshipRegistrationWaitingList
                        v-if="task.taskType === 'championship_registration_waiting_list'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ChampionshipRegistrationWaitingList>

                    <ChampionshipRegistrationSelectPaymentMethod
                        v-if="task.taskType === 'championship_registration_select_payment_method'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ChampionshipRegistrationSelectPaymentMethod>

                    <ChampionshipRegistrationFinalizeTeams
                        v-if="task.taskType === 'championship_registration_finalize_teams'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ChampionshipRegistrationFinalizeTeams>

                    <ChampionshipRegistrationPayInvoice
                        v-if="task.taskType === 'championship_registration_pay_invoice'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ChampionshipRegistrationPayInvoice>

                    <ApproveAddressUpdate
                        v-if="task.taskType === 'approve_address_update'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ApproveAddressUpdate>

                    <ApproveLoginJobActivityTask
                        v-if="task.taskType === 'approve_login_job_activity'"
                        v-bind="{ task, issue, index, viewMode }"
                        @updated="updated"
                    ></ApproveLoginJobActivityTask>

                    <TaskAttachmentList :task="task" v-if="task.attachments?.length > 0"></TaskAttachmentList>
                </AnimateIf>
            </AnimateIf>
        </div>

        <div v-animate-show="!openReply">
            <div class="p-3 d-flex justify-content-between">
                <div>
                    <VarsityInlineConfirmButton
                        class="btn btn-primary mb-0"
                        :controller="inlineCloseAll"
                        v-if="isAdmin"
                        :disabled="!canAnyTaskBeClosed()"
                    >
                        {{ $t("@tasks:issue.close_all") }}
                    </VarsityInlineConfirmButton>
                </div>
                <button type="button" class="btn btn-primary mb-0" @click="openReply = !openReply">
                    {{ $t("@tasks:issue.reply") }}
                </button>
            </div>
            <VarsityInlineConfirm :controller="inlineCloseAll">
                <template #confirmation>
                    <template v-if="allTasksCanBeClosed()">
                        {{ $t("@tasks:issue.close_all_confirm_all_possible") }}
                    </template>
                    <template v-else>
                        {{ $t("@tasks:issue.close_all_confirm_not_all_possible") }}
                    </template>
                </template>
                <template #success>
                    {{ $t("@tasks:issue.close_all_success") }}
                </template>
            </VarsityInlineConfirm>
        </div>

        <AnimateIf :if="openReply">
            <IssueRespond
                v-bind="{ issue, viewMode }"
                @close="openReply = false"
                @responded=";[updated(), (openReply = false)]"
            />
        </AnimateIf>
    </div>
</template>

<style scoped>
.bg-future {
    background-color: #e4e2e5;
}
</style>
