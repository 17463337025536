<script setup lang="ts">
import { computed } from "vue"
import { useHttpClient } from "@/vf"
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"

const { runningHttpRequests } = useHttpClient()
const legacyHttpClient = useLegacyHttpClient()
const active = computed(() => runningHttpRequests.value > 0 || legacyHttpClient.runningHttpRequests.value > 0)
</script>

<template>
    <div class="splash-screen loading-splash" :class="{ active }">
        <div class="splash-screen-backdrop"></div>
        <div class="splash-screen-content">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<!--<style lang="scss" scoped>-->
<!--@import "@/styles/variables";-->

<!--.splash-screen {-->
<!--    position: fixed;-->
<!--    //opacity: 0.2;-->
<!--    left: 0;-->
<!--    top: 0;-->
<!--    right: 0;-->
<!--    bottom: 0;-->
<!--    color: #fff;-->
<!--    z-index: 10000;-->
<!--    display: flex;-->

<!--    .splash-screen-backdrop {-->
<!--        position: fixed;-->

<!--        left: 0;-->
<!--        top: 0;-->
<!--        right: 0;-->
<!--        bottom: 0;-->
<!--        color: #fff;-->
<!--        z-index: 10001;-->
<!--        background-color: $body-bg;-->
<!--    }-->

<!--    .splash-screen-content {-->
<!--        margin: auto auto;-->
<!--        text-align: center;-->
<!--        color: #000;-->
<!--        z-index: 10002;-->

<!--        div {-->
<!--            font-size: 1.2em;-->
<!--        }-->
<!--    }-->
<!--}-->

<!--.splash-screen.loading-splash {-->
<!--    display: none;-->
<!--    opacity: 0;-->

<!--    .splash-screen-backdrop {-->
<!--        opacity: 0.6;-->
<!--        background-color: theme-color("dark");-->
<!--    }-->

<!--    &.active {-->
<!--        display: flex;-->
<!--        animation: loading-splash-fade-in;-->
<!--        animation-duration: 2000ms;-->
<!--        animation-iteration-count: 1;-->
<!--        animation-fill-mode: forwards;-->
<!--        animation-delay: 1000ms;-->
<!--    }-->
<!--}-->

<!--@keyframes loading-splash-fade-in {-->
<!--    0% {-->
<!--        opacity: 0;-->
<!--    }-->

<!--    100% {-->
<!--        opacity: 1;-->
<!--    }-->
<!--}-->

<!--.lds-ellipsis {-->
<!--    display: inline-block;-->
<!--    position: relative;-->
<!--    width: 64px;-->
<!--    height: 64px;-->
<!--}-->
<!--.lds-ellipsis div {-->
<!--    position: absolute;-->
<!--    top: 27px;-->
<!--    width: 11px;-->
<!--    height: 11px;-->
<!--    border-radius: 50%;-->
<!--    background: #fff;-->
<!--    animation-timing-function: cubic-bezier(0, 1, 1, 0);-->
<!--}-->
<!--.lds-ellipsis div:nth-child(1) {-->
<!--    left: 6px;-->
<!--    animation: lds-ellipsis1 0.6s infinite;-->
<!--}-->
<!--.lds-ellipsis div:nth-child(2) {-->
<!--    left: 6px;-->
<!--    animation: lds-ellipsis2 0.6s infinite;-->
<!--}-->
<!--.lds-ellipsis div:nth-child(3) {-->
<!--    left: 26px;-->
<!--    animation: lds-ellipsis2 0.6s infinite;-->
<!--}-->
<!--.lds-ellipsis div:nth-child(4) {-->
<!--    left: 45px;-->
<!--    animation: lds-ellipsis3 0.6s infinite;-->
<!--}-->
<!--@keyframes lds-ellipsis1 {-->
<!--    0% {-->
<!--        transform: scale(0);-->
<!--    }-->
<!--    100% {-->
<!--        transform: scale(1);-->
<!--    }-->
<!--}-->
<!--@keyframes lds-ellipsis3 {-->
<!--    0% {-->
<!--        transform: scale(1);-->
<!--    }-->
<!--    100% {-->
<!--        transform: scale(0);-->
<!--    }-->
<!--}-->
<!--@keyframes lds-ellipsis2 {-->
<!--    0% {-->
<!--        transform: translate(0, 0);-->
<!--    }-->
<!--    100% {-->
<!--        transform: translate(19px, 0);-->
<!--    }-->
<!--}-->
<!--</style>-->
