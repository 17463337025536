import type { OrganizationMember } from "@/model/app/organization-member"
import { h } from "vue"

export function renderConnectionStatus(member: OrganizationMember, t) {
    const r = []
    if (member.managerUserId) {
        r.push(
            h("div", null, [
                h("i", {
                    class: "fas fa-fw fa-circle management-status-" + member.managerStatus,
                    title: t("@app:ManagementStatus." + member.managerStatus),
                }),
                t("@app:organization_member.managementTypes.manager") + ": " + member.managerUserId,
            ]),
        )
    }
    if (member.connectionUserId) {
        r.push(
            h("div", null, [
                h("i", {
                    class: "fas fa-fw fa-circle management-status-" + member.connectionStatus,
                    title: t("@app:ManagementStatus." + member.connectionStatus),
                }),
                t("@app:organization_member.managementTypes.myself") + ": " + member.connectionUserId,
            ]),
        )
    }
    return r
}
