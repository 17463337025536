<script setup lang="ts">
const props = defineProps<{
    active: boolean
}>()
const emit = defineEmits<{
    (e: "close"): void
}>()
</script>

<template>
    <div class="dismissable-backdrop" @click="emit('close')" :class="{ active: props.active }"></div>
</template>

<style>
.dismissable-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    background-color: #000;
    opacity: 0;
    transition: 500ms ease-in-out opacity;
    pointer-events: none;
}

.dismissable-backdrop.active {
    pointer-events: auto;
    opacity: 0.3;
}
</style>
