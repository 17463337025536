<script setup lang="ts">
// generated from file:///home/nnscr/code/wiezwei/varsity-booking/api/src/CMSBundle/Resources/aurelia/elements/big-picture.ts
import { useApp } from "@/vf"
import Flickity from "flickity"
import type { Ref } from "vue"
import { onMounted, onUnmounted, ref } from "vue"
import CmsButtonLink from "./CmsButtonLink.vue"

// props
const props = defineProps<{
    element: any
}>()

// functions
function attached() {
    containerElement.value.style.width = containerElement.value.getBoundingClientRect().width + "px"
    setTimeout(() => {
        if (!containerElement.value) {
            return
        }

        flickity.value = new Flickity(containerElement.value, {
            wrapAround: true,
            pageDots: false,
        })
    }, 1000)
}

function detached() {
    if (flickity.value) {
        flickity.value.destroy()
    }
}
// refs
const flickity: Ref<Flickity> = ref(null)
const containerElement: Ref<HTMLDivElement> = ref(null)
const pageNextElement: Ref<HTMLElement> = ref(null)
const pagePrevElement: Ref<HTMLElement> = ref(null)
const { apiLink } = useApp()
// setup
onMounted(() => attached())
onUnmounted(() => detached())
</script>

<!-- generated from file:///home/nnscr/code/wiezwei/varsity-booking/api/src/CMSBundle/Resources/aurelia/elements/big-picture.html -->
<template>
    <!-- @todo replace me: <require from="@CMS.Elements.button-link"></require> -->
    <div class="big-picture" :style="'background-image: url(' + apiLink('/media/' + element.fileName)">
        <div class="main-carousel" ref="containerElement">
            <div class="carousel-cell" v-for="slide in element?.columns[0]">
                <div class="big-picture-content">
                    <h2>{{ slide.title }}</h2>

                    <div v-html="slide.text"></div>

                    <div class="mt-4" v-if="slide.buttonText">
                        <CmsButtonLink class="btn btn-primary" :element="slide"></CmsButtonLink>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div ref="pagePrevElement" class="swiper-button-prev" click.trigger="prevSlide()" if.bind="isSlider">-->
        <!--            <i class="fa fa-fw fa-chevron-left"></i>-->
        <!--        </div>-->
        <!--        <div ref="pageNextElement" class="swiper-button-next" click.trigger="nextSlide()" if.bind="isSlider">-->
        <!--            <i class="fa fa-fw fa-chevron-right"></i>-->
        <!--        </div>-->
    </div>
</template>
