<script lang="ts" setup>
import { inject, provide, ref, watch, type Ref } from "vue"
import { VfFormRendered } from "../.."

const props = defineProps<{
    name: string
}>()

const injectedPath = inject<Ref<string> | null>("vf-form-group", null)
const providedPath = ref("")

function update() {
    if (injectedPath) {
        providedPath.value = injectedPath.value + "." + props.name
    } else {
        providedPath.value = props.name
    }
}

watch(() => props.name, update)
update()
provide("vf-form-group", providedPath)
</script>

<template>
    <FormKit type="group" :name="props.name">
        <slot></slot>
    </FormKit>
    <VfFormRendered :name="props.name" />
</template>
