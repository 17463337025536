<script setup lang="ts">
// generated from file:///home/nnscr/code/wiezwei/varsity-booking/api/src/AppBundle/Resources/aurelia/registration/reset_password.ts
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { useNavbarState } from "@/stores"
import type { Ref } from "vue"
import { ref } from "vue"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
async function submit() {
    try {
        await http.post("/reset_password", {
            token: token.value,
            newPassword: newPassword.value,
            newPasswordRepeat: newPasswordRepeat.value,
        })
        success.value = true
    } catch {
        success.value = false
    }
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const navbarState = useNavbarState()
const http = useLegacyHttpClient()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const token: Ref<any> = ref(null)
const newPassword: Ref<any> = ref(null)
const newPasswordRepeat: Ref<any> = ref(null)
const success: Ref<any> = ref(null)
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
const queryParams = new URLSearchParams(window.location.search)
if (queryParams.has("resetPasswordToken")) {
    token.value = queryParams.get("resetPasswordToken")
}
</script>

<!-- generated from file:///home/nnscr/code/wiezwei/varsity-booking/api/src/AppBundle/Resources/aurelia/registration/reset_password.html -->
<template>
    <form @submit.prevent="submit()">
        <div class="card-body">
            <h5 class="card-title" v-t="'login.lost_password'">Passwort vergessen</h5>
            <p
                class="text-justify"
                v-t="'@app:registration.reset_password.set_new_password_prompt'"
                v-if="success === null"
            ></p>
            <div class="alert alert-success" v-t="'@app:registration.reset_password.success'" v-if="success"></div>
            <div
                class="alert alert-danger"
                v-t="'@app:registration.reset_password.failed'"
                v-if="success === false"
            ></div>

            <div class="my-4" v-if="success === null">
                <div class="form-group">
                    <label
                        class="col-form-label required"
                        for="reset_password_new_password"
                        v-t="'@app:my.new_password'"
                    ></label>
                    <div class="col-form-control">
                        <input
                            type="password"
                            id="reset_password_new_password"
                            class="form-control"
                            required
                            v-model="newPassword"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label
                        class="col-form-label required"
                        for="reset_password_repeat_password"
                        v-t="'@app:my.new_password_repeat'"
                    ></label>
                    <div class="col-form-control">
                        <input
                            type="password"
                            id="reset_password_repeat_password"
                            class="form-control"
                            required
                            v-model="newPasswordRepeat"
                        />
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between mt-3">
                <button
                    type="button"
                    class="btn btn-secondary"
                    v-t="'login.back'"
                    @click="navbarState.openCard('login')"
                ></button>
                <button
                    type="submit"
                    class="btn btn-primary btn-raised"
                    v-t="'crud:button.save'"
                    :disabled="newPassword !== newPassword || !newPassword || success"
                ></button>
            </div>
        </div>
    </form>
</template>
