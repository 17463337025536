import {
    VfCompose,
    // VfDeleteButton,
    VfDropdown,
    // VfEdit,
    // VfEditButton,
    // VfEditToolbar,
    // VfForm,
    // VfFormRendered,
    // VfFormRest,
    // VfFormRow,
    // VfFormWidget,
    VfHashTabBar,
    VfHashTabGroup,
    VfHashTabLink,
    VfHashTabPage,
} from "@/vf"
import type { App } from "vue"

export function registerGlobalComponents(app: App) {
    app.component("VfDropdown", VfDropdown)
    app.component("VfCompose", VfCompose)
    app.component("VfHashTabBar", VfHashTabBar)
    app.component("VfHashTabGroup", VfHashTabGroup)
    app.component("VfHashTabLink", VfHashTabLink)
    app.component("VfHashTabPage", VfHashTabPage)

    // app.component("VfForm", VfForm)
    // app.component("VfEdit", VfEdit)
    // app.component("VfEditToolbar", VfEditToolbar)

    // app.component("VfFormRendered", VfFormRendered)
    // app.component("VfFormRest", VfFormRest)
    // app.component("VfFormRow", VfFormRow)
    // app.component("VfFormWidget", VfFormWidget)

    // app.component("VfList", VfList)
    // app.component("VfListButtons", VfListButtons)
    // app.component("VfListCompose", VfListCompose)
    // app.component("VfListPagination", VfListPagination)
    // app.component("VfListSearch", VfListSearch)
    // app.component("VfListToolbar", VfListToolbar)
    // app.component("VfShow", VfShow)
    // app.component("VfShowToolbar", VfShowToolbar)

    // app.component("VfBackButton", VfBackButton)
    // app.component("VfDeleteButton", VfDeleteButton)
    // app.component("VfEditButton", VfEditButton)
    // app.component("VfNewButton", VfNewButton)
    // app.component("VfSaveButton", VfSaveButton)
}
