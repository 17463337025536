<script setup lang="ts">
import { useApp } from "@/vf"
import SidebarButtonWithImage from "./generic/SidebarButtonWithImage.vue"
import { getHref, getTarget } from "./link-service"

const props = defineProps<{
    app?: any
    element: any
}>()

const { apiLink } = useApp()
</script>

<template>
    <h1>{{ props.element.title }}</h1>

    <a v-for="item of element.columns[0]" :href="getHref(item)" :target="getTarget(item)">
        <SidebarButtonWithImage :image-url="apiLink('/media/' + item.fileName)">
            <template #title>
                {{ item.buttonText }}
            </template>
        </SidebarButtonWithImage>
    </a>
</template>
