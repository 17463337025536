<script setup lang="ts">
type StepProps = {
    issue: Issue
    task: ChampionshipRegistrationStepTask
    championship: Championship
    stepData: any
}
import { useLegacyHttpClient } from '@/composables/compat/useLegacyHttpClient'
import { ref, watch } from 'vue'
const props = defineProps<StepProps>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
async function activate(params) {
    if (params) {
        // this lifetime cycle is somehow called without params when changing from payment to final
        console.log(params)
        paymentMethod.value = !!props.stepData.paymentMethod
    }
    members.value = (await http.get("championship/registration/" + props.issue.id + "/pay-members")).content
    splitPayment.value = members.value.reduce((a, i) => a || i.receivesPaymentRequest, false)
}
async function selectPayment() {
    props.stepData.paymentMethod = paymentMethod.value
    await http.put("championship/registration/" + props.issue.id + "/pay-members", {
        members: members.value.map(i => i.id),
    })
    emit("updated")
}
async function splitPaymentChanged(newValue) {
    if (!newValue) {
        for (let member of members.value) {
            member.receivesPaymentRequest = false
        }
    }
}
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const http = useLegacyHttpClient()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const splitPayment = ref(false)
const members = ref()
const paymentMethod = ref()
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
watch(splitPayment, splitPaymentChanged)
await activate(props)
</script>

<template>
    <div class="form-check">
        <input type="radio" class="form-check-input" id="bankTransfer" value="bankTransfer" :checked="paymentMethod" />
        <label for="bankTransfer" class="form-check-label ml-2">
            {{ $t("@tasks:tasks.championship_registration.prepay") }}
        </label>
    </div>

    <div class="form-check mt-2">
        <input type="checkbox" class="form-check-input" id="groupPayment" v-model="splitPayment" />
        <label for="groupPayment" class="form-check-label ml-2">
            {{ $t("@tasks:tasks.championship_registration.payment_request_to_members") }}
        </label>
    </div>

    <div class="ml-2" v-if="splitPayment" :spinner="members">
        <div class="form-check" v-for="member in members">
            <input
                type="checkbox"
                class="form-check-input"
                :id="member.id"
                v-model="member.receivesPaymentRequest"
                :disabled="!member.canReceivePaymentRequests"
            />
            <label class="form-check-label ml-2" :for="member.id">
                {{ member.name }}
            </label>
        </div>
    </div>

    <button class="btn btn-primary mt-3" @click="selectPayment()">{{ $t("crud:button.next") }}</button>
</template>
